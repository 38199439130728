import React, { useState } from 'react';
import ImageCropper from '../../components/image-cropper';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBoardInCropper,
  updateImageCropData,
} from '../../../redux/boards/actions';
import { updateApp } from '../../../redux/app/actions';
import toggleChatPopup from '../../../utils/toggleChatPopup';
import { a_adjustCrop } from '../../../api/analytics';
import { checkoutImages } from '../../../lib/images';
import './CropperMobileWrapper.scss';
import { isMobile } from '../../../utils/dimensions';
import { Dialog, DialogContent } from '@material-ui/core';

const CLASS = 'sb-CropperWrapper';

const CropperMobileWrapper = () => {
  const boards = useSelector((state) => state.boards);
  const dispatch = useDispatch();
  const isCropperActive = useSelector((state) => state.app.modals.cropper);
  const [state, setState] = useState({ currentCropData: null });
  const setCropData = (cropData) =>
    setState({ ...state, currentCropData: cropData });
  const [grid, setGrid] = useState(true);

  const renderHeader = () => {
    return (
      <div
        className={`${CLASS}-header`}
        style={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          paddingTop: '4px',
          paddingBottom: '4px',
        }}
      >
        <span
          className={`${CLASS}-header-title`}
          style={{
            fontSize: '16px',
            fontWeight: 700,
            color: '#1C3E64',
            textTransform: 'uppercase',
          }}
        >
          Adjust Crop
        </span>
        <img
          className={`${CLASS}-header-btnCancel`}
          onClick={closeCropper}
          src={checkoutImages.closeBlueNew}
          alt="close window"
        />
      </div>
    );
  };

  const closeCropper = () => {
    dispatch(updateApp({ modals: { cropper: false } }));
    setTimeout(() => dispatch(setBoardInCropper(null)), 300);
    toggleChatPopup(true, 'close cropper');
  };

  const handleDone = async () => {
    a_adjustCrop();
    closeCropper();
    dispatch(
      updateImageCropData(state.currentCropData, boards.boardInCropper.id),
    );
  };

  return (
    <>
      {isMobile ? (
        <div>
          <div className={isCropperActive ? `${CLASS}-background` : ''} />
          <div
            className={
              isCropperActive
                ? `${CLASS} ${CLASS}-slideUp`
                : `${CLASS} ${CLASS}-slideDown`
            }
          >
            {renderHeader()}
            <div
              style={{
                textAlign: 'center',
                width: '65%',
                marginTop: '32px',
                marginBottom: isMobile ? undefined : '32px',
              }}
            >
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#1C3E64',
                }}
              >
                Pinch, zoom or move your photo to adjust the cropping. <br />
                Save when you’re done!
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ImageCropper setCropData={setCropData} grid={grid} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  marginTop: '80px',
                  marginBottom: isMobile ? undefined : '32px',
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.125 4.125H4.875C4.46079 4.125 4.125 4.46079 4.125 4.875V19.125C4.125 19.5392 4.46079 19.875 4.875 19.875H19.125C19.5392 19.875 19.875 19.5392 19.875 19.125V4.875C19.875 4.46079 19.5392 4.125 19.125 4.125Z"
                    stroke="#A638FC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 4.125V19.875"
                    stroke="#A638FC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.875 12H4.125"
                    stroke="#A638FC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#1C3E64',
                    cursor: 'pointer',
                  }}
                  onClick={() => setGrid(!grid)}
                >
                  {grid ? 'HIDE GRID' : 'SHOW GRID'}
                </span>
              </div>
            </div>
            <div style={{ flex: 1 }}></div>
            <button
              className={`${CLASS}-header-btnDone`}
              onClick={handleDone}
              style={{
                background: '#1C3E64',
                width: '320px',
                height: '52px',
                color: 'white',
                borderRadius: '4px',
                marginBottom: '32px',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontWeight: 700,
                  marginTop: 'auto',
                  display: 'block',
                }}
              >
                SAVE EDIT
              </span>
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className={isCropperActive ? `${CLASS}-background` : ''} />
          <div
            className={
              isCropperActive
                ? `${CLASS} ${CLASS}-slideUp`
                : `${CLASS} ${CLASS}-slideDown`
            }
            style={{ overflow: 'hidden' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflow: 'scroll',
              }}
            >
              {renderHeader()}
              <div
                style={{
                  padding: '24px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    width: '65%',
                    marginTop: '32px',
                    marginBottom: isMobile ? undefined : '32px',
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#1C3E64',
                    }}
                  >
                    Pinch, zoom or move your photo to adjust the cropping.{' '}
                    <br />
                    Save when you’re done!
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ImageCropper setCropData={setCropData} grid={grid} />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '4px',
                      marginTop: '80px',
                      marginBottom: isMobile ? undefined : '32px',
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.125 4.125H4.875C4.46079 4.125 4.125 4.46079 4.125 4.875V19.125C4.125 19.5392 4.46079 19.875 4.875 19.875H19.125C19.5392 19.875 19.875 19.5392 19.875 19.125V4.875C19.875 4.46079 19.5392 4.125 19.125 4.125Z"
                        stroke="#A638FC"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 4.125V19.875"
                        stroke="#A638FC"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19.875 12H4.125"
                        stroke="#A638FC"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#1C3E64',
                        cursor: 'pointer',
                      }}
                      onClick={() => setGrid(!grid)}
                    >
                      {grid ? 'HIDE GRID' : 'SHOW GRID'}
                    </span>
                  </div>
                </div>
                <button
                  className={`${CLASS}-header-btnDone`}
                  onClick={handleDone}
                  style={{
                    background: '#1C3E64',
                    width: '320px',
                    height: '52px',
                    color: 'white',
                    borderRadius: '4px',
                    marginTop: 'auto',
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 700,
                      marginTop: 'auto',
                      display: 'block',
                    }}
                  >
                    SAVE EDIT
                  </span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default CropperMobileWrapper;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import { pricingImages } from '../../../lib/images';
import { isMobile } from '../../../utils/dimensions';
import './Pricing.scss';
import { currencyFormatter } from '../upsell';
import { useSelector } from 'react-redux';

const CLASS = 'sb-Pricing';

const Pricing = ({ coupon, discount, priceOne, platform, mobileOnly }) => {
	const animationTimeout = 5000;
	const [animationIn, setAnimationIn] = useState([false, false, false]);

	useEffect(() => {
		if (coupon?.type === 'BUND') {
			setAnimationIn(p => [p[0], p[1], true]);
		}
	}, [coupon]);

	if (!coupon) return null;

	const onEntered = () => {
		const animInCopy = animationIn.map(e => false);
		setAnimationIn(animInCopy);
	};

	const onExiting = idx => {
		const animInCopy = animationIn.slice();
		const nextIdx = idx + 1 > 2 ? 0 : idx + 1;
		animInCopy[nextIdx] = true;
		setAnimationIn(animInCopy);
	};

	const savePercent = coupon.discountPercentage || coupon.b_disc;
	const discPrice = priceOne - (priceOne * savePercent) / 100;

	const price = {
		savePercent,
		full: priceOne,
		disc: Number.isInteger(discPrice) ? String(discPrice) : discPrice.toFixed(2),
	};
	let widgets;
	if (coupon.type === 'BUND' && !isMobile) {
		widgets = coupon.levels.map((level, idx) => (
			<CSSTransition
				key={level.value}
				timeout={animationTimeout}
				classNames="widgetItem"
				in={animationIn[idx]}
				onEntered={onEntered}
				onExiting={() => onExiting(idx)}>
				<WidgetBody
					key={level.text}
					coupon={coupon}
					level={level}
					price={price}
					idx={idx}
					animationIn={animationIn}
					animationTimeout={animationTimeout}
				/>
			</CSSTransition>
		));
	} else if (coupon.type === 'BUND') {
		widgets = coupon.levels.map((level, idx) => (
			<WidgetBody
				key={level.boards}
				coupon={coupon}
				level={level}
				price={price}
				idx={idx}
				animationIn={animationIn}
				animationTimeout={animationTimeout}
				platform={platform}
			/>
		));
	} else {
		widgets = [<WidgetBody coupon={coupon} price={price} key={coupon.type} />];
	}
	const isBundleMobile = isMobile && coupon.type === 'BUND';
	const pricingClass = classnames(CLASS, {
		'no-animation': isBundleMobile,
		[`${CLASS}-mobileOnly`]: mobileOnly,
	});
	return (
		<div className={pricingClass}>
			<div className={`widget-container ${isBundleMobile ? 'bundle-mobile-container' : ''}`}>
				{isBundleMobile && <h3 className="text-orange">Price Buster Bundles</h3>}
				{widgets}
				{isBundleMobile && (
					<div className={`${CLASS}__wrapper__item ${CLASS}__footnote`}>
						Extra board&nbsp;
						<span className="strikethrough">${price.full}</span>
						<span className="text-pink">&nbsp;${price.disc}</span>
					</div>
				)}
			</div>
			<div className={`free-shipping`}>
				<pricingImages.FreeShipping />
				<span>FREE Shipping Incl.</span>
			</div>
		</div>
	);
};

const WidgetBody = ({ coupon, level, price, idx, animationIn, animationTimeout, platform }) => {
	const { type, discountFree, metadata } = coupon;
	const currency = useSelector(state => state.coupon.currency) || 'USD';
	const lenght = currencyFormatter(price.full, currency, 2).length;

	const title =
		type === 'FREE' ? (
			<>{coupon.discountFree} FREE Boards</>
		) : type !== 'BUND' ? (
			<>
				Only&nbsp;
				<span className="strikethrough">{currencyFormatter(price.full, currency, 2)}</span>
				&nbsp;
				{currencyFormatter(price.disc, currency, 2)}
			</>
		) : (
			<>
				Only&nbsp;
				<span className="strikethrough">
					{currencyFormatter(level.fullPrice, currency, 2)}
				</span>
				&nbsp;
				{currencyFormatter(level.value, currency, 2)}
			</>
		);
	let subtitle;
	let footnote = true;
	let save = price.savePercent;
	let saveUnit = '%';
	if (type === 'BUND') {
		save = level.fullPrice - level.value;
		saveUnit = '$';
		footnote = true;
	} else if (type === 'DPER' || type === 'GIFT') {
		subtitle = <>{metadata.b_FREErule || 'Per Board'}</>;
		footnote = false;
	} else if (type === 'FREE') {
		save = price.full * discountFree;
		saveUnit = '$';
		subtitle = <>* {metadata.b_FREErule}</>;
	} else {
		footnote = false;
	}
	const isBundMobile = type === 'BUND' && isMobile;
	const isBundDesktop = type === 'BUND' && !isMobile;

	const topTitleJsx = level && (
		<div className={`${CLASS}__wrapper__item ${CLASS}__toptitle`}>
			<span>{level.boards}</span>&nbsp;Boards Deal!
		</div>
	);
	const titleJsx = (
		<div
			style={{ fontSize: lenght > 9 ? '14px' : lenght > 6 ? '16px' : '21px' }}
			className={`${CLASS}__wrapper__item ${CLASS}__title`}>
			<h3>{title}</h3>
		</div>
	);
	const subtitleJsx = subtitle && (
		<div className={`${CLASS}__wrapper__item ${CLASS}__subtitle`}>
			<span>{subtitle}</span>
		</div>
	);
	const footnoteJsx = footnote && (
		<div className={`${CLASS}__wrapper__item ${CLASS}__footnote`}>
			Extra board&nbsp;
			{coupon.b_disc ? (
				<span className="strikethrough">${price.full?.toFixed(2)}</span>
			) : null}
			<span className="text-pink">&nbsp;${price.disc}</span>
		</div>
	);

	const widgetClass = classnames(`${CLASS}__widget`, {
		[`${CLASS}__widget__show`]: type !== 'BUND',
		[`${CLASS}__widget__no-animation`]: isMobile,
	});
	const badgeSaveClass = classnames(`${CLASS}__badge_save`, {
		percent: saveUnit === '%',
		dollar: saveUnit === '$',
	});
	const saveAmountClass = classnames('saveAmount', {
		saveAmount_medium: String(save).length > 2,
	});

	const boardsKey = level?.boards;
	const itemProps = boardsKey ? [animationTimeout, animationIn[idx]] : [];

	if (isBundMobile) {
		return (
			<BundleCoupon
				coupon={coupon}
				platform={platform}
				level={level}
				saveUnit={saveUnit}
				save={save}
				badgeClass={badgeSaveClass}
				saveAmountClass={saveAmountClass}
			/>
		);
	}
	return (
		<div className={widgetClass}>
			<div className={`${CLASS}__wrapper ${isBundDesktop ? `${CLASS}__wrapper-bundle` : ''}`}>
				{level && wrapInTransition(topTitleJsx, boardsKey + 1, ...itemProps)}
				{wrapInTransition(titleJsx, boardsKey + 2, ...itemProps)}
				{subtitle && wrapInTransition(subtitleJsx, boardsKey + 3, ...itemProps)}
				{footnote && wrapInTransition(footnoteJsx, boardsKey + 4, ...itemProps)}
			</div>
			<div className={`${CLASS}__badge`}>
				<img src={pricingImages.offerRoundalUnderline} alt="discount" />
				<p className={badgeSaveClass}>
					<span>SAVE</span>
					{level ? (
						<CSSTransition
							key={level.text}
							timeout={animationTimeout}
							classNames="saveItem"
							in={animationIn[idx]}>
							<span className={saveAmountClass}>{save}</span>
						</CSSTransition>
					) : (
						<span className={saveAmountClass}>{save}</span>
					)}
					<span className="saveUnit">{saveUnit}</span>{' '}
				</p>
			</div>
		</div>
	);
};

const BundleCoupon = ({ level, saveUnit, save, badgeClass, saveAmountClass }) => (
	<div className={`${CLASS}-bundle-mobile`}>
		<div className={`${CLASS}-bundle-left text-grey`}>
			<span className="text-orange">&nbsp;{level.boards}</span>
			<span className="text-small">for{'  '}</span>
			<span className="strikethrough">&nbsp;${level.fullPrice}</span>&nbsp;
			<span className="text-orange">
				<span className="text-small">&nbsp;&nbsp;Only</span> ${level.value}
			</span>
		</div>
		<div>
			<div className={`${CLASS}__badge`}>
				<img src={pricingImages.offerRoundalUnderline} alt="discount" />
				<p className={badgeClass}>
					<span className="text-small">SAVE</span>
					<span className={saveAmountClass + ' text-medium'}>{save}</span>
					<span className="saveUnit">{saveUnit}</span>{' '}
				</p>
			</div>
		</div>
	</div>
);

const wrapInTransition = (jsxElement, key, timeout, animationIn) =>
	key ? (
		<CSSTransition key={key} timeout={timeout} classNames="wrapperItem" in={animationIn}>
			{jsxElement}
		</CSSTransition>
	) : (
		<>{jsxElement}</>
	);

const mapStateToProps = ({ app, coupon, checkout }) => ({
	discount: checkout.discount,
	platform: app.platform,
	coupon: coupon.coupon,
	priceOne: app.priceOne,
});

export default connect(mapStateToProps, null)(Pricing);

import React, { useState, useEffect, useRef } from 'react';
import {
  CardCVCElement,
  CardNumberElement,
  CardExpiryElement,
  injectStripe,
} from 'react-stripe-elements';
import { useSelector, useDispatch } from 'react-redux';

import { usePrevious } from '../../../utils/hooks';
import {
  updateCheckout,
  updateStripeInfo,
} from '../../../redux/checkout/actions';
import { updateUserEmail } from '../../../redux/user/actions';
import { EMAIL_REGEX } from '../../../utils/regularExpressions';

import './StripeMobileForm.scss';

const CLASS = 'sb-StripeMobileForm';

const createOptions = (padding) => {
  return {
    style: {
      base: {
        fontSize: '16px',
        fontWeight: 400,
        letterSpacing: 0,
        color: '#284E7B',
        fontFamily: `'Montserrat', sans-serif`,
        '::placeholder': {
          color: '#284E7B50',
          fontFamily: 'Montserrat, sans-serif',
          letterSpacing: '1px',
          fontWeight: 400,
          fontSize: '14px',
        },
        ...(padding ? { padding } : {}),
      },
      invalid: {
        color: '#e51b72',
      },
    },
  };
};

export const tokenize = () => {
  document.getElementById('btn-stripeMobileCheckout').click();
};

const StripeMobileForm = ({ handleUpdateEmail, fontSize, stripe }) => {
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [cardCVCErrorMessage, setCardCVCErrorMessage] = useState(null);
  const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState(null);
  const [cardExpiryErrorMessage, setCardExpiryErrorMessage] = useState(null);

  const [complete, setComplete] = useState({
    card: false,
    expDate: false,
    cvc: false,
  });

  const dispatch = useDispatch();
  const { user, checkout } = useSelector((state) => state);
  const previousUserData = usePrevious(user);

  useEffect(() => {
    const { card, expDate, cvc } = complete;
    if (card && expDate && cvc) {
      tokenize();
    }
  }, [complete]);

  useEffect(() => {
    if (
      cardExpiryErrorMessage ||
      cardNumberErrorMessage ||
      cardCVCErrorMessage
    ) {
      dispatch(updateStripeInfo(null, null, null));
      dispatch(updateCheckout({ isLoadingToken: false }));
      localStorage.setItem('checkoutError', true);
      setHasError(true);
    }
  }, [cardExpiryErrorMessage, cardNumberErrorMessage, cardCVCErrorMessage]);

  useEffect(() => {
    handleUpdateEmail(user.email);
    if (user.email) {
      setEmail(user.email);
      setIsValidEmail(true);
    }
  }, []);

  useEffect(() => {
    if (previousUserData?.email !== user.email && EMAIL_REGEX.test(user.email))
      setIsValidEmail(true);
    if (previousUserData && user && previousUserData?.email !== user.email) {
      setEmail(user.email);
    }
  }, [user]);

  // VALIDATE FIELDS ON CHANGE
  const handleCardChange = (change) => {
    setComplete((state) => ({ ...state, card: change.complete }));
    let errorMsg;
    if (change.error || change.empty || !change.complete) {
      if (change.error) {
        errorMsg = change.error.message;
      } else {
        errorMsg = 'Enter full card number';
      }
      setCardNumberErrorMessage(errorMsg);
      setHasError(true);
    } else {
      setCardNumberErrorMessage(null);
    }
    dispatch(updateCheckout({ error: true }));
    validateFields();
  };

  const handleExpiryChange = (change) => {
    setComplete((state) => ({ ...state, expDate: change.complete }));
    let errorMsg;
    if (change.error || change.empty || !change.complete) {
      if (change.error) {
        errorMsg = change.error.message;
      } else {
        errorMsg = 'Enter full expiry date';
      }
      setCardExpiryErrorMessage(errorMsg);
      setHasError(true);
    } else {
      setCardExpiryErrorMessage(null);
    }
    validateFields();
  };

  const handleCVCChange = (change) => {
    setComplete((state) => ({ ...state, cvc: change.complete }));
    let errorMsg;
    if (change.error || change.empty || !change.complete) {
      if (change.error) {
        errorMsg = change.error.message;
      } else {
        errorMsg = 'Enter full CVC';
      }
      setCardCVCErrorMessage(errorMsg);
      setHasError(true);
    } else {
      setCardCVCErrorMessage(null);
    }
    validateFields();
  };

  const validateFields = () => {
    if (
      cardExpiryErrorMessage ||
      cardNumberErrorMessage ||
      cardCVCErrorMessage ||
      !isValidEmail
    ) {
      setHasError(true);
      localStorage.setItem('checkoutError', true);
    } else {
      setHasError(false);
      localStorage.setItem('checkoutError', false);
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    dispatch(updateCheckout({ cardPaymentOpen: false }));
    if (stripe && isValidEmail && email) {
      dispatch(updateCheckout({ isLoadingToken: true }));
      stripe
        .createToken()
        .then((payload) => {
          if (!payload.token) {
            dispatch(
              updateCheckout({
                isLoadingToken: false,
                stripeResponse: payload,
              }),
            );
            return;
          }
          let user = JSON.parse(localStorage.getItem('user'));
          if (user) {
            user.stripe = payload;
          } else {
            user = {
              stripe: payload,
            };
          }
          user.checkoutEmail = email;

          const { brand, last4 } = payload.token.card;
          dispatch(updateStripeInfo(payload, brand, last4));
          dispatch(updateCheckout({ isLoadingToken: false, email }));
          dispatch(updateUserEmail(email));
          localStorage.setItem('user', JSON.stringify(user));
        })
        .catch((stripeError) => {
          dispatch(updateStripeInfo(null, null, null));
          dispatch(updateCheckout({ isLoadingToken: false, stripeError }));
          localStorage.setItem('checkoutError', true);
          setHasError(true);
        });
    } else {
      dispatch(updateStripeInfo(null, null, null));
      localStorage.setItem('checkoutError', true);
      setHasError(true);
    }
  };

  return (
    <div className={CLASS}>
      <form
        onSubmit={handleSubmit}
        id="stripe-form"
        style={{ gap: '8px', display: 'flex', flexDirection: 'column' }}
      >
        <label
          style={{
            fontSize: '14px',
            fontWeight: 400,
            color: '#284E7B',
          }}
        >
          <span style={{ opacity: 0.4 }}>Card Number</span>
          <div style={{ position: 'relative' }}>
            <CardNumberElement
              onChange={handleCardChange}
              className={`${CLASS}-formElement`}
              {...createOptions(fontSize)}
            />
            <div style={{ position: 'absolute', right: '12px', top: 0 }}>
              {checkout.isLoadingToken &&
                complete.card &&
                complete.expDate &&
                complete.cvc && <Loader />}
              {!checkout.isLoadingToken && checkout.stripe && (
                <CCCheck style={{ width: '24px' }} />
              )}
            </div>
          </div>
          {cardNumberErrorMessage ? (
            <p className={`${CLASS}-errorMessage`}>{cardNumberErrorMessage}</p>
          ) : null}
        </label>
        <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
          <label
            style={{
              fontSize: '14px',
              fontWeight: 400,
              color: '#284E7B',
              flex: 1,
            }}
          >
            <span style={{ opacity: 0.4 }}>Exp. Date</span>
            <CardExpiryElement
              onChange={handleExpiryChange}
              className={`${CLASS}-formElement`}
              {...createOptions(fontSize)}
            />
            {cardExpiryErrorMessage ? (
              <p className={`${CLASS}-errorMessage`}>
                {cardExpiryErrorMessage}
              </p>
            ) : null}
          </label>
          <label
            style={{
              flex: 1,
              fontWeight: 400,
              fontSize: '14px',
              color: '#284E7B',
            }}
          >
            <span style={{ opacity: 0.4 }}>CVC Number</span>
            <CardCVCElement
              onChange={handleCVCChange}
              {...createOptions(fontSize)}
              className={`${CLASS}-formElement`}
            />
            {cardCVCErrorMessage ? (
              <p className={`${CLASS}-errorMessage`}>{cardCVCErrorMessage}</p>
            ) : null}
          </label>
          <button
            hidden
            id="btn-stripeMobileCheckout"
            className={`${CLASS}-btnSubmit`}
          />
        </div>
      </form>
    </div>
  );
};

export default injectStripe(StripeMobileForm);

const CCCheck = ({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-check"
    width="44"
    height="44"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#00b341"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...style}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M5 12l5 5l10 -10" />
  </svg>
);

const Loader = () => (
  <div
    style={{ marginTop: '12px', marginBottom: '12px' }}
    className="sk-fading-circle"
  >
    <div className="sk-circle1 sk-circle"></div>
    <div className="sk-circle2 sk-circle"></div>
    <div className="sk-circle3 sk-circle"></div>
    <div className="sk-circle4 sk-circle"></div>
    <div className="sk-circle5 sk-circle"></div>
    <div className="sk-circle6 sk-circle"></div>
    <div className="sk-circle7 sk-circle"></div>
    <div className="sk-circle8 sk-circle"></div>
    <div className="sk-circle9 sk-circle"></div>
    <div className="sk-circle10 sk-circle"></div>
    <div className="sk-circle11 sk-circle"></div>
    <div className="sk-circle12 sk-circle"></div>
  </div>
);

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import * as storage from '../../../utils/storage';
import date from '../../../utils/getDate';
import { couponActions } from '../../../redux/coupon';
import * as actions from './actionCreators';

import Confetti from './Confetti/Confetti';
import smile from '../../../assets/images/emojiHearts.png';
import './OrderCompleted.scss';

const CLASS = `sb-OrderCompleted`;

const OrderCompleted = () => {
  const { app, user, checkout } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleInit = () => {
    dispatch(actions.initState());
    dispatch(couponActions.validateCoupon('NextOrder'));
  };

  const userData = storage.get(storage.names.shippingInfo);
  const name = userData && userData.fullName.split(' ');

  const { shipping } = app.cms;

  return (
    <>
      <div className={CLASS}>
        <Confetti />
        <div className={`${CLASS}-message`}>
          <div className={`${CLASS}-message-user`}>
            <p>Hey </p> <p className="upper">{name[0]}</p>
          </div>
          <p style={{ marginBottom: '10px', color: '#B30423' }}>
            You’ve unlocked an exclusive Treat! Enjoy 65% off your next order!
          </p>
          <span style={{ fontSize: '10px', color: '#afafaf' }}>
            Valid until midnight* PST
          </span>
        </div>
        <div className={`${CLASS}-imageWrapper`}>
          <img src={smile} alt="lovedSmiley" />
        </div>
        <p className={`${CLASS}-orderStatus`}>Order Completed ;)</p>
        <span className={`${CLASS}-storylab`}>
          <p> StoryLAB&#x24B8; is starting to produce your boards</p>
          <p>Order No: #{checkout.orderNumber}</p>
          <p>
            Confirmation sent: <span>{user.email}</span>
          </p>
          {/* <br /> */}
        </span>
        <div className={`${CLASS}-delivery`}>
          <p>
            {/* <img src={footerImages.cardbox} alt="cardbox" />{' '} */}
            <span>Est. Delivery: {date(Number(shipping['option1'].days))}</span>
          </p>
        </div>
      </div>
      <div
        className={`sb-Footer sb-Footer-completed`}
        style={{ position: 'absolute', bottom: '16px' }}
      >
        <div className={`sb-Footer-button`}>
          <Link to="/NextOrder" onClick={handleInit}>
            <span>Create More</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default OrderCompleted;

export const INITIALIZE_COUPON_START = 'INITIALIZE_COUPON_START';
export const INITIALIZE_COUPON_ERROR = 'INITIALIZE_COUPON_ERROR';
export const INITIALIZE_COUPON_END = 'INITIALIZE_COUPON_END';

export const VALIDATE_COUPON_START = 'VALIDATE_COUPON_START';
export const VALIDATE_COUPON_ERROR = 'VALIDATE_COUPON_ERROR';
export const VALIDATE_COUPON_END = 'VALIDATE_COUPON_END';

export const GET_VOUCHERIFY_START = 'GET_VOUCHERIFY_START';
export const GET_VOUCHERIFY_ERROR = 'GET_VOUCHERIFY_ERROR';
export const GET_VOUCHERIFY_END = 'GET_VOUCHERIFY_END';

export const CLEAR_COUPON = 'CLEAR_COUPON';
export const RESET_CURRENCY = 'RESET_CURRENCY';

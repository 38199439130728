import React from 'react';

import './Spinner.scss';

const CLASS = 'sb-Spinner';

const Spinner = ({ homeClass }) => {
	return (
		<div className={`${CLASS} ${homeClass ? `${CLASS}${homeClass}` : ''}`}>
			<div className={`${CLASS}-cube ${CLASS}-cube1`} />
			<div className={`${CLASS}-cube ${CLASS}-cube2`} />
			<div className={`${CLASS}-cube ${CLASS}-cube3`} />
			<div className={`${CLASS}-cube ${CLASS}-cube4`} />
			<div className={`${CLASS}-cube ${CLASS}-cube5`} />
			<div className={`${CLASS}-cube ${CLASS}-cube6`} />
			<div className={`${CLASS}-cube ${CLASS}-cube7`} />
			<div className={`${CLASS}-cube ${CLASS}-cube8`} />
			<div className={`${CLASS}-cube ${CLASS}-cube9`} />
		</div>
	);
};

export default Spinner;

import { post } from '../../../utils/http';
import { useDispatch, useSelector } from 'react-redux';
import { verifyAddress } from '../shipping-info/ShippingInfo';
import { a_klarnaButtonPressed } from '../../../api/analytics';
import { checkoutError, updateCheckout } from '../../../redux/checkout/actions';

export const Klarna = () => {
  const dispatch = useDispatch();
  const { checkout, user, app } = useSelector((state) => state);
  const { shippingCost, totalPrice } = checkout;
  const { email, country } = user;
  const currency = useSelector((state) => state.coupon.currency) || 'USD';
  const doKlarna = async () => {
    dispatch(updateCheckout({ paypalApproving: true }));
    a_klarnaButtonPressed(app.remarketingUrl);
    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const response = await post('/order/klarna', null, {
      amount: ((totalPrice + shippingCost) * 100).toFixed(0),
      currency,
    });
    const { client_secret: clientSecret } = response;
    stripe
      .confirmKlarnaPayment(clientSecret, {
        payment_method: {
          billing_details: {
            email,
            address: {
              country:
                country.toLowerCase() === 'usa' ||
                country.toLowerCase() === 'united states'
                  ? 'US'
                  : country,
            },
          },
        },
        return_url: window.location.href,
      })
      .then((result) => {
        if (result.error) {
          result.error.message = result.error.message.split('.')[0];
          dispatch(checkoutError(result.error));
        }
      })
      .finally(() => {
        dispatch(updateCheckout({ paypalApproving: false }));
      });
  };
  return (
    <div>
      {user.shippingInfoError ? (
        <div
          onClick={() => verifyAddress()}
          style={{
            gap: '8px',
            height: '55px',
            display: 'flex',
            cursor: 'pointer',
            borderRadius: '4px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FEB4C7',
          }}
        >
          <span style={{ fontWeight: 600 }}>Pay later with</span>
          <svg
            height="18"
            fill="none"
            viewBox="0 0 57 13"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#17120F"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.84833 12.7592H0V0H2.84833V12.7592ZM9.95943 0H7.17288C7.17288 2.34803 6.12281 4.50314 4.292 5.91309L3.18802 6.76307L7.46561 12.7598H10.9826L7.04666 7.24189C8.91236 5.3319 9.95943 2.76228 9.95943 0ZM14.5025 12.7558H11.8126V0.00244385H14.5025V12.7558ZM22.6297 3.93377V4.49811C21.9038 3.98907 21.0278 3.68992 20.0829 3.68992C17.5822 3.68992 15.555 5.77404 15.555 8.34495C15.555 10.9159 17.5822 13 20.0829 13C21.0278 13 21.9038 12.7008 22.6297 12.1919V12.7558H25.1989V3.93377H22.6297ZM22.6219 8.345C22.6219 9.59856 21.5795 10.6148 20.2938 10.6148C19.0082 10.6148 17.9658 9.59856 17.9658 8.345C17.9658 7.09144 19.0082 6.07542 20.2938 6.07542C21.5795 6.07542 22.6219 7.09144 22.6219 8.345ZM49.7342 4.49811V3.93377H52.3033V12.7558H49.7342V12.1919C49.0084 12.7008 48.1323 13 47.1873 13C44.6866 13 42.6594 10.9159 42.6594 8.34495C42.6594 5.77404 44.6866 3.68992 47.1873 3.68992C48.1323 3.68992 49.0084 3.98907 49.7342 4.49811ZM47.3983 10.6148C48.684 10.6148 49.7262 9.59856 49.7262 8.345C49.7262 7.09144 48.684 6.07542 47.3983 6.07542C46.1125 6.07542 45.0703 7.09144 45.0703 8.345C45.0703 9.59856 46.1125 10.6148 47.3983 10.6148ZM38.094 3.69649C37.0677 3.69649 36.0964 4.02406 35.447 4.9278V3.93429H32.8889V12.7558H35.4784V8.11977C35.4784 6.77824 36.3535 6.1213 37.4071 6.1213C38.5362 6.1213 39.1853 6.81477 39.1853 8.10151V12.7558H41.7515V7.14568C41.7515 5.09268 40.1637 3.69649 38.094 3.69649ZM29.1733 3.93393V5.08293C29.6883 4.39371 30.6479 3.93444 31.6912 3.93444V6.50149C31.6864 6.50143 31.6817 6.50118 31.677 6.50092C31.6716 6.50063 31.6663 6.50034 31.6607 6.50034C30.6442 6.50034 29.1792 7.2473 29.1792 8.63693V12.7558H26.5429V3.93393H29.1733ZM53.4184 11.2843C53.4184 10.3688 54.1402 9.62673 55.0306 9.62673C55.9209 9.62673 56.6429 10.3688 56.6429 11.2843C56.6429 12.1995 55.9209 12.9417 55.0306 12.9417C54.1402 12.9417 53.4184 12.1995 53.4184 11.2843Z"
            />
          </svg>
        </div>
      ) : (
        <div
          onClick={doKlarna}
          style={{
            gap: '8px',
            height: '55px',
            display: 'flex',
            cursor: 'pointer',
            borderRadius: '4px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FEB4C7',
          }}
        >
          <span style={{ fontWeight: 600 }}>Pay later with</span>
          <svg
            height="18"
            fill="none"
            viewBox="0 0 57 13"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#17120F"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.84833 12.7592H0V0H2.84833V12.7592ZM9.95943 0H7.17288C7.17288 2.34803 6.12281 4.50314 4.292 5.91309L3.18802 6.76307L7.46561 12.7598H10.9826L7.04666 7.24189C8.91236 5.3319 9.95943 2.76228 9.95943 0ZM14.5025 12.7558H11.8126V0.00244385H14.5025V12.7558ZM22.6297 3.93377V4.49811C21.9038 3.98907 21.0278 3.68992 20.0829 3.68992C17.5822 3.68992 15.555 5.77404 15.555 8.34495C15.555 10.9159 17.5822 13 20.0829 13C21.0278 13 21.9038 12.7008 22.6297 12.1919V12.7558H25.1989V3.93377H22.6297ZM22.6219 8.345C22.6219 9.59856 21.5795 10.6148 20.2938 10.6148C19.0082 10.6148 17.9658 9.59856 17.9658 8.345C17.9658 7.09144 19.0082 6.07542 20.2938 6.07542C21.5795 6.07542 22.6219 7.09144 22.6219 8.345ZM49.7342 4.49811V3.93377H52.3033V12.7558H49.7342V12.1919C49.0084 12.7008 48.1323 13 47.1873 13C44.6866 13 42.6594 10.9159 42.6594 8.34495C42.6594 5.77404 44.6866 3.68992 47.1873 3.68992C48.1323 3.68992 49.0084 3.98907 49.7342 4.49811ZM47.3983 10.6148C48.684 10.6148 49.7262 9.59856 49.7262 8.345C49.7262 7.09144 48.684 6.07542 47.3983 6.07542C46.1125 6.07542 45.0703 7.09144 45.0703 8.345C45.0703 9.59856 46.1125 10.6148 47.3983 10.6148ZM38.094 3.69649C37.0677 3.69649 36.0964 4.02406 35.447 4.9278V3.93429H32.8889V12.7558H35.4784V8.11977C35.4784 6.77824 36.3535 6.1213 37.4071 6.1213C38.5362 6.1213 39.1853 6.81477 39.1853 8.10151V12.7558H41.7515V7.14568C41.7515 5.09268 40.1637 3.69649 38.094 3.69649ZM29.1733 3.93393V5.08293C29.6883 4.39371 30.6479 3.93444 31.6912 3.93444V6.50149C31.6864 6.50143 31.6817 6.50118 31.677 6.50092C31.6716 6.50063 31.6663 6.50034 31.6607 6.50034C30.6442 6.50034 29.1792 7.2473 29.1792 8.63693V12.7558H26.5429V3.93393H29.1733ZM53.4184 11.2843C53.4184 10.3688 54.1402 9.62673 55.0306 9.62673C55.9209 9.62673 56.6429 10.3688 56.6429 11.2843C56.6429 12.1995 55.9209 12.9417 55.0306 12.9417C54.1402 12.9417 53.4184 12.1995 53.4184 11.2843Z"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  framesSet,
  updateImagesForFrameOption,
} from '../../../redux/boards/actions';
import SingleFrame from './SingleFrame';
import './FrameSelect.scss';
import './LdsSpinner.scss';
import { u_returnFirstFrame } from './util';
import { isMobile } from '../../../utils/dimensions';

import bold from '../../../assets/images/boards/default/bold.png';
import canvas from '../../../assets/images/boards/default/canvas.png';
import classic from '../../../assets/images/boards/default/classic.png';
import classicDark from '../../../assets/images/boards/default/classic-dark.png';
import classicLight from '../../../assets/images/boards/default/classic-light.png';
import clean from '../../../assets/images/boards/default/clean.png';
import eco from '../../../assets/images/boards/default/eco.png';
import edge from '../../../assets/images/boards/default/edge.png';
import ever from '../../../assets/images/boards/default/ever.png';
import metallic from '../../../assets/images/boards/default/metallic.png';
import original from '../../../assets/images/boards/default/original.png';
import { updateApp } from '../../../redux/app/actions';

const mapping = {
  bold,
  canvas,
  classic,
  'classic-dark': classicDark,
  'classic-light': classicLight,
  clean,
  eco,
  edge,
  ever,
  metallic,
  original,
  'black-foam': original,
  'canvas-foam': canvas,
  'metallic-foam': metallic,
  'gray-foam': 'gray-foam',
  frame: 'frame',
};

const FrameSelect = () => {
  const [selected, setSelected] = useState('');
  const framesData = useSelector((state) => state.app.cms.frames);
  const { isCheckoutOpen } = useSelector((state) => state.checkout);
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  const updateFrame = (name) => {
    dispatch(framesSet(name));
    setSelected(name);
    dispatch(updateImagesForFrameOption(name));
  };

  useEffect(() => {
    if (framesData) {
      const frameName = u_returnFirstFrame(framesData);
      updateFrame(frameName);
    }
  }, [framesData]);

  const handleSelectFrame = (name) => {
    updateFrame(name);
  };

  if (!framesData)
    return (
      <div className="frame-select">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );

  let activeImages = framesData.filter((x) => x.isShown).length;

  return (
    <div
      className={
        app.modals.material ? 'frame-select-slideUp' : 'frame-select-slideDown'
      }
      style={{
        zIndex: 100,
        left: '50%',
        width: isMobile ? '100%' : '500px',
        padding: '24px',
        ...(isMobile ? { paddingBottom: 0 } : {}),
        background: 'white',
        borderRadius: '24px',
        position: 'absolute',
        transform: 'translate(-50%)',
        boxShadow: '0px 1px 59px rgba(35, 33, 66, 0.1)',
      }}
    >
      <div style={{ display: 'flex', marginBottom: 12 }}>
        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Select Style</div>
        <div
          style={{
            color: '#A638FC',
            fontSize: '16px',
            marginLeft: 'auto',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
          onClick={() => {
            dispatch(updateApp({ modals: { material: !app.modals.material } }));
          }}
        >
          Done
        </div>
      </div>
      <div
        className={`frame-select ${isCheckoutOpen && isMobile && 'hide'}`}
        style={{ overflow: 'hidden' }}
      >
        <div
          className="frames-container"
          style={{
            gap: '12px',
            overflowX: 'auto',
            paddingTop: '12px',
            paddingLeft: '2px',
            paddingRight: '2px',
          }}
        >
          {framesData.map((frame, i) => {
            return (
              <>
                {!!frame.isShown && (
                  <div
                    style={{
                      width: '140px',
                      height: '100%',
                      borderRadius: '12px',
                      marginBottom: '12px',
                      border:
                        frame.img !== selected
                          ? '3px solid #EAECEE'
                          : '3px solid #C03CFC',
                      transition: 'all 0.2s ease-in-out',
                      boxShadow:
                        frame.img === selected
                          ? '5px 5px 10px rgba(132, 132, 132, 0.2)'
                          : undefined,
                    }}
                  >
                    <SingleFrame
                      key={i}
                      text2={frame.text2}
                      text3={frame.text3}
                      checkoutDescription={frame.checkoutDescription}
                      name={frame.img}
                      defaultSrc={frame.img && mapping[frame.img]}
                      text={frame.text}
                      isSelected={frame.img === selected}
                      isShown={frame.isShown}
                      handleSelectFrame={handleSelectFrame}
                    />
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FrameSelect;

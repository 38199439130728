const { REACT_APP_STAGE } = process.env;

export const isProduction = REACT_APP_STAGE === 'production' ? true : false;

export const isStaging = REACT_APP_STAGE === 'staging' ? true : false;

export const search = window.location.search;
export const params = new URLSearchParams(search);

export const metaPixelTestCode = params.get('meta_pixel_test_code');

export const isDevelopment =
	(!isProduction && !isStaging) || window.location.search.includes('analytics=false')
		? true
		: false;

export const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const applePayAvailable = () => {
	if (window.ApplePaySession) {
		return true;
	} else {
		return false;
	}
}; 
 
export const utmMediumChecker = () => {
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const utmMedium = params.get('utm_medium');  
	return ["email", "flow-email", "campaign-email", "sms", "SMS"].some((value) => value === utmMedium);
}

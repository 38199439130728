import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { updateApp } from '../../../redux/app/actions';
import { useEffect, useState } from 'react';
import { sizeSet } from '../../../redux/app/actions';
import { u_returnFirstSize } from './util';

import calculatePrice, {
  calculatePriceForSize,
} from '../../../utils/price/calculatePrice';
import { a_materialSizeSelected } from '../../../api/analytics';
import { currencyFormatter } from '../upsell';

import { Large } from '../../../assets/images/Large';
import { Small } from '../../../assets/images/Small';
import { Medium } from '../../../assets/images/Medium';

export const SizeSelect = () => {
  const dispatch = useDispatch();
  const size_ = u_returnFirstSize();
  const app = useSelector((state) => state.app);
  const [selected, setSelected] = useState(size_);
  const { frames } = useSelector((state) => state.app.cms);
  const { selectedFrame } = useSelector((state) => state.boards);
  const { isCheckoutOpen, vipBoardPrice } = useSelector(
    (state) => state.checkout,
  );
  const [frame] = frames.filter((frame) => frame.img === selectedFrame);
  const { selectedSize } = useSelector((state) => state.app);

  const { oneTimeOrSubscription, subscriptions } = app;

  const { boards } = useSelector((state) => state.boards);
  const { coupon } = useSelector((state) => state.coupon);

  const maping = {
    8: <Small isMobile={isMobile} active={selected.toString() === '8'} />,
    16: <Large isMobile={isMobile} active={selected.toString() === '16'} />,
    12: <Medium isMobile={isMobile} active={selected.toString() === '12'} />,
  };

  const updateSize = (size) => {
    dispatch(sizeSet(size));
    setSelected(size);
  };

  const handleSelectSize = (size) => {
    updateSize(size);
  };

  const getPrice = (size) => {
    const framePrice = frame?.sizes[size].price;
    const price = calculatePriceForSize(
      [boards[0]],
      coupon,
      framePrice,
      oneTimeOrSubscription,
      subscriptions,
    );
    return price;
  };

  useEffect(() => {
    const isSizeActive = frame?.sizes[+selected]?.isActive === true;
    if (!isSizeActive) {
      handleSelectSize(8);
    }
  }, [frame, selected, selectedSize]);

  return (
    <div
      className={
        app.modals.size ? 'frame-select-slideUp' : 'frame-select-slideDown'
      }
      style={{
        zIndex: 100,
        left: '50%',
        width: isMobile ? '100%' : '500px',
        padding: '24px',
        background: 'white',
        borderRadius: '24px',
        ...(isMobile ? { paddingBottom: 0 } : {}),
        position: 'absolute',
        transform: 'translate(-50%)',
        boxShadow: '0px 1px 59px rgba(35, 33, 66, 0.1)',
      }}
    >
      <div style={{ display: 'flex', paddingBottom: 12 }}>
        <div
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            flexDirection: 'column',
          }}
        >
          Select Size
        </div>
        <div
          style={{
            color: '#A638FC',
            fontSize: '16px',
            marginLeft: 'auto',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
          onClick={() => {
            dispatch(updateApp({ modals: { size: !app.modals.size } }));
          }}
        >
          Done
        </div>
      </div>
      <div
        className={`frame-select ${isCheckoutOpen && isMobile && 'hide'}`}
        style={{ overflow: 'hidden' }}
      >
        <div
          className="frames-container"
          style={{
            gap: '12px',
            overflowX: 'auto',
            paddingTop: '12px',
            paddingLeft: '2px',
            paddingRight: '2px',
          }}
        >
          {[8, 12, 16].map((size) => {
            const isSizeActive = frame?.sizes[size].isActive === true;
            return (
              <div
                key={size}
                style={{
                  marginBottom: '12px',
                  display: !isSizeActive ? 'none' : 'block',
                }}
              >
                <div
                  style={{
                    width: isMobile ? '105px' : '140px',
                    height: '100%',
                    cursor: 'pointer',
                    textAlign: 'center',
                    borderRadius: '12px',
                    border:
                      selected != size
                        ? '3px solid #EAECEE'
                        : '3px solid #C03CFC',
                    boxShadow:
                      selected == size
                        ? '5px 5px 10px rgba(132, 132, 132, 0.2)'
                        : undefined,
                  }}
                  onClick={() => {
                    handleSelectSize(size);
                    a_materialSizeSelected(size);
                  }}
                  className={`single-frame ${selected == size && 'selected'}`}
                >
                  {maping[size]}
                  <div
                    style={{
                      padding: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p
                      style={{
                        color: selected == size ? '#A637FC' : undefined,
                        fontSize: '16px',
                        fontWeight: 'bold',
                        margin: 0,
                      }}
                    >
                      {size}” x {size}”
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ paddingBottom: isMobile ? '24px' : '0px' }}>
        <div
          style={{
            gap: 8,
            fontSize: 14,
            fontWeight: 700,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Price per tile:
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              visibility: 'hidden',
            }}
          >
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6333 8.25746L7.55444 1.1895C7.26287 0.912389 6.91964 0.695358 6.54428 0.550768C6.17921 0.388438 5.78587 0.299211 5.38649 0.288128H1.26662C1.10038 0.286749 0.935625 0.319404 0.782481 0.384082C0.629338 0.448761 0.491051 0.544094 0.37613 0.664216C0.256008 0.779137 0.160675 0.917424 0.0959964 1.07057C0.0313181 1.22371 -0.00133658 1.38847 4.18914e-05 1.5547V5.67302C0.0110848 6.07241 0.100314 6.46576 0.262682 6.83081C0.405632 7.20423 0.622934 7.54472 0.90141 7.83164L7.98025 14.9214C8.09575 15.0401 8.23435 15.1339 8.38751 15.197C8.54067 15.2601 8.70513 15.2911 8.87074 15.2881C9.03774 15.2894 9.20333 15.2576 9.35801 15.1946C9.51269 15.1317 9.65342 15.0388 9.77211 14.9214L14.6333 10.0509C14.752 9.93538 14.8458 9.79677 14.9089 9.64361C14.972 9.49046 15.003 9.326 15 9.16038C15.0015 8.99313 14.9698 8.82725 14.9069 8.67228C14.8439 8.51732 14.7509 8.37634 14.6333 8.25746ZM4.06552 4.35205C3.94937 4.47195 3.80987 4.56677 3.65564 4.63065C3.50141 4.69453 3.33573 4.72613 3.16882 4.72348C2.83302 4.72307 2.5111 4.58949 2.27366 4.35205C2.03622 4.11461 1.90265 3.79269 1.90224 3.4569C1.90265 3.12111 2.03622 2.79919 2.27366 2.56175C2.5111 2.32431 2.83302 2.19074 3.16882 2.19032C3.50461 2.19074 3.82653 2.32431 4.06397 2.56175C4.30141 2.79919 4.43498 3.12111 4.43539 3.4569C4.43783 3.62355 4.40613 3.78894 4.34225 3.94289C4.27838 4.09683 4.18367 4.23608 4.06397 4.35205H4.06552Z"
                fill="#BF3EFB"
              />
            </svg>
            <span style={{ color: '#284E7B', marginLeft: '4px' }}>
              {coupon?.discount?.percent}% OFF
            </span>
          </div>
          <div
            style={{
              marginLeft: 'auto',
              fontWeight: 600,
              color: '#A637FC',
              display: 'flex',
              gap: 4,
              alignItems: 'center',
            }}
          >
            <span style={{ fontSize: 15 }} className="beforePrice">
              {currencyFormatter(getPrice(size_).originalPrice, 'USD', 2)}
            </span>
            <span style={{ fontSize: 15 }}>
              {currencyFormatter(getPrice(size_).totalPrice, 'USD', 2)}
            </span>
          </div>
        </div>
        {/* {vipBoardPrice && (
          <div
            style={{
              fontWeight: 600,
              color: '#BA1956',
              fontSize: '15px',
              textAlign: 'right',
            }}
          >
            VIP {currencyFormatter(vipBoardPrice, 'USD', 2)}
          </div>
        )} */}
      </div>
    </div>
  );
};

import * as TYPES from './action-types';

import { isProduction } from '../../utils/envHandler';

const INITIAL_STATE = {
	initalizing: false,
	initialized: false,
	uploading: false,
	uploaded: false,
	invalidating: false,
	invalidated: false,
	showMenu: false,
	error: false,
	boards: null,
	boardsBackup: null,
	boardInCropper: null,
	lowQualityBoards: null,
	showUploadOptions: false,
	cloudinaryURL: null,
	selectedFrame: localStorage.getItem('selectedFrame') || 'classic',
};

// ----- FRAMES -----

const framesSet = (state, frame) => {
	localStorage.setItem('selectedFrame', frame);
	return {
		...state,
		selectedFrame: frame,
	};
};

// ----- INITALIZE -----

const initializeStart = state => {
	return {
		...state,
		initalizing: true,
		error: null,
	};
};

const initializeError = (state, error) => {
	return {
		...state,
		initalizing: false,
		initialized: false,
		error,
	};
};

const initializeEnd = (state, boards) => {
	return {
		...state,
		boards,
		boardsBackup: boards,
		initalizing: false,
		initialized: true,
		error: null,
		uploaded: boards?.every(b => b.secureUrl) ? true : false,
	};
};

// ----- UPLOAD -----

const uploadStart = state => {
	return {
		...state,
		uploading: true,
		uploaded: false,
		error: null,
	};
};

const uploadError = (state, error) => {
	return {
		...state,
		uploading: state.boards?.some(b => !b.status),
		uploaded: false,
		error,
	};
};

const uploadBlobEnd = (state, { images, isLeft }) => {
	const blobs = images.map(image => {
		return {
			...image,
			blob: image.blob,
			frame: image.frame,
		};
	});
	return {
		...state,
		error: null,
		boards: state.boards
			? isLeft
				? [...blobs, ...state.boards]
				: [...state.boards, ...blobs]
			: [...blobs],
	};
};

const updateLowQuality = (state, images) => {
	return {
		...state,
		error: null,
		lowQualityBoards: state.lowQualityBoards
			? [...state.lowQualityBoards, ...images]
			: [...images],
	};
};
 
const uploadEnd = (state, { images, uploadsDone = false }) => {
	let boards;
	if (state.boards) {
		boards = state.boards.map(b => ({ ...b }));
	} else return;

	images.forEach(image => {
		const index = boards.findIndex(board => board.id === image.id);
		if (index !== -1) {
			if (image.secureUrl) {
				const s3Folder = isProduction ? 'RemarketingImagesProduction' : 'RemarketingImagesStaging';
				const s3FileKey = image.secureUrl
					.split('/')
					.slice(3)
					.join('/');
				const cloudinaryUrl = `https://res.cloudinary.com/storyboards/image/upload/t_cart1/${s3Folder}/${s3FileKey}`;
				boards[index][`cloudinaryURL ${index + 1}`] = cloudinaryUrl;
			}
			Object.assign(boards[index], {
				id: image.id,
				secureUrl: image.secureUrl,
				blob: image.blob,
				s3Params: image.s3Params,
				status: image.status,
				// frame: image.frame,
				meta: image.meta,
			});
		}
	});

	let moreBoards = false;
	if (boards.some(board => !board.status)) {
		moreBoards = true;
	}

	return {
		...state,
		uploading: moreBoards,
		uploaded: boards.every(board => board.status === 'done') || uploadsDone,
		error: null,
		hash: Math.random().toString(36),
		boards,
		boardsBackup: boards,
	};
};

// ----- REMOVE -----

const removeStart = state => {
	return {
		...state,
		error: null,
	};
};

const removeError = (state, error) => {
	return {
		...state,
		error,
	};
};

const removeEnd = (state, id) => {
	return {
		...state,
		error: null,
		hash: Math.random().toString(36),
		boards: state.boards.filter(board => board.id !== id),
	};
};

// ----- INVALIDATE -----

const invalidateStart = state => {
	return {
		...state,
		invalidating: true,
		invalidated: false,
		error: null,
	};
};

const invalidateError = (state, error) => {
	return {
		...state,
		invalidating: false,
		invalidated: false,
		error,
	};
};

const invalidateEnd = state => {
	return {
		...state,
		invalidating: false,
		invalidated: true,
		error: null,
		boards: null,
	};
};

// ----- MENU -----

const showMenu = state => {
	return {
		...state,
		showMenu: true,
	};
};

const hideMenu = state => {
	return {
		...state,
		showMenu: false,
	};
};

// ----- SHOW UPLOAD OPTIONS -----

const showUploadOptions = state => {
	return {
		...state,
		showUploadOptions: true,
	};
};

const hideUploadOptions = state => {
	return {
		...state,
		showUploadOptions: false,
	};
};

// ----- UPDATE CROP DATA -----

const updateCropData = (state, boards) => {
	return {
		...state,
		hash: Math.random().toString(36),
		boards,
	};
};

const updateWidthAndHeight = (state, { board, image }) => {
	let boards = state.boards.map(b => ({ ...b }));
	for (let i = 0; i < boards.length; i++) {
		if (
			boards[i].id === board.id ||
			(boards[i].secureUrl && boards[i].secureUrl === board.secureUrl)
		) {
			boards[i].width = image.naturalWidth;
			boards[i].height = image.naturalHeight;
		}
	}
	return {
		...state,
		boards,
	};
};

// ----- LOW QUALITY -----

const keepLowQuality = (state, id) => {
	return {
		...state,
		lowQualityBoards: state.lowQualityBoards.filter(board => board.id !== id),
	};
};

const removeLowQuality = (state, id) => {
	return {
		...state,
		lowQualityBoards: state.lowQualityBoards.filter(board => board.id !== id),
	};
};

// ----- BOARDS REDUCER -----

const setBoardInCropper = (state, board) => {
	if (board !== null)
		return {
			...state,
			boardInCropper: { ...state.boardInCropper, ...board },
		};
	else
		return {
			...state,
			boardInCropper: null,
		};
};

export const boardsReducer = (state = INITIAL_STATE, { type, payload }) => {
	switch (type) {
		// ----- FRAMES -----
		case TYPES.FRAMES_SET:
			return framesSet(state, payload);

		// ----- INITIALIZE -----
		case TYPES.INITIALIZE_BOARDS_START:
			return initializeStart(state);
		case TYPES.INITIALIZE_BOARDS_ERROR:
			return initializeError(state, payload);
		case TYPES.INITIALIZE_BOARDS_END:
			return initializeEnd(state, payload);
		case TYPES.SET_BOARD_IN_CROPPER:
			return setBoardInCropper(state, payload);

		// ----- UPLOAD -----
		case TYPES.UPLOAD_IMAGE_START:
			return uploadStart(state);
		case TYPES.UPLOAD_IMAGE_ERROR:
			return uploadError(state, payload);
		case TYPES.UPLOAD_BLOB_END:
			return uploadBlobEnd(state, payload);
		case TYPES.UPDATE_LOW_QUALITY:
			return updateLowQuality(state, payload);
		case TYPES.UPLOAD_IMAGE_END:
			return uploadEnd(state, payload);

		// ----- REMOVE -----
		case TYPES.REMOVE_IMAGE_START:
			return removeStart(state);
		case TYPES.REMOVE_IMAGE_ERROR:
			return removeError(state, payload);
		case TYPES.REMOVE_IMAGE_END:
			return removeEnd(state, payload);

		// ----- INVALIDATE -----
		case TYPES.INVALIDATE_BOARDS_START:
			return invalidateStart(state);
		case TYPES.INVALIDATE_BOARDS_ERROR:
			return invalidateError(state, payload);
		case TYPES.INVALIDATE_BOARDS_END:
			return invalidateEnd(state);

		// ----- MENU -----
		case TYPES.SHOW_BOARDS_MENU:
			return showMenu(state);
		case TYPES.HIDE_BOARDS_MENU:
			return hideMenu(state);

		// ----- SHOW UPLOAD OPTIONS -----
		case TYPES.SHOW_UPLOAD_OPTIONS:
			return showUploadOptions(state);
		case TYPES.HIDE_UPLOAD_OPTIONS:
			return hideUploadOptions(state);

		// ----- UPDATE -----
		case TYPES.UPDATE_CROP_DATA:
			return updateCropData(state, payload);
		case TYPES.UPDATE_WIDTH_AND_HEIGHT:
			return updateWidthAndHeight(state, payload);

		// ----- LOW QUALITY -----
		case TYPES.KEEP_LOW_QUALITY:
			return keepLowQuality(state, payload);
		case TYPES.REMOVE_LOW_QUALITY:
			return removeLowQuality(state, payload);

		case TYPES.CLOUDINARY_URL:
			return {
				...state,
				cloudinaryURL: payload,
			};

		case TYPES.LOAD_SESSION_END:
			return {
				...state,
				boards: payload.boards,
				uploaded: true,
			};

		default:
			return state;
	}
};

import React from 'react';
import classnames from 'classnames';
import { batch, useDispatch } from 'react-redux';

import { setBoardInCropper } from '../../../redux/boards/actions';
import { updateApp } from '../../../redux/app/actions';
import { ReactComponent as EditIcon } from '../../../assets/images/add-image/33-edit_photo_icon-wht.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/images/add-image/34-remove-close_icon-wht.svg';
import './BoardMenu.scss';
import { isMobile } from '../../../utils/dimensions';

const CLASS = 'sb-BoardMenu';

const BoardMenu = ({ show, board, setShow, onRemove, isOverBoard }) => {
	const dispatch = useDispatch();

	const handleRemove = e => {
		e.stopPropagation();
		onRemove();
		setShow(false);
	};

	const handleEdit = e => {
		e.stopPropagation();
		batch(() => {
			dispatch(setBoardInCropper(board));
			dispatch(updateApp({ modals: { cropper: true } }));
		});
		setShow(false);
	};

	const boardMenuClass = classnames(CLASS, {
		[`${CLASS}-dock`]: !isOverBoard,
		[`${CLASS}-overBoard`]: isOverBoard,
		[`${CLASS}-show`]: show,
	});
	return (
    <>
      {isMobile ? (
        <div className={boardMenuClass} style={{ background: 'white' }}>
          <div>
            <span
              style={{
                padding: '16px',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 700,
                color: '#1C3E64',
                textTransform: 'uppercase',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              }}
            >
              Adjust Cropping
            </span>
            <button
              className={'sb-BoardMenu-edit'}
              onClick={handleEdit}
              style={{
                color: '#1C3E64',
                fontSize: '16px',
                fontWeight: 500,
                margin: 'unset',
                padding: '12px',
                paddingTop: '28px',
                paddingBottom: '28px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30 24C30 24.2652 29.8946 24.5196 29.7071 24.7071C29.5196 24.8946 29.2652 25 29 25H25V29C25 29.2652 24.8946 29.5196 24.7071 29.7071C24.5196 29.8946 24.2652 30 24 30C23.7348 30 23.4804 29.8946 23.2929 29.7071C23.1054 29.5196 23 29.2652 23 29V25H8C7.73478 25 7.48043 24.8946 7.29289 24.7071C7.10536 24.5196 7 24.2652 7 24V9H3C2.73478 9 2.48043 8.89464 2.29289 8.70711C2.10536 8.51957 2 8.26522 2 8C2 7.73478 2.10536 7.48043 2.29289 7.29289C2.48043 7.10536 2.73478 7 3 7H7V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2C8.26522 2 8.51957 2.10536 8.70711 2.29289C8.89464 2.48043 9 2.73478 9 3V23H29C29.2652 23 29.5196 23.1054 29.7071 23.2929C29.8946 23.4804 30 23.7348 30 24ZM12 9H23V20C23 20.2652 23.1054 20.5196 23.2929 20.7071C23.4804 20.8946 23.7348 21 24 21C24.2652 21 24.5196 20.8946 24.7071 20.7071C24.8946 20.5196 25 20.2652 25 20V8C25 7.73478 24.8946 7.48043 24.7071 7.29289C24.5196 7.10536 24.2652 7 24 7H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8C11 8.26522 11.1054 8.51957 11.2929 8.70711C11.4804 8.89464 11.7348 9 12 9Z"
                  fill="#C03CFC"
                />
              </svg>
              <span style={{ fontSize: '16px', fontWeight: 500 }}>
                Adjust Cropping
              </span>
            </button>
            <button
              className={'sb-BoardMenu-remove'}
              onClick={handleRemove}
              style={{
                fontSize: '16px',
                color: '#CB0000',
                padding: '12px',
                paddingTop: '28px',
                paddingBottom: '28px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.9395 6H22.9395V5C22.9362 4.20536 22.619 3.44421 22.0571 2.88231C21.4952 2.32042 20.7341 2.00329 19.9395 2H13.9395C13.1448 2.00329 12.3837 2.32042 11.8218 2.88231C11.2599 3.44421 10.9427 4.20536 10.9395 5V6H5.93945C5.67424 6 5.41988 6.10536 5.23235 6.29289C5.04481 6.48043 4.93945 6.73478 4.93945 7C4.93945 7.26522 5.04481 7.51957 5.23235 7.70711C5.41988 7.89464 5.67424 8 5.93945 8H6.93945V26C6.93945 26.5304 7.15017 27.0391 7.52524 27.4142C7.90031 27.7893 8.40902 28 8.93945 28H24.9395C25.4699 28 25.9786 27.7893 26.3537 27.4142C26.7287 27.0391 26.9395 26.5304 26.9395 26V8H27.9395C28.2047 8 28.459 7.89464 28.6466 7.70711C28.8341 7.51957 28.9395 7.26522 28.9395 7C28.9395 6.73478 28.8341 6.48043 28.6466 6.29289C28.459 6.10536 28.2047 6 27.9395 6ZM14.9395 21C14.9395 21.2652 14.8341 21.5196 14.6466 21.7071C14.459 21.8946 14.2047 22 13.9395 22C13.6742 22 13.4199 21.8946 13.2323 21.7071C13.0448 21.5196 12.9395 21.2652 12.9395 21V13C12.9395 12.7348 13.0448 12.4804 13.2323 12.2929C13.4199 12.1054 13.6742 12 13.9395 12C14.2047 12 14.459 12.1054 14.6466 12.2929C14.8341 12.4804 14.9395 12.7348 14.9395 13V21ZM20.9395 21C20.9395 21.2652 20.8341 21.5196 20.6466 21.7071C20.459 21.8946 20.2047 22 19.9395 22C19.6742 22 19.4199 21.8946 19.2323 21.7071C19.0448 21.5196 18.9395 21.2652 18.9395 21V13C18.9395 12.7348 19.0448 12.4804 19.2323 12.2929C19.4199 12.1054 19.6742 12 19.9395 12C20.2047 12 20.459 12.1054 20.6466 12.2929C20.8341 12.4804 20.9395 12.7348 20.9395 13V21ZM20.9395 6H12.9395V5C12.9395 4.73478 13.0448 4.48043 13.2323 4.29289C13.4199 4.10536 13.6742 4 13.9395 4H19.9395C20.2047 4 20.459 4.10536 20.6466 4.29289C20.8341 4.48043 20.9395 4.73478 20.9395 5V6Z"
                  fill="#CB0000"
                />
              </svg>
              <span style={{ fontSize: '16px', fontWeight: 500 }}>
                Remove
              </span>
            </button>
          </div>
        </div>
      ) : (
        <div className={boardMenuClass}>
          <div>
            <button className={'sb-BoardMenu-edit'} onClick={handleEdit}>
              <EditIcon />
              Adjust Cropping
            </button>
            <button className={'sb-BoardMenu-remove'} onClick={handleRemove}>
              <RemoveIcon />
              Remove
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default BoardMenu;

import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { updateCheckout } from '../../../redux/checkout/actions';
import CustomCheckbox from '../CustomCheckbox';
import formatDate from '../../../utils/formatDate';
import { customShipping, getDueDate } from '../../../utils/customShipping';
import classNames from 'classnames';
import './CustomShipping.scss';
import { currencyFormatter } from '../upsell';

export const CustomShipping = () => {
	const dispatch = useDispatch();
	const { app, user, checkout, coupon } = useSelector((state) => state);
	const { option1, option2 } = useSelector(state => {
		const option1 = {
			...state.app.cms.shipping.option1,
			option: 'option1',
			dueDate: getDueDate(state.app.cms.shipping.option1.days),
			index: 1,
		};
		const option2 = {
			...state.app.cms.shipping.option2,
			option: 'option2',
			dueDate: getDueDate(state.app.cms.shipping.option2.days),
			index: 2,
		};
		return { option1, option2 };
	});
	const boards = useSelector(state => state.boards.boards.length);
	const [selectedOption, setSelectedOption] = useState(
		option1.defaultOption ? 'option1' : 'option2',
	);
	let calcOptionPrice = option => customShipping(option, boards, option1, option2);
	const currency = useSelector(state => state.coupon.currency) || 'USD';

	const oneTimeOrSubscription = checkout.oneTimeOrSubscription;

	const { subscriptionEnabled } = app;

	useEffect(() => {
		dispatch(updateCheckout({ shippingCost: calcOptionPrice(selectedOption) }));
	}, [boards]);

	useEffect(() => {
		let cost;
		const defaultOption = option1.defaultOption ? 'option1' : 'option2';
		if (coupon.coupon.name.startsWith('CARDV')) {
			cost = 0;
		} else if (oneTimeOrSubscription === 'one-time') {
			cost = calcOptionPrice(defaultOption)
		} else if (oneTimeOrSubscription === 'subscription') {
			cost = 0;
		}
		dispatch(
			updateCheckout({
				shippingCost: cost,
				shippingOption: defaultOption,
			}),
		);
	}, [oneTimeOrSubscription, coupon.coupon.name]);

	const handleSelect = value => {
		setSelectedOption(value);
		dispatch(updateCheckout({ shippingOption: value, shippingCost: calcOptionPrice(value) }));
	};

	const renderShippingOption = ({ dueDate, enabled, index, option: optionName, text }) =>
		enabled ? (
			<div className="customshipping__option" key={index === 1 ? option2.text : option1.text}>
				{(index === 1 ? option2.enabled : option1.enabled) && (
					<CustomCheckbox
						checked={selectedOption === optionName}
						size={16}
						handler={handleSelect}
						value={optionName}
					/>
				)}
				<div style={{ width: '100%' }}>
					<label
						style={{ width: '100%', display: 'flex' }}
						className={classNames({
							'customshipping__option-selected': selectedOption === optionName,
						})}>
						<span>
							{text}: {formatDate(dueDate)}
						</span>
						<span
							style={{ marginLeft: 'auto' }}
							className={classNames('customshipping__option__price', {
								'customshipping__option-selected__price': selectedOption === optionName,
							})}>
							{calcOptionPrice(optionName) !== 0
								? currencyFormatter(calcOptionPrice(optionName), currency, 2)
								: 'FREE'}
						</span>
					</label>
				</div>
			</div>
		) : null;

	return (
		<div className="customshipping" style={{ marginTop: '-10px', marginBottom: '8px' }}>
			<h4
				style={{
					fontWeight: 700,
					fontSize: '16px',
					textTransform: 'uppercase',
					marginBottom: '-4px',
				}}
			>
				Shipping Options
			</h4>
			{[option1, option2].map(renderShippingOption)}
		</div>
	);
};

const getArrayBuffer = file => {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.onload = e => {
			const arrayBuffer = e.target.result;
			resolve(arrayBuffer);
		};
		reader.readAsArrayBuffer(file);
	});
};

export default getArrayBuffer;

export const Check = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#0B6B65"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.24693 15.1218C9.32038 15.0572 9.38977 14.9861 9.4543 14.9088L19.2658 3.12741C19.9425 2.31488 19.8272 1.1099 19.0077 0.438035C18.1882 -0.234344 16.9745 -0.119869 16.2969 0.693532L7.63339 11.0965L4.20602 6.98015C3.52921 6.16762 2.31551 6.05278 1.49563 6.72465C0.676561 7.39696 0.560703 8.60091 1.23752 9.41446L6.14301 15.3056C6.82027 16.119 8.03353 16.2335 8.8534 15.5611C9.01074 15.4321 9.14213 15.2835 9.24693 15.1218Z"
    />
  </svg>
);

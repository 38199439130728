import React, { useEffect, useState } from 'react';

import validate from '../../../validation';
import './Input.scss';

const CLASS = 'sb-Input';

const Input = ({
  name,
  value,
  style,
  label,
  errors,
  register,
  onChange,
  canceled,
  type = 'text',
  placeholder = 'Type something...',
}) => {
  const [error, setError] = useState(null);
  const [touched, setTouched] = useState(false);

  return (
    <div className={CLASS} style={{ ...style }}>
      <label>
        <span
          style={{
            opacity: 0.4,
            fontSize: '14px',
            fontWeight: 400,
            color: '#284E7B',
          }}
        >
          {label}
        </span>
        <input
          {...register(name, {
            required: 'This field is required',
            ...(name === 'email' && {
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Entered value does not match email format',
              },
            }),
          })}
          name={name}
          type={type}
          autoComplete="off"
          value={value || ''}
          placeholder={placeholder}
          className={`${CLASS}-field`}
          onChange={(e) => onChange(e.target.value)}
          style={{
            fontWeight: 400,
            fontSize: '16px',
            color: '#284E7B',
            borderRadius: '4px',
          }}
        />
      </label>
      {errors[name] && (
        <span style={{ color: '#e51b72', fontSize: '10pt', margin: '5px 0px' }}>
          {errors[name].message}
        </span>
      )}
    </div>
  );
};

export default Input;

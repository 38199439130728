import * as TYPES from './action-types';
import * as storage from '../../utils/storage';
import { post, get } from '../../utils/http';
import { readExitIntelEmail } from '../../utils/readCookie';

import { saveSessionEnd } from '../app/actions';
import { nanoid } from 'nanoid';
import axios from 'axios';
import { updateApp } from '../app/actions';
import { updateCheckout } from '../checkout/actions';
import { getClientIp } from '../../utils/getClientIp';

const getUser = () => storage.get(storage.names.shippingInfo);

export const updateUser = (payload) => ({
  type: TYPES.UPDATE_USER,
  payload,
});

const getKlaviyoEmail = () =>
  storage.get(storage.names.klaviyoEmail) ||
  readExitIntelEmail('exitintel_email');

// ----- INITIALIZE -----

const initializeStart = () => ({
  type: TYPES.INITIALIZE_USER_START,
});

const initializeEnd = (user, email) => ({
  type: TYPES.INITIALIZE_USER_END,
  payload: {
    user,
    email,
  },
});

export const initializeUser = () => async (dispatch, state) => {
  const ip = await getClientIp();
  dispatch(initializeStart());
  const shippingInfo = getUser();
  delete shippingInfo.showVipModal;
  if (shippingInfo?.email) {
    axios
      .post('/api/order/subscription', { email: shippingInfo?.email })
      .then(({ data }) => {
        dispatch(updateApp({ subscriptionEnabled: !data.result }));
        if (data.result) {
          if (!sessionStorage.getItem('vip_modal')) {
            dispatch(updateUser({ showVipModal: true }));
          }
          dispatch(updateCheckout({ oneTimeOrSubscription: 'one-time' }));
        }
      })
      .catch((error) => {
        // nothing
      });
  }
  dispatch(initializeEnd(shippingInfo || {}));
  dispatch({
    type: 'SAVE_USER_DETAILS',
    payload: { ip, browser: window.navigator.userAgent },
  });
};

export const saveSession = (data) => async (dispatch, getState) => {
  const { user } = getState();
  try {
    const result = await post(`/customer`, null, {
      ...data,
      user: {
        ...data.user,
        metadata: {
          date: new Date(),
          ip: user.ip,
          browser: window.navigator.userAgent,
        },
      },
    });
    dispatch(saveSessionEnd(result));
  } catch (err) {}
};

export const loadSession = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TYPES.LOAD_SESSION_START,
    });
    const userData = await get(`/customer/${id}`, null, { id });

    userData.boards = userData.boards?.map((board) => ({
      ...board,
      id: board.id || nanoid(12),
    }));

    localStorage.setItem('boards', JSON.stringify(userData.boards));
    dispatch({
      type: TYPES.LOAD_SESSION_END,
      payload: userData,
    });
  } catch (err) {
    dispatch({
      type: TYPES.LOAD_SESSION_ERROR,
    });
  }
};

const initializeKlaviyoStart = () => ({
  type: TYPES.INITIALIZE_KLAVIYO_USER_START,
});

const initializeKlaviyoError = (error) => ({
  type: TYPES.INITIALIZE_KLAVIYO_USER_ERROR,
  payload: error,
});

const initializeKlaviyoEnd = (email) => ({
  type: TYPES.INITIALIZE_KLAVIYO_USER_END,
  payload: {
    email,
  },
});

export const initializeKlaviyoUser = () => {
  return (dispatch, getState) => {
    dispatch(initializeKlaviyoStart());
    try {
      const klaviyoEmail = getKlaviyoEmail();
      dispatch(initializeKlaviyoEnd(klaviyoEmail));
    } catch (error) {
      dispatch(initializeKlaviyoError(error));
    }
  };
};

// ---- UPDATE SHIPPING INFO ----

export const updateShippingInfo = (payload) => ({
  type: TYPES.USER_UPDATE_SHIPPING_INFO,
  payload,
});

// ---- UPDATE EMAIL ----

export const updateUserEmail = (email) => (dispatch, getState) => {
  const shippingInfo = getState().user;
  storage.set(storage.names.shippingInfo, { ...shippingInfo, email });
  storage.set(storage.names.email, email);
  return dispatch({
    type: TYPES.USER_UPDATE_EMAIL,
    payload: email,
  });
};

// ----- SAVE USER DETAILS ----

export const saveUserDetails = (data) => (dispatch, getState) => {
  dispatch({ type: 'SAVE_USER_DETAILS', payload: data });
  if (data) {
    post('/customer/details', null, {
      ...data,
      email: getState().user.email,
    }).catch(() => null);
    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: { addressInfoOpen: false, addressError: false },
    });
  } else {
    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: { addressInfoOpen: false, addressError: true },
    });
  }
};

import React from 'react';
import { a_materialSelected } from '../../../api/analytics/index';

const SingleFrame = ({
  defaultSrc,
  name,
  text,
  text2,
  text3,
  isShown,
  isSelected,
  handleSelectFrame,
  checkoutDescription,
}) => {
  return (
    <div
      onClick={() => {
        handleSelectFrame(name);
        a_materialSelected(name);
      }}
      className={`single-frame ${!isShown && 'hide'} ${
        isSelected && 'selected'
      }`}
    >
      <img
        width="134px"
        height="109px"
        src={defaultSrc}
        alt="Storyboards frame"
        style={{
          objectFit: 'cover',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      />
      <div style={{ padding: '6px', display: 'flex', flexDirection: 'column' }}>
        <p style={{ fontSize: '15px', fontWeight: 'bold' }}>{text}</p>
        <span style={{ fontSize: '13px', fontWeight: 500 }}>{text2}</span>
        <span style={{ fontSize: '13px', fontWeight: 500 }}>{text3}</span>
      </div>
    </div>
  );
};

export default SingleFrame;

import React from 'react';

const CustomCheckbox = ({
  size,
  value,
  square,
  checked,
  handler,
  disabled,
}) => (
  <div
    className={'checkbox'}
    onClick={() => handler(value)}
    style={{
      cursor: 'pointer',
      width: `${size}px`,
      alignItems: 'center',
      position: 'relative',
      display: square ? '' : 'flex',
      pointerEvents: disabled ? 'none' : 'auto',
    }}
  >
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        position: 'absolute',
        ...(square && { borderRadius: `${3}px` }),
        ...(!square && { borderRadius: `${size}px` }),
        background: disabled ? 'rgba(0, 0, 0, 0.1)' : 'white',
        border: `1px solid ${checked ? '#a637fc' : '#9a9999'}`,
      }}
    >
      {checked && (
        <div
          style={{
            width: `${size - 5}px`,
            height: `${size - 5}px`,
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            ...(!square && { borderRadius: `${size - 5}px` }),
            backgroundImage:
              'linear-gradient(96.86deg, #a637fc -1.49%, #f34ef8 169.78%)',
          }}
        />
      )}
    </div>
  </div>
);

export default CustomCheckbox;

// import React from 'react';
// HEADER
import { ReactComponent as CouponWhite } from '../assets/images/header-footer/Coupon_Icon_white.svg';
import headerLogoWhite from '../assets/images/header-footer/StoryBoards_Icon_wht.svg';
import headerLogoWhiteNew from '../assets/images/header-footer/new-logo.svg';
import heart_white from '../assets/images/header-footer/heart_white.svg';
import { ReactComponent as HeartBlue } from '../assets/images/header-footer/heart_blue.svg';

// MENU
import faqMenuWhite from '../assets/images/header-footer/05_menu_faq_icon_wht.svg';
import { ReactComponent as FaqMenuBlue } from '../assets/images/header-footer/05_menu_faq_icon_blue.svg';
import helpMenuWhite from '../assets/images/header-footer/05_menu_help_icon_wht.svg';
import { ReactComponent as HelpMenuBlue } from '../assets/images/header-footer/05_menu_help_icon_blue.svg';

// FOOTER
import checkoutButtonIcon from '../assets/images/menu/02_button_checkout_icon.svg';
import checkoutButtonIconNew from '../assets/images/menu/17_button_checkout_icon.svg';
import cardbox from '../assets/images/emoji/package.png';

// GURANTEE DIV
import smileyFace from '../assets/images/emoji/smiley_animate.gif';

// PRICING DIV
import offerRoundalUnderline from '../assets/images/prices/offer_roundal_purple.svg';

import { ReactComponent as FreeShipping } from '../assets/images/free_shipping_icon.svg';

// IMAGE UPLOAD
import removeImageIcon from '../assets/images/add-image/34-remove-close_icon-wht.svg';
import editImageIcon from '../assets/images/add-image/33-edit_photo_icon-wht.svg';
import addImageIcon from '../assets/images/add-image/31-upload_icon-blu.svg';
import addImageCouponIcon from '../assets/images/add-image/upload-image.svg';

// CHECKOUT
import creditCardPurple from '../assets/images/menu/14_menu_pay_icon_purple.svg';

import housePurple from '../assets/images/menu/13_menu_address_icon_purple.svg';
import closeBlue from '../assets/images/menu/12_close_window.svg';
import closeBlueNew from '../assets/images/menu/15_close_window.svg';
import securePayment from '../assets/images/menu/10_secure_pay_icon.svg';
import securePaymentNew from '../assets/images/menu/16_secure_pay_icon.svg';
import discountImage from '../assets/images/elements/Get_30_off.gif';
import tip from '../assets/images/elements/TIP.gif';
import dropdownTrianglePurple from '../assets/images/dropdown-triangle-purple.svg';
import info from '../assets/images/info.svg'
// CREDIT CARDS
import americanExpressNew from '../assets/images/credit-cards/americanExpressNew.png';
import discoverNew from '../assets/images/credit-cards/discoverNew.png';
import masterCardNew from '../assets/images/credit-cards/masterCardNew.png';
import visaNew from '../assets/images/credit-cards/visaNew.png';

// FAQ
import whiteArrowLeft from '../assets/images/faq/left-arrow.svg';

//GIFT
import { ReactComponent as WhiteArrowLeft } from '../assets/images/faq/left-arrow.svg';

export const headerMenuImages = {
  headerLogoWhite,
  headerLogoWhiteNew,
  faqMenuWhite,
  FaqMenuBlue,
  helpMenuWhite,
  HelpMenuBlue,
  CouponWhite,
  heart_white,
  HeartBlue,
};

export const checkoutImages = {
  creditCardPurple,
  housePurple,
  closeBlue,
  closeBlueNew,
  securePayment,
  securePaymentNew,
  discountImage,
  tip,
  info,
  dropdownTrianglePurple,
};

export const creditCardImages = {
  visaNew,
  masterCardNew,
  americanExpressNew,
  discoverNew,
};

export const footerImages = {
  checkoutButtonIcon,
  checkoutButtonIconNew,
  cardbox,
};

export const guranteeImages = {
  smileyFace,
};

export const pricingImages = {
  cardbox,
  offerRoundalUnderline,
  FreeShipping,
};

export const imageUploadImages = {
  removeImageIcon,
  editImageIcon,
  addImageIcon,
  addImageCouponIcon,
};

export const faqImages = {
  whiteArrowLeft,
};

export const giftImages = {
  WhiteArrowLeft,
};

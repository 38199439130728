import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import { ReactComponent as YourSvg } from '../../../assets/images/upload.svg';

import { boardsActions } from '../../../redux/boards';
import { imageUploadImages } from '../../../lib/images';
import * as events from '../../../utils/events';

import './UploadImage.scss';

const { uploadImage, hideUploadOptions } = boardsActions;
const CLASS = 'sb-UploadImage';

const UploadImage = ({ onUpload, left, discount = 30, uploadRight }) => {
	const dispatch = useDispatch();
	const app = useSelector(state => state.app);
	const frame = useSelector(state => state.boards.selectedFrame);
	const coupon = useSelector(state => state.coupon?.coupon);
	let upload = null;

	const loadFiles = files => {
		const images = [];
		for (let file of files) {
			const id = nanoid();
			const blob = URL.createObjectURL(file);
			images.push({ id, file, blob, frame });
		}
		onUpload(images);
		dispatch(uploadImage(images, left, app.remarketingId));
		if (upload) upload.value = null;
	};

	const onFileChange = event => {
		const { files } = event.target;
		loadFiles(files);
	};

	const onUploadClick = e => {
		e.stopPropagation();
		events.onUploadClick();
		upload.click();
		dispatch(hideUploadOptions());
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: loadFiles });

	const showDiscAmount = coupon?.type === 'DPER';

	return (
    <div
    //   className={classNames(CLASS, `${CLASS}-${frame}`, {
    //     'sb-UploadImage-right': uploadRight,
    //   })}
    >
      <div {...getRootProps()}>
        {/* <div>
					<div className={`${CLASS}-coupon`} onClick={onUploadClick}>
						{!isDragActive && !discount && (
							<img
								src={imageUploadImages.addImageIcon}
								className={`${CLASS}-upload-icon`}
								alt="upload icon"
							/>
						)}
						{coupon ? (
							<>
								<img
									src={imageUploadImages.addImageCouponIcon}
									className={`upload-coupon`}
									alt="upload coupon icon"
								/>
								{showDiscAmount && (
									<span className={`${CLASS}-discount`}>{discount}</span>
								)}
								<span
									className={`${CLASS}-discount-coupon ${
										!showDiscAmount ? 'coupon-bund' : ''
									}`}>
									COUPON
								</span>
								{!showDiscAmount && (
									<span
										className={`${CLASS}-discount-coupon ${
											!showDiscAmount ? 'coupon-bund' : ''
										}`}>
										ACTIVATED
									</span>
								)}
							</>
						) : (
							<div>
								<input {...getInputProps()} />
								{isDragActive ? (
									<p>Drop the photo here ...</p>
								) : (
									<p className={`${CLASS}-drag-drop`}>Drag & Drop</p>
								)}
							</div>
						)}
					</div>
				</div>
				<span className={`${CLASS}-corner`} />
				<span className={`${CLASS}-corner`} />
				<span className={`${CLASS}-corner`} />
				<span className={`${CLASS}-corner`} /> */}
        <div onClick={onUploadClick}>
          <YourSvg />
        </div>
      </div>
      <input
        hidden
        accept="image/*"
        multiple
        type="file"
        ref={(ref) => {
          upload = ref;
        }}
        onChange={onFileChange}
      />
    </div>
  );
};

export default UploadImage;

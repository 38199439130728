export const Large = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 175 109"
    style={{
      width: props.isMobile ? '100px' : '136px',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    }}
  >
    <g clip-path="url(#a1)">
      <path
        fill="#EEE"
        fill-opacity=".4"
        d="M0 12C0 5.373 5.373 0 12 0h151c6.627 0 12 5.373 12 12v97H0V12Z"
      />
      <g filter="url(#b1)">
        <path
          fill="url(#c1)"
          fill-opacity=".2"
          d="m122 161.234 12.358-7.548L159 100.564l-7.888-2.33-9.193 19.579-12.449 25.975-7.47 17.446Z"
        />
      </g>
      <path
        stroke={props.active ? '#A637FC' : '#284E7B'}
        d="M58.5 30.5h49v49h-49z"
        opacity=".5"
      />
      <g filter="url(#d1)" opacity=".8">
        <path
          fill={props.active ? '#A637FC' : '#284E7B'}
          d="M78.784 47.953h3.482v12.21h7.545V63H78.784V47.953Z"
        />
      </g>
    </g>
    <defs>
      <filter
        id="b1"
        width="38.34"
        height="64.34"
        x="121.33"
        y="97.564"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_1140_1074"
          stdDeviation=".335"
        />
      </filter>
      <filter
        id="d1"
        width="149.899"
        height="153.92"
        x="9.348"
        y="17.241"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="38.724" />
        <feGaussianBlur stdDeviation="34.718" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.542688 0 0 0 0 0.303116 0 0 0 0 0.729022 0 0 0 0.17 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1140_1074"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1140_1074"
          result="shape"
        />
      </filter>
      <linearGradient
        id="c1"
        x1="120.276"
        x2="137.371"
        y1="154.256"
        y2="147.993"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset=".578" stop-color="#D9D9D9" stop-opacity="0" />
      </linearGradient>
      <clipPath id="a1">
        <path
          fill="#fff"
          d="M0 12C0 5.373 5.373 0 12 0h151c6.627 0 12 5.373 12 12v97H0V12Z"
        />
      </clipPath>
    </defs>
  </svg>
);

import ReactPixel from 'react-facebook-pixel';
import { nanoid } from 'nanoid';

import * as storage from '../../utils/storage';

const pixelId = process.env.REACT_APP_META_PIXEL_ID;

export const pixelSetup = userData => {
	let advancedMatching = {};
	const externalId = storage.get('external_id');

	const formatMatching = (userData, externalId) => {
		if (userData && userData.email) {
			advancedMatching.em = userData.email.toLowerCase();
		}
		if (userData && userData.fullName) {
			const [firstName, ...nameParts] = userData.fullName.trim().split(' ');
			advancedMatching.fn = firstName.toLowerCase();
			advancedMatching.ln = nameParts.join(' ').toLowerCase();
		}
		if (userData && userData.city) {
			advancedMatching.ct = userData.city.trim().toLowerCase();
		}
		if (storage.get(storage.names.stateCode)) {
			advancedMatching.st = storage.get(storage.names.stateCode).toLowerCase();
		}
		if (userData && userData.postalCode) {
			advancedMatching.zp = Number(userData.postalCode);
		}
		if (storage.get(storage.names.countryCode)) {
			advancedMatching.cn = storage.get(storage.names.countryCode).toLowerCase();
		}
		if (userData && userData.phone) {
			advancedMatching.ph = Number('1' + userData.phone.replace(/[\(\)\-\s]/g, ''));
		}
		if (externalId) {
			advancedMatching.external_id = externalId;
		} else {
			const newExternalId = nanoid();
			storage.set('external_id', newExternalId);
			advancedMatching.external_id = newExternalId;
		}
	};
	formatMatching(userData, externalId);

	const options = {
		autoConfig: true, // set pixel's autoConfig
		debug: false, // enable logs
	};
	ReactPixel.init(pixelId, advancedMatching, options);
	// window.fbq && window.fbq('init', pixelId, advancedMatching);
};

export const facebookPixel = ReactPixel;

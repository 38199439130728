import React, { useEffect, useState } from 'react';
import { a_popupEmailDisplayed, a_popupEmailSubmitted } from '../../../api/analytics';
import { post } from '../../../utils/http';
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_REGEX } from '../../../utils/regularExpressions';
import { updateUserEmail } from '../../../redux/user/actions';
import exclamation from "../../../assets/images/exclamation.svg";
import star from "../../../assets/images/star.svg";
import logo from "../../../assets/images/logo.svg";
import close from "../../../assets/images/close.svg";

import './index.scss';

const className = 'sb-EmailPopup';

const EmailPopup = ({ show, setShow }) => { 
	const dispatch = useDispatch();
	const remarketingId = useSelector(state => state.app.remarketingId);
	const [valid, setValid] = useState(false);
	const [email, setEmail] = useState('');
	const [showDoneNotification, setShowDoneNotification] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [error, setError] = useState(false);
	const user = useSelector(state => state.user);
	const checkout = useSelector(state => state.checkout);
	const hide = !show || !!user.email || !!user.klaviyoEmail || checkout.isCheckoutOpen;

	useEffect(() => {
		show && a_popupEmailDisplayed();
	}, [show]);

	const handleSubmitEmail = () => {    
			setDisabled(true);
			error && setError(false);
			post('/customer/popup', null, { email, remarketingId }).then(()=>{ 
				localStorage.setItem('email', email);
				a_popupEmailSubmitted();
				setShowDoneNotification(true);
				setTimeout(() => {
					dispatch(updateUserEmail(email)); 
					setShow(false);
				}, 3000);
			}).catch((err) => {
				setError(true);
				setDisabled(false);
			  });
	};

	const doneNotification = (
		<div className={`${className}__notification`}>
			<img src={star} alt="star" /> 
			<span>
				Your Boards Have Been <br /> Saved!
			</span>
		</div>
	);

	if (hide) return null;
	return (
		<>
			<div className={`${className}__backdrop`} onClick={() => setShow(false)} />
			<div className={className}>
				{!showDoneNotification ? (
					<>
						<button 
							className={`${className}__close`}
							onClick={() => setShow(false)}>
							<img src={close} alt="close"/> 
						</button>
         				<img src={exclamation} alt="save" className={`${className}__bookmark`}/> 
         				<img src={logo} alt="logo" className={`${className}__logo`}/>  
						<p className={`${className}__title`}>Wait...</p>
						<span className={`${className}__subtitle`}>Don't lose your hard work!</span> 
						<div className={`${className}__email`}>
							<p> 
								Enter your email below to save your photos.
							</p> 
							<input
							placeholder="Enter your email"
							onChange={({ target: { value } }) => {
								setEmail(value);
								setValid(EMAIL_REGEX.test(value));
							}}></input>
							{error && <span className={`${className}__error`}>
								We couldn’t save your progress, please try again.
							</span>}
							<button
								className={`${className}__save`}
								disabled={!valid || disabled}
								onClick={handleSubmitEmail}>
								{disabled ? 'saving progress...' : error ? 'retry' : 'save my progress'}
							</button>  
						</div>
					</>
				) : (
					doneNotification
				)}
			</div>
		</>
	);
};

export default EmailPopup;

export const INITIALIZE_APP_START = 'INITIALIZE_APP_START';
export const INITIALIZE_APP_ERROR = 'INITIALIZE_APP_ERROR';
export const INITIALIZE_APP_END = 'INITIALIZE_APP_END';

export const GET_INFO_ERROR = 'GET_INFO_ERROR';
export const GET_INFO_END = 'GET_INFO_END';

export const SET_FOOTER_VISIBILITY = 'SET_FOOTER_VISIBILITY';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';


export const UPDATE_APP = 'UPDATE_APP';

export const SAVE_SESSION_END = 'SAVE_SESSION_END';
export const SIZE_SET = 'SIZE_SET';
import { isMobile } from '../../dimensions';

export const openGorgiasChat = () => {
  window.GorgiasChat.init().then((chat) => {
    let chatContainer = document.getElementById('gorgias-chat-container');
    let chatButton = document.querySelector('#chat-button');
    chatContainer.style.display = '';
    if (isMobile) chatButton.style.display = 'none';
    chat.open();
  });
};

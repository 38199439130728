export const KlarnaLogo = ({ style }) => (
  <svg
    width="35"
    height="24"
    fill="none"
    viewBox="0 0 35 24"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style }}
  >
    <rect
      x="0.5"
      y="0.5"
      width="34"
      height="23"
      rx="3.5"
      fill="#FEB4C7"
      stroke="#D9D9D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.42416 15.3796H4V9H5.42416V15.3796ZM8.97973 9H7.58645C7.58645 10.174 7.06142 11.2516 6.14601 11.9565L5.59402 12.3815L7.73282 15.3799H9.49132L7.52334 12.6209C8.4562 11.666 8.97973 10.3811 8.97973 9ZM11.2513 15.3779H9.90633V9.00122H11.2513V15.3779ZM15.3148 10.9669V11.249C14.9519 10.9945 14.5139 10.845 14.0415 10.845C12.7911 10.845 11.7775 11.887 11.7775 13.1725C11.7775 14.4579 12.7911 15.5 14.0415 15.5C14.5139 15.5 14.9519 15.3504 15.3148 15.096V15.3779H16.5995V10.9669H15.3148ZM15.3109 13.1726C15.3109 13.7994 14.7898 14.3075 14.1469 14.3075C13.5041 14.3075 12.9829 13.7994 12.9829 13.1726C12.9829 12.5458 13.5041 12.0378 14.1469 12.0378C14.7898 12.0378 15.3109 12.5458 15.3109 13.1726ZM28.8671 11.249V10.9669H30.1517V15.3779H28.8671V15.096C28.5042 15.3504 28.0662 15.5 27.5937 15.5C26.3433 15.5 25.3297 14.4579 25.3297 13.1725C25.3297 11.887 26.3433 10.845 27.5937 10.845C28.0662 10.845 28.5042 10.9945 28.8671 11.249ZM27.6991 14.3075C28.342 14.3075 28.8631 13.7994 28.8631 13.1726C28.8631 12.5458 28.342 12.0378 27.6991 12.0378C27.0563 12.0378 26.5352 12.5458 26.5352 13.1726C26.5352 13.7994 27.0563 14.3075 27.6991 14.3075ZM23.047 10.8483C22.5338 10.8483 22.0482 11.0121 21.7235 11.464V10.9672H20.4445V15.378H21.7392V13.06C21.7392 12.3892 22.1768 12.0608 22.7035 12.0608C23.2681 12.0608 23.5927 12.4075 23.5927 13.0509V15.378H24.8757V12.5729C24.8757 11.5464 24.0819 10.8483 23.047 10.8483ZM18.5866 10.9669V11.5414C18.8442 11.1968 19.324 10.9671 19.8456 10.9671V12.2507L19.8411 12.2505L19.8385 12.2504L19.8304 12.2501C19.3221 12.2501 18.5896 12.6236 18.5896 13.3184V15.3778H17.2714V10.9669H18.5866ZM30.7092 14.642C30.7092 14.1843 31.0701 13.8133 31.5153 13.8133C31.9605 13.8133 32.3214 14.1843 32.3214 14.642C32.3214 15.0997 31.9605 15.4708 31.5153 15.4708C31.0701 15.4708 30.7092 15.0997 30.7092 14.642Z"
      fill="#17120F"
    />
  </svg>
);

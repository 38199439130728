import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as classnames from 'classnames';

import { a_checkoutError, a_upsellAccepted } from '../../../api/analytics';
import { toggleModal } from '../../../redux/app/actions';
import { appActions } from '../../../redux/app';
import { checkoutError } from '../../../redux/checkout/actions';
import { post } from '../../../utils/http';
import notify from '../../../utils/notify';
import * as storage from '../../../utils/storage';
import toggleChatPopup from '../../../utils/toggleChatPopup';
import './Upsell.scss';
import { restorePrice } from '../../../redux/coupon/actions';

export const currencyFormatter = (value, currency, digits) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency,
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: digits,
  });
  return formatter.format(value);
};

const Upsell = () => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const checkout = useSelector((state) => state.checkout);
  const user = useSelector((state) => state.user);
  const [posting, setPosting] = useState(false);
  const { originalMinBoards, originalPriceOne } = app;
  const orderId = useSelector((state) => state.checkout.orderId);
  const boardsNumber = useSelector((state) => state.boards.boardsBackup);
  const currency = useSelector((state) => state.coupon.currency) || 'USD';
  const { paypalActive, applePayActive, klarnaActive, tax } = checkout;

  useEffect(() => {
    toggleChatPopup(false, 'upsell');
    return () => {
      toggleChatPopup(true, 'upsell');
      dispatch(
        restorePrice({
          minBoards: originalMinBoards,
          priceOne: originalPriceOne,
        }),
      );
    };
  }, []);

  const classes = classnames('sb-Upsell', 'sb-Upsell-fullscreen');

  if (!app.cms) return null;

  const buildPrice = () => {
    let price = 0;
    const { minPrice, maxBoards, boardPrice } = app.cms.upsell;

    if (boardsNumber.length <= maxBoards) {
      price = minPrice;
    } else {
      price = Number((boardPrice * boardsNumber.length).toFixed(2));
    }

    return price;
  };

  const formatPrice = (price) => {
    if (price % 1 === 0) {
      return currencyFormatter(price, currency, 0);
    }
    return currencyFormatter(price, currency, 2);
  };

  const offerTitle = app.cms.upsell?.popupTitle
    ? app.cms.upsell.popupTitle.replace('$price', formatPrice(buildPrice()))
    : 'Let our Expert Editors add their magical touch to your photos';

  const offerDescription =
    app.cms.upsell?.popupDescription ||
    'Colour Enhancement, Vibrance, White Balance, Image Sharpen';

  const handleClose = () => {
    if (posting) return;
    dispatch(toggleModal({ modal: 'upsell', toggle: false }));
    dispatch(toggleModal({ modal: 'upsellPopup', toggle: false }));
  };

  const buildUpsell = () => {
    return {
      ...app.cms.upsell,
      price: buildPrice(),
    };
  };

  const handleUpsell = () => {
    if (posting) return;

    const upsell = buildUpsell();
    const order = {
      id: checkout.orderNo,
      boardsNumber: boardsNumber.length,
      upsell,
      customer: user.email,
    };

    setPosting(true);
    handleClose();
    const request = {
      user,
      orderId,
      upsell,
      tax,
      remarketingId: app.remarketingId,
      currency: currency.toLowerCase(),
    };
    if (klarnaActive || paypalActive) {
      request.upsell.offline = true;
    }
    post('/order/upsell', null, request)
      .then(() => {
        setPosting(false);
        a_upsellAccepted(order);
      })
      .catch((error) => {
        setPosting(false);
        dispatch(checkoutError(error));
        dispatch(appActions.updateApp({ footer: true }));
        notify({ error, msg: 'Stripe checkout error' });
        a_checkoutError(error);
        storage.remove(storage.names.checkoutError);
      });
  };

  if (!app.cms.upsell) return null;

  return (
    <div className={classes}>
      <img src={app.cms.upsell.titleImage} />
      <h2>{offerTitle}</h2>
      <div className={'sb-Upsell__description'}>
        <img src={app.cms.upsell.descriptionImage} />
        <span>{offerDescription}</span>
      </div>
      <>
        <span
          style={{ cursor: 'pointer' }}
          onClick={handleUpsell}
          className="sb-Upsell__button-yes"
        >
          YES! Add to Order
        </span>
        <span
          style={{ cursor: 'pointer' }}
          className="sb-Upsell__button-no"
          onClick={handleClose}
        >
          No Thanks. Complete Order.
        </span>
      </>
    </div>
  );
};

export default Upsell;

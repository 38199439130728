import React from 'react';
import { connect } from 'react-redux';

import { boardsActions } from '../../../redux/boards';
import toggleChatPopup from '../../../utils/toggleChatPopup';

import './LowQuality.scss';

const CLASS = 'sb-LowQuality';

const LowQuality = (props) => {
  let content = null;
  const image = props.lowQualityBoards[0];
  const imageId = image?.id;

  const onClick = (keep) => {
    if (props.lowQualityBoards.length === 1) {
      content.classList.add(`${CLASS}-content_slideDown`);
      setTimeout(() => {
        if (keep) return props.keepLowQuality(imageId);
        return props.removeLowQuality(imageId);
      }, 200);
    } else {
      if (keep) return props.keepLowQuality(imageId);
      return props.removeLowQuality(imageId);
    }
    toggleChatPopup(true, 'low quality');
  };

  return (
    <div className={CLASS}>
      <div
        className={`${CLASS}-content ${CLASS}-content_slideUp`}
        ref={(ref) => (content = ref)}
      >
        <div className={`${CLASS}-image`}>
          <span className={`${CLASS}-title`}>⚠️ Poor Print Quality Alert</span>
          <img src={image.blob} alt="low quality" />
          <span className={`${CLASS}-text`}>
            Images with low resolution will result in poor quality prints.
          </span>
        </div>
        <div className={`${CLASS}-menu`}>
          <span onClick={() => onClick(true)}>Proceed Anyway</span>
          <span onClick={() => onClick(false)}>Replace Image</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lowQualityBoards: state.boards.lowQualityBoards,
});

const mapDispatchToProps = (dispatch) => ({
  keepLowQuality: (blob) => dispatch(boardsActions.keepLowQuality(blob)),
  removeLowQuality: (blob) => dispatch(boardsActions.removeLowQuality(blob)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LowQuality);

import * as TYPES from './action-types';

const INITIAL_STATE = {
	loading: false,
	initialized: false,
	validated: false,
	error: null,
	voucherifyError: null,
	coupon: null,
	currency: 'USD',
};

// ----- INITALIZE -----

const initializeStart = state => ({
	...state,
	loading: true,
	initialized: false,
	error: null,
});

const resetCurrency = state => ({
	...state,
	currency: undefined,
});

const initializeError = (state, error) => ({
	...state,
	loading: false,
	initialized: false,
	error,
});

const initializeEnd = (state, coupon) => ({
	...state,
	loading: false,
	initialized: true,
	error: null,
	coupon: coupon?.gift
		? { ...coupon, discountPercentage: state.coupon?.coupon?.discountPercentage }
		: coupon,
	currency: coupon?.metadata?.currency_c
});

// ---- VALIDATE ----

const validateStart = state => ({
	...state,
	loading: true,
	validated: false,
	error: null,
});

const validateError = (state, error) => ({
	...state,
	loading: false,
	validated: false,
	error,
});

const validateEnd = (state, coupon) => ({
	...state,
	loading: false,
	validated: true,
	error: null,
	coupon,
});

const getVoucherifyStart = state => ({
	...state,
	loading: true,
	validated: false,
	error: null,
	voucherifyError: null,
});

const getVoucherifyEnd = (state, coupon) => {
	const { discountPercentage, metadata, discount } = coupon;
	const utmDiscount = state?.coupon?.discountPercentage;
	coupon.discountPercentage =
		metadata.type === 'CARD' && !discountPercentage ? utmDiscount : discountPercentage;
	return {
		...state,
		loading: false,
		validated: true,
		voucherifyError: null,
		coupon,
		currency: coupon?.metadata?.currency_c,
		discount
	};
};
const getVoucherifyError = (state, error) => ({
	...state,
	loading: false,
	validated: false,
	voucherifyError: error,
});

export const couponReducer = (state = INITIAL_STATE, { type, payload }) => {
	switch (type) {
		// ----- INITIALIZE -----
		case TYPES.INITIALIZE_COUPON_START:
			return initializeStart(state);
		case TYPES.INITIALIZE_COUPON_ERROR:
			return initializeError(state, payload);
		case TYPES.INITIALIZE_COUPON_END:
			return initializeEnd(state, payload);

		// ------ VALIDATE -----
		case TYPES.VALIDATE_COUPON_START:
			return validateStart(state);
		case TYPES.VALIDATE_COUPON_ERROR:
			return validateError(state, payload);
		case TYPES.VALIDATE_COUPON_END:
			return validateEnd(state, payload);

		// ---- Voucherify ----
		case TYPES.GET_VOUCHERIFY_START:
			return getVoucherifyStart(state);
		case TYPES.GET_VOUCHERIFY_ERROR:
			return getVoucherifyError(state, payload);
		case TYPES.GET_VOUCHERIFY_END:
			return getVoucherifyEnd(state, payload);

		case TYPES.RESET_CURRENCY:
			return resetCurrency(state);

		// ----- CLEAR -----
		case TYPES.CLEAR_COUPON:
			return {
				...state,
				coupon: null,
			};

		default:
			return state;
	}
};

import React from 'react';
import { hydrate, render } from 'react-dom';
import { Helmet } from 'react-helmet';
import App from './app';
import seo from './lib/seo';
import * as serviceWorker from './utils/serviceWorker';
import './index.scss';

if (window.location.host.includes('www')) {
	let url = window.location.href.replace('www.', '');
	window.location.replace(url);
}

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
	hydrate(
		<>
			<Helmet>
				<meta name="description" content={seo.main.description} />
				<meta
					name="og:description"
					property="og:description"
					content={seo.main.description}
				/>
				<meta name="og:title" property="og:title" content={seo.main.title} />
				<title>{seo.main.title}</title>
			</Helmet>
			<App />
		</>,
		rootElement,
	);
} else {
	render(
		<>
			<Helmet>
				<meta name="description" content={seo.main.description} />
				<meta
					name="og:description"
					property="og:description"
					content={seo.main.description}
				/>
				<meta name="og:title" property="og:title" content={seo.main.title} />
				<title>{seo.main.title}</title>
			</Helmet>
			<App />
		</>,
		rootElement,
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

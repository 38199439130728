export const PaypalLogo = () => (
  <svg
    width="35"
    height="24"
    viewBox="0 0 35 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="34"
      height="23"
      rx="3.5"
      fill="white"
      stroke="#D9D9D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.90001 15.2409H8.34439C8.23794 15.2409 8.1474 15.3183 8.1308 15.4234L7.50163 19.4124C7.48913 19.4912 7.55009 19.5621 7.62993 19.5621H8.37259C8.47905 19.5621 8.56958 19.4847 8.58618 19.3795L8.75588 18.3036C8.77225 18.1982 8.863 18.1209 8.96924 18.1209H9.46169C10.4864 18.1209 11.0778 17.6251 11.2323 16.6424C11.3019 16.2125 11.2353 15.8747 11.0339 15.6381C10.8129 15.3784 10.4206 15.2409 9.90001 15.2409ZM10.0795 16.6979C9.99443 17.2561 9.56792 17.2561 9.15553 17.2561H8.92078L9.08547 16.2136C9.09525 16.1506 9.14984 16.1042 9.21353 16.1042H9.32112C9.60204 16.1042 9.86706 16.1042 10.004 16.2643C10.0856 16.3599 10.1106 16.5018 10.0795 16.6979ZM14.5501 16.68H13.8052C13.7417 16.68 13.6869 16.7264 13.6771 16.7895L13.6441 16.9978L13.592 16.9223C13.4308 16.6882 13.0712 16.61 12.7122 16.61C11.889 16.61 11.1859 17.2335 11.049 18.1081C10.9778 18.5443 11.079 18.9615 11.3264 19.2524C11.5535 19.5199 11.8783 19.6314 12.2647 19.6314C12.928 19.6314 13.2959 19.2048 13.2959 19.2048L13.2626 19.4119C13.2501 19.4911 13.3111 19.562 13.3904 19.562H14.0615C14.1682 19.562 14.2582 19.4846 14.2751 19.3794L14.6777 16.8297C14.6904 16.7513 14.6297 16.68 14.5501 16.68ZM13.5117 18.1299C13.4398 18.5555 13.102 18.8412 12.6713 18.8412C12.455 18.8412 12.282 18.7718 12.1711 18.6403C12.061 18.5098 12.0191 18.3239 12.0541 18.1169C12.1213 17.6949 12.4647 17.4 12.889 17.4C13.1004 17.4 13.2724 17.4702 13.3857 17.6028C13.4992 17.7368 13.5442 17.9238 13.5117 18.1299ZM17.769 16.6799H18.5176C18.6224 16.6799 18.6836 16.7975 18.624 16.8835L16.1342 20.4774C16.0939 20.5356 16.0275 20.5702 15.9565 20.5702H15.2089C15.1036 20.5702 15.0421 20.4517 15.1031 20.3655L15.8783 19.2712L15.0538 16.8514C15.0253 16.7673 15.0874 16.6799 15.1769 16.6799H15.9124C16.008 16.6799 16.0923 16.7426 16.1199 16.8341L16.5575 18.2956L17.59 16.7747C17.6304 16.7154 17.6976 16.6799 17.769 16.6799Z"
      fill="#253B80"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.8855 19.4124L26.5239 15.3504C26.5337 15.2873 26.5883 15.2409 26.6518 15.2407H27.3705C27.4499 15.2407 27.5108 15.3119 27.4983 15.3906L26.8687 19.3795C26.8523 19.4847 26.7618 19.5621 26.6551 19.5621H26.0132C25.9339 19.5621 25.8729 19.4911 25.8855 19.4124ZM20.9961 15.2409H19.4402C19.334 15.2409 19.2434 15.3183 19.2268 15.4233L18.5976 19.4124C18.5851 19.4911 18.6461 19.5621 18.7255 19.5621H19.5239C19.5981 19.5621 19.6615 19.508 19.6731 19.4343L19.8516 18.3036C19.8681 18.1982 19.9588 18.1209 20.065 18.1209H20.5572C21.5822 18.1209 22.1734 17.625 22.3281 16.6424C22.3979 16.2125 22.3308 15.8747 22.1295 15.6381C21.9086 15.3784 21.5167 15.2409 20.9961 15.2409ZM21.1755 16.6979C21.0907 17.2561 20.6642 17.2561 20.2515 17.2561H20.017L20.182 16.2136C20.1918 16.1506 20.2459 16.1042 20.3098 16.1042H20.4173C20.6981 16.1042 20.9633 16.1042 21.1002 16.2643C21.1819 16.3599 21.2067 16.5018 21.1755 16.6979ZM25.6458 16.68H24.9013C24.8373 16.68 24.783 16.7264 24.7734 16.7895L24.7405 16.9978L24.6882 16.9223C24.5269 16.6882 24.1676 16.61 23.8086 16.61C22.9853 16.61 22.2825 17.2335 22.1455 18.1081C22.0746 18.5443 22.1753 18.9615 22.4228 19.2524C22.6503 19.5199 22.9747 19.6314 23.3611 19.6314C24.0244 19.6314 24.3922 19.2048 24.3922 19.2048L24.359 19.4119C24.3465 19.491 24.4075 19.562 24.4873 19.562H25.1581C25.2643 19.562 25.3548 19.4846 25.3715 19.3794L25.7743 16.8297C25.7866 16.7512 25.7257 16.68 25.6458 16.68ZM24.6075 18.1299C24.536 18.5555 24.1978 18.8411 23.7669 18.8411C23.551 18.8411 23.3778 18.7718 23.2667 18.6403C23.1566 18.5098 23.1152 18.3239 23.1499 18.1169C23.2173 17.6949 23.5604 17.4 23.9846 17.4C24.1962 17.4 24.3681 17.4702 24.4815 17.6028C24.5953 17.7368 24.6404 17.9238 24.6075 18.1299Z"
      fill="#179BD7"
    />
    <path
      d="M15.657 13.8139L15.8483 12.5991L15.4223 12.5892H13.3881L14.8017 3.6254C14.8061 3.59833 14.8204 3.5731 14.8412 3.55519C14.8621 3.53727 14.8888 3.5274 14.9166 3.5274H18.3466C19.4853 3.5274 20.2711 3.76435 20.6814 4.23205C20.8738 4.45146 20.9963 4.68074 21.0556 4.93305C21.1177 5.19781 21.1188 5.51412 21.0581 5.89991L21.0537 5.92806V6.17525L21.2461 6.28423C21.4081 6.37016 21.5367 6.46853 21.6354 6.58116C21.8001 6.76875 21.9065 7.00717 21.9515 7.28984C21.9979 7.58055 21.9825 7.92648 21.9065 8.31812C21.8187 8.76864 21.6768 9.16101 21.4852 9.48207C21.3089 9.77791 21.0844 10.0232 20.8179 10.2134C20.5633 10.3941 20.2609 10.5312 19.919 10.619C19.5877 10.7052 19.21 10.7488 18.7957 10.7488H18.5287C18.3379 10.7488 18.1524 10.8175 18.0069 10.9408C17.861 11.0666 17.7644 11.2384 17.7348 11.4264L17.7147 11.5358L17.3768 13.6767L17.3614 13.7553C17.3574 13.7803 17.3505 13.7927 17.3403 13.8011C17.3311 13.8087 17.318 13.8139 17.3051 13.8139H15.657Z"
      fill="#253B80"
    />
    <path
      d="M21.4282 5.95672C21.418 6.02217 21.4063 6.08909 21.3931 6.15783C20.9407 8.48025 19.3932 9.28255 17.4167 9.28255H16.4104C16.1687 9.28255 15.965 9.45806 15.9273 9.69648L15.4121 12.9642L15.2662 13.8904C15.2416 14.0469 15.3624 14.1881 15.5203 14.1881H17.3052C17.5166 14.1881 17.6961 14.0345 17.7293 13.8261L17.7469 13.7354L18.083 11.6027L18.1046 11.4858C18.1374 11.2766 18.3173 11.123 18.5287 11.123H18.7957C20.5249 11.123 21.8787 10.4209 22.2744 8.3892C22.4397 7.54046 22.3541 6.83178 21.9167 6.33336C21.7844 6.18306 21.6202 6.05837 21.4282 5.95672Z"
      fill="#179BD7"
    />
    <path
      d="M20.9548 5.76818C20.8858 5.74807 20.8144 5.72978 20.7412 5.71333C20.6678 5.69725 20.5924 5.68298 20.5149 5.67055C20.2436 5.62667 19.9463 5.60583 19.6278 5.60583H16.9393C16.8731 5.60583 16.8103 5.62082 16.754 5.64788C16.63 5.70748 16.5379 5.82487 16.5155 5.96857L15.9435 9.59097L15.9271 9.69665C15.9648 9.45822 16.1685 9.2827 16.4102 9.2827H17.4165C19.393 9.2827 20.9406 8.48004 21.3929 6.158C21.4064 6.08925 21.4178 6.02233 21.428 5.95688C21.3136 5.89617 21.1896 5.84425 21.0561 5.79999C21.0232 5.78903 20.9892 5.77843 20.9548 5.76818Z"
      fill="#222D65"
    />
    <path
      d="M16.5156 5.96849C16.5379 5.82477 16.6301 5.70739 16.754 5.64815C16.8107 5.62109 16.8733 5.6061 16.9394 5.6061H19.6279C19.9464 5.6061 20.2437 5.62694 20.5149 5.67082C20.5925 5.68325 20.6679 5.69752 20.7414 5.7136C20.8145 5.73007 20.8858 5.74835 20.9549 5.76846C20.9893 5.7787 21.0233 5.7893 21.0565 5.79991C21.19 5.84415 21.314 5.89645 21.4284 5.95678C21.5631 5.09854 21.4274 4.51419 20.9634 3.98505C20.4518 3.40253 19.5284 3.15314 18.3469 3.15314H14.9168C14.6755 3.15314 14.4697 3.32866 14.4323 3.56745L13.0037 12.6234C12.9755 12.8026 13.1137 12.9643 13.2943 12.9643H15.412L15.9437 9.59088L16.5156 5.96849Z"
      fill="#253B80"
    />
  </svg>
);

// import { post, put } from 'utils/http';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

const credentials = {
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

const s3Config = {
	credentials,
	region: process.env.REACT_APP_AWS_REGION,
	httpOptions: {
		timeout: 0,
	},
	correctClockSkew: true,
};

const client = new S3Client(s3Config);

export const s3Upload = async params => {
	await client.send(new PutObjectCommand(params));
	return `https://${params.Bucket}.s3.${s3Config.region}.amazonaws.com/${params.Key}`;
};

// const s3Client = async params => {
// 	const signedUrl = await post('image/get-signed-url', null, {
// 		fileType: params.ContentType,
// 		key: params.Key,
// 	});
// 	await put(signedUrl, null, params.Body);
// 	return signedUrl.split('?')[0];
// };

export const customShipping = (option, boards, option1, option2) => {
	let {boardPrice, freeShipping, maxBoards, minPrice} = option === "option1" ? option1 : option2;
	if (freeShipping) {
		return 0;
	}
	if (boards <= maxBoards) {
		return Number(minPrice);
	} else
		return Number(minPrice) + (boards - maxBoards) * Number(boardPrice);
};
export const getDueDate = shippingTime => {
	let dueDate = new Date();
	dueDate.setDate(dueDate.getDate() + Number(shippingTime));
	return dueDate;
};

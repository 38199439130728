import React from 'react';

import Spinner from '../spinner';

import './PayPalCheckoutLoader.scss';

const CLASS = 'sb-PayPalCheckoutLoader';

const PayPalCheckoutLoader = () => {
	return (
		<div className={CLASS}>
			<Spinner />
		</div>
	);
};

export default PayPalCheckoutLoader;

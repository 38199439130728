import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import BoardLoader from '../board-loader';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import './ImageCropper.scss';
import { ReactComponent as LeftIcon } from '../../../assets/images/add-image/Image_left.svg';
import { ReactComponent as RightIcon } from '../../../assets/images/add-image/Image_big.svg';
import frameClassic from '../../../assets/images/boards/frames/Default.png'; // Classic - No padding
import frameBold from '../../../assets/images/boards/frames/Bold-1.png'; // White - No padding
// import frameClean from '../../../assets/images/boards/frames/Clean-1.png'; // White - With padding
import frameEver from '../../../assets/images/boards/frames/Ever-1.png'; // Black - No padding
// import frameEdge from '../../../assets/images/boards/frames/Edge-1.png'; // Black - With padding

const CLASS = 'sb-ImageCropper';
const initialZoom = 1.036;

const initialState = {
	crop: { x: 0, y: 0 },
	zoom: initialZoom,
	cropData: {
		x: 0,
		y: 0,
		height: null,
		width: null,
		zoom: initialZoom,
	},
};

const ImageCropper = props => {
	const [state, setState] = useState(initialState);
	const [image, setImage] = useState(null);
	const boardInCropper = useSelector(state => state.boards.boardInCropper);
	const selectedFrame = useSelector(state => state.boards.selectedFrame);

	useEffect(() => {}, [state]);

	useEffect(() => {
		setImage(null);
		boardInCropper && setImage(boardInCropper.secureUrl || boardInCropper.blob );
	}, [boardInCropper]);

	useEffect(() => {
		if (boardInCropper && !!boardInCropper.cropData) {
			const { cropData } = boardInCropper;
			setState({
				crop: cropData.crop,
				zoom: cropData.zoom,
				cropData: { ...cropData },
			});
		} else setState(initialState);
	}, [boardInCropper]);

	const onCropChange = crop => {
		setState({
			...state,
			crop,
			cropData: {
				...state.cropData,
				crop,
			},
		});
	};

	const onCropComplete = async (croppedArea, croppedAreaPixels) => {
		props.setCropData({
			zoom: state.cropData.zoom,
			crop: state.crop,
			width: croppedAreaPixels?.width,
			height: croppedAreaPixels?.height,
			x: croppedAreaPixels.x,
			y: croppedAreaPixels.y,
			croppedAreaPixels,
		});
	};

	const onZoomChange = zoom => {
		setState({
			...state,
			zoom,
			cropData: {
				...state.cropData,
				zoom,
			},
		});
	};

	const renderFrame = () => {
		// if (this.props.upload) return null;

		return (
			<div className={`${CLASS}-frame ${selectedFrame === 'classic' && 'isClassic'}`}>
				<img
					src={frameClassic}
					className={`${selectedFrame === 'classic' && 'show'}`}
					alt="Frame"
				/>
				<img
					src={frameBold}
					className={`${['bold', 'clean'].includes(selectedFrame) && 'show'}`}
					alt="Frame"
				/>
				<img
					src={frameEver}
					className={`${['ever', 'edge'].includes(selectedFrame) && 'show'}`}
					alt="Frame"
				/>
			</div>
		);
	};

	const minZoom = boardInCropper?.frame === 'classic' ? 1.036 : 1.0;

	const cropperClass = classnames('image-inside-cropper', {
		crop: !!boardInCropper?.cropData,
		[`crop-${boardInCropper?.frame}`]: boardInCropper?.frame,
		'crop-img-horizontal': boardInCropper?.width > boardInCropper?.height,
		'crop-img-vertical': boardInCropper?.width <= boardInCropper?.height,
	});

	return (
		<div className={CLASS}>
			<div className={`${CLASS}-slider`}>
				<LeftIcon className={`${CLASS}-slider-lefticon`} />
				<input
					onChange={e => onZoomChange(e.target.value)}
					step="0.001"
					type="range"
					min={String(minZoom)}
					max="1.5"
					value={state.zoom}
				/>
				<RightIcon className={`${CLASS}-slider-righticon`} />
			</div>
			<div className={`${CLASS}-container ${CLASS}-container-${boardInCropper?.frame || ''}`}>
				{!image ? (
					<BoardLoader />
				) : (
					<>
						{renderFrame()}
						<Cropper
							className={`${CLASS}-cropper`}
							classes={{
								containerClassName: `container-cropper`,
								imageClassName: cropperClass,
								cropAreaClassName: 'crop-area',
							}}
							image={image}
							showGrid={props.grid}
							crop={state.crop}
							zoom={state.zoom}
							aspect={1}
							onCropChange={onCropChange}
							onCropComplete={onCropComplete}
							minZoom={minZoom}
							maxZoom={1.5}
							onZoomChange={onZoomChange}
							restrictPosition={true}
							initialCroppedAreaPixels={state.croppedAreaPixels}
							zoomSpeed={0.03}
							cropSize={{ width: 255, height: 255 }}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default ImageCropper;

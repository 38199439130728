const nth = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
};

const options = {
  weekday: 'short',
  // year: 'numeric',
  month: 'short',
  day: 'numeric'
};

const getFormated = (date, opt) => new Intl.DateTimeFormat('en', opt).format(date);

const getDate = (ahead = 0, format) => {
  const daysAhead = new Date();
  daysAhead.setDate(daysAhead.getDate() + Number(ahead));

  const date = new Date();
  const ye = getFormated(date, { year: '2-digit' });
  const mo = getFormated(date, { month: '2-digit' });
  const da = getFormated(date, { day: '2-digit' });

  if (format === 'YY/MM/DD/') {
    return `${ye}/${mo}/${da}/`;
  }

  let dateString = daysAhead.toLocaleDateString("en-US", options);
  const ordinal = nth(daysAhead.getDate());
  return dateString + ordinal;
};

export default getDate;

export function clickAndDrag(className) {
	// apply click and drag on toch events for devices that are not iOS
	const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

	if (!iOS) {
		if (document.querySelector(`.${className}`)) {
			let startX, scrollLeft;
			const slider = document.querySelector(`.${className}`);

			slider.addEventListener('touchstart', event => {
				event.stopPropagation();
				const distance = event.touches[0].clientX - slider.offsetLeft;
				startX = distance;
				scrollLeft = slider.scrollLeft;
			});

			slider.addEventListener('touchmove', event => {
				event.stopPropagation();
				const x = event.touches[0].clientX - slider.offsetLeft;
				const walk = x - startX;
				slider.scrollLeft = scrollLeft - walk;
			});
		}
	}
}

export const drag = (classname) => {
  let startX;
  let scrollLeft;
  let isDown = false;
  const slider = document.querySelector(classname);
  if(!slider) {
    return;
  }
  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', () => {
    isDown = false;
  });
  slider.addEventListener('mouseup', () => {
    isDown = false;
  });
  slider.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX);
    slider.scrollLeft = scrollLeft - walk;
  });
};

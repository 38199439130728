export const INITIALIZE_BOARDS_START = 'INITIALIZE_BOARDS_START';
export const INITIALIZE_BOARDS_ERROR = 'INITIALIZE_BOARDS_ERROR';
export const INITIALIZE_BOARDS_END = 'INITIALIZE_BOARDS_END';

export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';
export const UPLOAD_BLOB_END = 'UPLOAD_BLOB_END';
export const UPDATE_LOW_QUALITY = 'UPDATE_LOW_QUALITY';
export const UPLOAD_IMAGE_END = 'UPLOAD_IMAGE_END';

export const REMOVE_IMAGE_START = 'REMOVE_IMAGE_START';
export const REMOVE_IMAGE_ERROR = 'REMOVE_IMAGE_ERROR';
export const REMOVE_IMAGE_END = 'REMOVE_IMAGE_END';

export const INVALIDATE_BOARDS_START = 'INVALIDATE_BOARDS_START';
export const INVALIDATE_BOARDS_ERROR = 'INVALIDATE_BOARDS_ERROR';
export const INVALIDATE_BOARDS_END = 'INVALIDATE_BOARDS_END';

export const SHOW_BOARDS_MENU = 'SHOW_BOARDS_MENU';
export const HIDE_BOARDS_MENU = 'HIDE_BOARDS_MENU';

export const SHOW_UPLOAD_OPTIONS = 'SHOW_UPLOAD_OPTIONS';
export const HIDE_UPLOAD_OPTIONS = 'HIDE_UPLOAD_OPTIONS';

export const UPDATE_CROP_DATA = 'UPDATE_CROP_DATA';
export const UPDATE_WIDTH_AND_HEIGHT = 'UPDATE_WIDTH_AND_HEIGHT';

export const KEEP_LOW_QUALITY = 'KEEP_LOW_QUALITY';
export const REMOVE_LOW_QUALITY = 'REMOVE_LOW_QUALITY';

export const CLOUDINARY_URL = 'CLOUDINARY_URL';

export const LOAD_SESSION_END = 'LOAD_SESSION_END';
export const SET_BOARD_IN_CROPPER = 'SET_BOARD_IN_CROPPER';

export const FRAMES_SET = 'FRAMES_SET';

import React from 'react';
import classnames from 'classnames';
import Cropper from 'react-easy-crop';

import BoardLoader from '../board-loader';

import canvas from '../../../assets/images/canvas.png';
import metallic from '../../../assets/images/metallic.png';

const overlay = {
	canvas,
	metallic,
	'metallic-foam': metallic,
	'canvas-foam': canvas
}

const initialCropData = {
	crop: { x: 0, y: 0 },
	zoom: 1.036,
	cropData: {
		x: 0,
		y: 0,
		height: null,
		width: null,
		zoom: 1.036,
	},
};

const CLASS = 'sb-Board';

function BoardImage({ upload, board, isLoaded, image, handleImageLoaded }) {
	if (upload) return null;

	const { frame, cropData } = board;
	const { crop, croppedAreaPixels } = cropData || initialCropData;
	// const minZoom = ['classic'].includes(frame) ? 1.036 : 1.0;
	const minZoom = 1.036;
	const zoom = cropData?.zoom ?? minZoom;

	const cropperClass = classnames('image-inside-cropper', {
		crop: !!cropData,
		[`crop-${frame}`]: frame,
		'crop-img-horizontal': board?.width > board?.height,
		'crop-img-vertical': board?.width <= board?.height,
	});
	return (
		<div className={`${CLASS}-container ${CLASS}-container-${frame}`}>
			{(frame === 'metallic' || frame === 'canvas' || frame === 'canvas-foam' || frame === 'metallic-foam') && <img alt='cover' src={overlay[frame]} style={{
				objectFit: 'cover',
				width: "255px",
				height: "255px",
				position: 'relative',
				zIndex: 333333,
				}}/>}
			<div className={`${CLASS}-content ${CLASS}-content-${frame}`}>
				{isLoaded ? (
					// add old style
					// <img
					// 	style={this.state.cropStyle}
					// 	ref={ref => (this.originalImage = ref)}
					// 	className={`${CLASS}-blob`}
					// 	src={this.state.image}
					// 	alt="Board item"
					// />
					<Cropper
						className={`${CLASS}-cropper`}
						classes={{
							containerClassName: `container-cropper`,
							imageClassName: cropperClass,
							cropAreaClassName: 'crop-area',
						}}
						image={board.secureUrl || board.blob}
						showGrid={false}
						crop={crop}
						zoom={zoom}
						aspect={1}
						minZoom={minZoom}
						maxZoom={1.5}
						restrictPosition={true}
						initialCroppedAreaPixels={croppedAreaPixels}
						zoomSpeed={0.03}
						cropSize={{ width: 255, height: 255 }}
						onCropChange={() => {}}
					/>
				) : (
					<BoardLoader />
				)}
				<img hidden src={board.secureUrl || board.blob} alt="loading" onLoad={handleImageLoaded} />
			</div>
		</div>
	);
}

export default BoardImage;

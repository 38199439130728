import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { currencyFormatter } from '../upsell';
import FooterCta from './FooterCta';
import NewPricing from '../../../views/components/pricing/NewPricing';
import {
  a_checkoutClick,
  a_getStartedLoaded,
  a_letsCreate,
} from '../../../api/analytics';
import * as routes from '../../../app/routes';
import { appActions } from '../../../redux/app';
import './Footer.scss';
import { isMobile } from '../../../utils/dimensions';
import { updateApp } from '../../../redux/app/actions';

const { closeModals } = appActions;

const CLASS = 'sb-Footer';

const Footer = ({
  isHome,
  mobile,
  isGift,
  btnType,
  toggleCheckout,
  handleCheckout,
}) => {
  const [gtmFired, setGtmFired] = useState(false);
  const { app, checkout, boards } = useSelector((state) => state);
  const { coupon } = useSelector((state) => state.coupon);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isCheckoutOpen,
    addressInfoOpen,
    cardPaymentOpen,
    discount,
    totalPrice,
    originalPrice,
    saveTextPrice,
    totalWithSubscription,
  } = checkout;
  useEffect(() => {
    if (!gtmFired && app.priceOne) {
      a_getStartedLoaded();
      setGtmFired(true);
    }
  }, [app.priceOne, gtmFired]);

  function _toggleCheckout() {
    toggleCheckout(true);
    dispatch(closeModals());
    a_checkoutClick();
  }

  function _redirectToBoards() {
    a_letsCreate();
    let couponUtmParams = '';
    if (coupon && typeof coupon.utmParams === 'string') {
      couponUtmParams = coupon.utmParams;
    }
    const { utmParams } = app;
    const params = utmParams ? '?' + utmParams : '';
    history.push(`/boards${params}`);
  }

  function onCtaClick() {
    const showForm =
      app.cms.form?.enabled &&
      parseInt(localStorage.asPageViews, 10) === 1 &&
      !window.klaviyoFormSubmitted;
    if (isHome) {
      showForm ? history.push(routes.FORM) : _redirectToBoards();
    } else if (checkout.isCheckoutOpen) {
      handleCheckout();
    } else {
      _toggleCheckout();
    }
  }

  const footerClass = classnames(CLASS, {
    [`${CLASS}-hidden`]: !app.footer,
    [`${CLASS}-checkout`]: !isHome,
    [`${CLASS}-mobile`]: mobile,
    // [`${CLASS}-checkout-grey`]: isCheckoutOpen,
  });

  const currency = useSelector((state) => state.coupon.currency) || 'USD';

  const [frame] = app.cms.frames.filter(
    (frame) => frame.img === boards.selectedFrame,
  );

  if (isGift) return null;

  return (
    <div
      className={footerClass}
      style={{ visibility: isCheckoutOpen ? 'hidden' : 'visible' }}
    >
      <div
        style={{
          marginLeft: '16px',
          marginRight: '16px',
          width: isMobile ? '100%' : undefined,
          minWidth: isMobile ? undefined : '440px',
        }}
      >
        <div style={{ display: 'flex' }}>
          {!!boards?.boards?.length && (
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 600,
                color: '#1C3E64',
                fontSize: '13px',
                borderRadius: '4px',
                marginBottom: '12px',
                cursor: 'pointer',
                gap: '12px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '8px',
                  alignItems: 'center',
                  background: 'rgba(30, 62, 101, 0.06)',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  dispatch(
                    updateApp({ modals: { material: !app.modals.material } }),
                  );
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <div style={{ width: '24px' }}>
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="m20.018 23.984h-19.615c-0.22231 0-0.40246-0.1796-0.40246-0.4013v-20.654c0-0.22165 0.18016-0.40128 0.40246-0.40128h19.615c0.2224 0 0.4025 0.17963 0.4025 0.40128v20.654c0 0.2217-0.1801 0.4013-0.4025 0.4013zm-19.213-0.8025h18.81v-19.851h-18.81v19.851z"
                        fill="#284E7B"
                      />
                      <path
                        d="m4.9175 23.984c-0.20075 0-0.37449-0.1495-0.39915-0.3531-0.48799-4.0338 1.0192-6.6922 2.1197-8.6334 1.1346-2.0014 1.6274-3.0198 0.44258-4.2915-3.1087-3.3366-0.69886-7.9205-0.67414-7.9665 0.105-0.19483 0.34806-0.26901 0.54448-0.16395 0.19572 0.10438 0.26959 0.34747 0.16507 0.54299-0.08944 0.16709-2.1596 4.1283 0.55448 7.0413 1.6382 1.7581 0.72538 3.3682-0.33153 5.2324-1.1039 1.9472-2.4778 4.3704-2.0212 8.1424 0.02653 0.2201-0.13078 0.4199-0.35138 0.4466-0.01642 0.0018-0.03273 0.0027-0.04883 0.0027z"
                        fill="#284E7B"
                      />
                      <path
                        d="m8.1641 23.984c-0.13741 0-0.27077-0.0701-0.34571-0.1966-0.08478-0.1429-2.0301-3.5605 1.9422-8.6588 1.8162-2.3309 0.84203-4.1905-0.18946-6.1595-1.0208-1.9482-2.0763-3.9627-0.1953-6.2912 0.13945-0.17253 0.39289-0.19995 0.56598-0.0608 0.17301 0.13904 0.20041 0.39173 0.06101 0.56432-1.5504 1.919-0.7015 3.5397 0.2817 5.4162 1.0524 2.0088 2.2452 4.2854 0.1118 7.0235-3.6155 4.6401-1.9564 7.6347-1.8839 7.7599 0.11051 0.1912 0.0459 0.4367-0.14528 0.548-0.06392 0.0373-0.13404 0.055-0.20305 0.055z"
                        fill="#284E7B"
                      />
                      <path
                        d="m2.7056 23.984c-0.18475 0-0.35122-0.1276-0.39256-0.3149-0.05344-0.2415-1.2768-5.9649 1.4921-10.09 1.1843-1.7645 0.12731-3.3688-0.99182-5.0673-1.1681-1.773-2.4922-3.7827-0.60995-5.852 0.14934-0.16379 0.40417-0.17664 0.56849-0.02757 0.16459 0.1489 0.177 0.40266 0.02766 0.56682-1.4634 1.6089-0.46708 3.121 0.68676 4.8721 1.1572 1.7567 2.4692 3.7478 0.98802 5.9544-2.5754 3.837-1.3874 9.4154-1.3751 9.4713 0.04766 0.2165-0.08966 0.4305-0.30656 0.478-0.02931 0.0063-0.05841 0.0094-0.08703 0.0094z"
                        fill="#284E7B"
                      />
                      <path
                        d="m8.8703 24c-0.21867 0-0.39787-0.1897-0.40247-0.4085-0.0039-0.1862 0.14528-1.3474 3.4757-5.9405 3.2591-4.4949-0.0222-14.494-0.0556-14.595-0.0701-0.21019 0.0438-0.43733 0.2548-0.50741 0.2099-0.0696 0.4386 0.04357 0.5089 0.25392 0.1423 0.42602 3.4448 10.49-0.0557 15.319-3.164 4.3634-3.3192 5.4409-3.3242 5.4846 0.0047 0.2217-0.17133 0.3894-0.39348 0.3939-0.00273 2e-4 -0.0054 2e-4 -0.00791 2e-4z"
                        fill="#284E7B"
                      />
                      <path
                        d="m11.593 23.984c-0.2011 0-0.375-0.15-0.3991-0.3539-0.026-0.2176 0.128-0.4153 0.3449-0.4449 0.0451-0.0083 1.7458-0.3621 1.9464-4.4004 0.2197-4.4183-0.0946-6.3408-0.0979-6.3598-0.0369-0.2184 0.1106-0.4256 0.3293-0.4626 0.2195-0.0372 0.4267 0.1093 0.4642 0.3276 0.0138 0.0799 0.3332 2.0095 0.1084 6.5346-0.2394 4.8163-2.55 5.145-2.6482 5.1566-0.0161 0.0019-0.0321 0.0028-0.048 0.0028z"
                        fill="#284E7B"
                      />
                      <path
                        d="m17.513 23.984c-0.1649 0-0.3195-0.1019-0.3786-0.2653-0.1214-0.3357-2.9454-8.2642-0.7084-12.178 2.0632-3.6096 0.8926-8.4666 0.8805-8.5153-0.0533-0.21504 0.0781-0.43264 0.2938-0.48592 0.2154-0.05392 0.4338 0.0776 0.4874 0.29264 0.0526 0.21098 1.2627 5.2132-0.9625 9.106-2.0535 3.5927 0.738 11.43 0.7663 11.508 0.0753 0.2085-0.033 0.4384-0.2421 0.5135-0.0451 0.0163-0.0911 0.024-0.1364 0.024z"
                        fill="#284E7B"
                      />
                      <path
                        d="m23.597 21.456h-3.5797c-0.2223 0-0.4024-0.1796-0.4024-0.4013 0-0.2216 0.1801-0.4013 0.4024-0.4013h3.1773v-19.851h-18.81v2.0167c0 0.22165-0.18015 0.40128-0.40246 0.40128-0.2223 0-0.40246-0.17963-0.40246-0.40128v-2.418c0-0.22165 0.18016-0.40133 0.40246-0.40133h19.615c0.2223 0 0.4025 0.17963 0.4025 0.40133v20.654c0 0.2216-0.1802 0.4013-0.4025 0.4013z"
                        fill="#284E7B"
                      />
                      <path
                        d="m9.5573 3.2696c-0.02075 0-0.04183-0.00155-0.06307-0.00485-0.21968-0.03451-0.36961-0.24-0.33501-0.45883 0.23392-1.4802 0.80274-2.5494 0.82681-2.5942 0.10503-0.19483 0.34813-0.26848 0.54443-0.16395 0.1958 0.10443 0.2696 0.34752 0.1651 0.54283-0.0052 0.009867-0.5295 1.001-0.74115 2.3401-0.03129 0.19786-0.20251 0.33893-0.39711 0.33893z"
                        fill="#284E7B"
                      />
                      <path
                        d="m12.326 3.2412c-0.2216 0-0.4017-0.17883-0.4024-0.4-3e-3 -0.94758 0.3345-1.828 1.0321-2.6916 0.1395-0.17259 0.3926-0.2 0.5659-0.0608 0.173 0.13904 0.2005 0.39173 0.061 0.56427-0.5771 0.71445-0.8567 1.4293-0.8542 2.1856 8e-4 0.22165-0.1788 0.40176-0.4012 0.4025-4e-4 6e-5 -7e-4 6e-5 -0.0012 6e-5z"
                        fill="#284E7B"
                      />
                      <path
                        d="m5.2802 3.2412c-0.19289 0-0.3632-0.13856-0.39636-0.33435-0.17182-1.0134 0.12233-1.921 0.89912-2.7751 0.14934-0.16416 0.40417-0.17637 0.56844-0.027574 0.16459 0.14875 0.177 0.40256 0.02771 0.56677-0.60846 0.66885-0.83135 1.337-0.70164 2.102 0.03713 0.21856-0.11051 0.42576-0.32971 0.46256-0.02279 0.00395-0.04525 0.00566-0.06756 0.00566z"
                        fill="#284E7B"
                      />
                      <path
                        d="m16.531 3.2895c-0.1828 0-0.3482-0.1249-0.3917-0.31002-0.343-1.4628-0.6678-2.4417-0.6711-2.4514-0.0701-0.21019 0.0439-0.43733 0.2549-0.50736 0.2106-0.0696 0.4386 0.043573 0.5088 0.25392 0.0137 0.04112 0.3392 1.0206 0.6913 2.5221 0.0506 0.21584-0.0838 0.43168-0.3003 0.48213-0.0308 0.0072-0.0616 0.01061-0.0919 0.01061z"
                        fill="#284E7B"
                      />
                    </svg>
                  </div>
                  <span
                    style={{
                      color: '#284E7B',
                      fontWeight: 700,
                      fontSize: '12px',
                    }}
                  >
                    Style
                  </span>
                </div>
                <div
                  style={{
                    color: '#A637FC',
                    fontWeight: 500,
                  }}
                >
                  {frame?.text.split(' ')[0]}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '8px',
                  alignItems: 'center',
                  background: 'rgba(30, 62, 101, 0.06)',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  dispatch(updateApp({ modals: { size: !app.modals.size } }));
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <div style={{ width: '24px' }}>
                    <svg
                      fill="none"
                      viewBox="0 0 25 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m4 15.25c0.3797 0 0.69349 0.2822 0.74315 0.6482l0.00685 0.1018v5.25h5.25c0.3797 0 0.6935 0.2822 0.7432 0.6482l0.0068 0.1018c0 0.3797-0.2822 0.6935-0.6482 0.7432l-0.1018 0.0068h-5.5c-0.64721 0-1.1795-0.4919-1.2436-1.1222l-0.00645-0.1278v-5.5c0-0.4142 0.33579-0.75 0.75-0.75zm19 0c0.3797 0 0.6935 0.2822 0.7432 0.6482l0.0068 0.1018v5.5c0 0.6472-0.4919 1.1795-1.1222 1.2435l-0.1278 0.0065h-5.5c-0.4142 0-0.75-0.3358-0.75-0.75 0-0.3797 0.2822-0.6935 0.6482-0.7432l0.1018-0.0068h5.25v-5.25c0-0.3797 0.2822-0.6935 0.6482-0.7432l0.1018-0.0068zm-0.5-14c0.6472 0 1.1795 0.49188 1.2435 1.1222l0.0065 0.1278v5.5c0 0.41421-0.3358 0.75-0.75 0.75-0.3797 0-0.6935-0.28215-0.7432-0.64823l-0.0068-0.10177v-5.25h-5.25c-0.3797 0-0.6935-0.28215-0.7432-0.64823l-0.0068-0.10177c0-0.3797 0.2822-0.69349 0.6482-0.74315l0.1018-0.00685h5.5zm-12.5 0c0.4142 0 0.75 0.33579 0.75 0.75 0 0.3797-0.2822 0.69349-0.6482 0.74315l-0.1018 0.00685h-5.25v5.25c0 0.3797-0.28215 0.69349-0.64823 0.74315l-0.10177 0.00685c-0.3797 0-0.69349-0.28215-0.74315-0.64823l-0.00685-0.10177v-5.5c0-0.64721 0.49188-1.1795 1.1222-1.2436l0.1278-0.00645h5.5z"
                        fill="#284E7B"
                      />
                    </svg>
                  </div>
                  <span
                    style={{
                      color: '#284E7B',
                      fontWeight: 700,
                      fontSize: '12px',
                    }}
                  >
                    Size
                  </span>
                </div>
                <div
                  style={{
                    color: '#A637FC',
                    fontWeight: 500,
                  }}
                >
                  {app?.selectedSize}” x {app?.selectedSize}”
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '12px',
              alignItems: 'center',
              gap: '3px',
              paddingLeft: '1px',
            }}
          >
            <span
              style={{ color: '#284E7B', fontWeight: 400, fontSize: '14px' }}
            >
              {boards?.boards?.length} {frame?.checkoutDescription} Tiles
            </span>
            <div
              style={{
                background: '#DCF2D6',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '6px 12px',
                borderRadius: '100px',
              }}
            >
              <span
                style={{
                  fontSize: '12px',
                  color: '#477B36',
                  fontWeight: '700',
                }}
              >
                {coupon?.discount?.percent ? `${coupon?.discount?.percent}% OFF` : ''}
              </span>
            </div>
          </div> */}
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '8px',
              alignItems: 'center',
              gap: '3px',
              paddingRight: '1px',
            }}
          >
            <span
              style={{
                fontSize: '16px',
                fontWeight: '600',
                color: '#8E8E8E',
              }}
              className={
                totalPrice !== originalPrice
                  ? `sb-CheckoutInfo-checkoutInfoWrapper-checkoutInfo-fullPrice`
                  : ''
              }
            >
              {currencyFormatter(originalPrice, currency, 2)}
            </span>
            <span
              style={{
                fontSize: '16px',
                color: '#477B36',
                fontWeight: 600,
              }}
            >
              {currencyFormatter(totalPrice, currency, 2)}
            </span>
          </div> */}
        </div>
        <FooterCta btnType={btnType} onCtaClick={onCtaClick} />
      </div>
    </div>
  );
};

export default Footer;

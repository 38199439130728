import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import {appReducer} from './app';
import {boardsReducer} from './boards';
import {userReducer} from './user';
import {checkoutReducer} from './checkout';
import {couponReducer} from './coupon';

const rootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		app: appReducer,
		boards: boardsReducer,
		user: userReducer,
		checkout: checkoutReducer,
		coupon: couponReducer,
	});

export default rootReducer;

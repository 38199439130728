import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import history from '../app/history';
import reducers from './reducers';

const initStore = (initialState = {}) => {
	const middlewares = [thunk, routerMiddleware(history)];
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	return createStore(
		reducers(history),
		initialState,
		composeEnhancers(applyMiddleware(...middlewares)),
	);
};

export default initStore;

import { useEffect } from 'react';
import { isMobile } from '../../../utils/dimensions';

const useGorgiasChat = () => {
  const onWidgetClosed = () => {
    let chatButton = document.querySelector('#chat-button');
    chatButton.style.display = 'none';
  };

  const onWidgetOpened = () => {
    if (isMobile) return;
    let chatButton = document.querySelector('#chat-button');
    chatButton.style.right = '12px';
    chatButton.style.display = '';
  };

  useEffect(() => {
    window.GorgiasChat.init().then((chat) => {
      chat.on('widget:closed', onWidgetClosed);
      chat.on('widget:opened', onWidgetOpened);
    });
  }, []);
};

export default useGorgiasChat;

import React, { useEffect, useState } from 'react';
import { Elements } from 'react-stripe-elements';

import StripeMobileForm from '../stripe-mobile-form';
import './StripeMobile.scss';

const CLASS = 'sb-StripeMobile';

const StripeMobile = ({ handleUpdateEmail }) => {
  const [elementFontSize, setElementFontSize] = useState(
    document.documentElement?.clientWidth < 450 ? '14px' : '18px',
  );

  useEffect(() => {
    const onResize = () => {
      if (
        document.documentElement?.clientWidth < 450 &&
        elementFontSize !== '14px'
      ) {
        setElementFontSize('14px');
      } else if (
        document.documentElement?.clientWidth >= 450 &&
        elementFontSize !== '18px'
      ) {
        setElementFontSize('18px');
      }
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div className={CLASS}>
      <Elements>
        <StripeMobileForm
          fontSize={elementFontSize}
          handleUpdateEmail={handleUpdateEmail}
        />
      </Elements>
    </div>
  );
};

export default StripeMobile;

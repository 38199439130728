export const HOME = '/';
export const BOARDS = '/boards';
export const FAQ = '/faq';
export const PRIVACY_POLICY = '/privacy';
export const BOARDS_WITH_COUPON = '/boards/:couponName';
export const HOME_WITH_COUPON = '/:couponName';
export const GIFT = '/gift';
export const FORM = '/form';
export const PORTAL = '/portal';

// SOCIALS

export const INSTAGRAM = 'https://www.instagram.com/storyboardsphotos/';
export const MESSENGER = 'https://www.messenger.com/t/storyboardsbot';
export const FACEBOOK = 'https://www.facebook.com/storyboardsbot/';

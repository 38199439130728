import { nanoid } from 'nanoid';
import * as TYPES from './action-types';
import detectPlatform from '../../utils/detectPlatform';

const getToken = () => {
  let token = localStorage.getItem('remarketing_token');
  if (!token) {
    token = nanoid(12);
    localStorage.setItem('remarketing_token', token);
  }
  return token;
};

const INITIAL_STATE = {
  footer: true,
  headerNavigation: true,
  showHeader: true,
  initalizing: false,
  initialized: false,
  error: false,
  cms: {},
  priceOne: 0,
  priceThree: null,
  smallAmount: null,
  minBoards: 2,
  appVersion: null,
  originalPriceOne: null,
  originalMinBoards: null,
  platform: detectPlatform(),
  token: getToken(),
  remarketingUrl: '',
  subscriptionEnabled: false,
  subscriptions: null,
  remarketingId: new URLSearchParams(window.location.search).get('session_id'),
  modals: {
    upsell: false,
    upsellPopup: false,
    addCoupon: false,
    addVIPCoupon: false,
    gift: false,
    faq: false,
    help: false,
    cropper: false,
    material: false,
    size: false,
  },
  selectedSize: localStorage.getItem('selectedSize') || '8',
};

export const updateApp = (state, payload) => ({
  ...state,
  ...payload,
});

const setFooter = (state, visible) => {
  return {
    ...state,
    footer: visible,
  };
};

// ----- INITALIZE -----

const initializeStart = (state) => {
  return {
    ...state,
    initalizing: true,
    initialized: false,
    error: null,
  };
};

const initializeError = (state, error) => {
  return {
    ...state,
    initalizing: false,
    initialized: false,
    error,
  };
};

const initializeEnd = (state, remarketingUrl) => {
  return {
    ...state,
    initalizing: false,
    initialized: true,
    error: null,
    remarketingUrl,
  };
};

export const sizeSet = (state, size) => {
  localStorage.setItem('selectedSize', size);
  return {
    ...state,
    selectedSize: size,
  };
};

// --- GET VERSION NUMBER ---
const getInfoEnd = (
  state,
  { cms, info, id, price, remarketingUrl, subscriptions },
) => {
  const newCms = {
    emailPopup: {
      enabled: cms.emailPopup.enabled,
      triggerNumber: cms.emailPopup.triggerNumber,
    },
    frames: cms.materials.map((field) => {
      return {
        img: field.code,
        text: field.description,
        isShown: field.isActive,
        isSelected: field.isDefault,
        checkoutDescription: field.checkoutDescription,
        extraPrice: field[`price-${state.selectedSize}`],
        text2: field.description2,
        text3: field.description3,
        sizes: {
          8: {
            price: field['price-8'],
            isActive: field['price-8-isActive'],
          },
          12: {
            price: field['price-12'],
            isActive: field['price-12-isActive'],
          },
          16: {
            price: field['price-16'],
            isActive: field['price-16-isActive'],
          },
        },
      };
    }),
    shipping: {
      option1: {
        enabled: cms.shipping[0].isActive,
        defaultOption: cms.shipping[0].isDefault,
        text: cms.shipping[0].text,
        days: cms.shipping[0].days,
        minPrice: cms.shipping[0].minimumPrice,
        freeShipping: cms.shipping[0].freeShipping,
        maxBoards: cms.shipping[0].maximumBoards,
        boardPrice: cms.shipping[0].boardPrice,
      },
      option2: {
        enabled: cms.shipping[1].isActive,
        defaultOption: cms.shipping[1].isDefault,
        text: cms.shipping[1].text,
        days: cms.shipping[1].days,
        minPrice: cms.shipping[1].minimumPrice,
        freeShipping: cms.shipping[1].freeShipping,
        maxBoards: cms.shipping[1].maximumBoards,
        boardPrice: cms.shipping[1].boardPrice,
      },
    },
    upsell: {
      title: cms.upsells[0].title,
      minPrice: cms.upsells[0].minimumPrice,
      maxBoards: cms.upsells[0].maximumBoards,
      boardPrice: cms.upsells[0].boardPrice,
      popupTitle: cms.upsells[0].popupTitle,
      popupDescription: cms.upsells[0].popupDescription,
      titleImage: cms.upsells[0].imageTitle,
      descriptionImage: cms.upsells[0].imageDescription,
    },
  };
  let minBoards;
  let originalMinBoards;
  switch (+state.selectedSize) {
    case 8:
      minBoards = 3;
      originalMinBoards = 3;
      break;
    case 12:
    case 16:
      minBoards = 1;
      originalMinBoards = 1;
      break;
    default:
      minBoards = 3;
      originalMinBoards = 3;
  }
  return {
    ...state,
    cms: newCms,
    remarketingUrl,
    appVersion: info,
    priceOne: 0,
    priceThree: 0,
    smallAmount: 0,
    minBoards,
    remarketingId: id,
    originalPriceOne: price.originalPriceOne,
    originalMinBoards: originalMinBoards,
    subscriptions,
    subscriptionEnabled: price.subscriptionEnabled,
  };
};
const getInfoError = (state, error) => {
  return {
    ...state,
    error,
  };
};

// --- TOGGLE MODAL ---

const toggleModal = (state, { modal, toggle }) => ({
  ...state,
  modals: {
    ...state.modals,
    [modal]: toggle === undefined ? !state.modals[modal] : toggle,
  },
});

// ----- APP REDUCER -----
export const appReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.INITIALIZE_APP_START:
      return initializeStart(state);
    case TYPES.INITIALIZE_APP_ERROR:
      return initializeError(state, payload);
    case TYPES.INITIALIZE_APP_END:
      return initializeEnd(state, payload);
    case TYPES.UPDATE_APP:
      return updateApp(state, payload);

    case TYPES.SIZE_SET:
      return sizeSet(state, payload);

    case TYPES.SET_FOOTER_VISIBILITY:
      return setFooter(state, payload);
    case TYPES.GET_INFO_END:
      return getInfoEnd(state, payload);
    case TYPES.GET_INFO_ERROR:
      return getInfoError(state, payload);
    case TYPES.TOGGLE_MODAL:
      return toggleModal(state, payload);
    case TYPES.SAVE_SESSION_END:
    default:
      return state;
  }
};

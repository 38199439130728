import { customAlphabet } from 'nanoid';

import * as TYPES from './action-types';

const numberRegex = /^(\w{4})(\w{4})$/g;
const stringRegex = /^(\w{4})(\w{4})(\w{4})$/g;

const newNumber = () =>
  customAlphabet('1234567890', 8)().replace(numberRegex, '$1-$2');
const newHexadecimal = () =>
  customAlphabet('1234567890abcdef', 12)().replace(stringRegex, '$1-$2-$3');

const INITIAL_STATE = {
  checkingout: false,
  checkedout: false,
  error: true,
  addressError: JSON.parse(localStorage.getItem('shippingInfoError')),
  stripe: null,
  cardBrand: null,
  last4: null,
  paypalActive: false,
  addressInfoOpen: false,
  cardPaymentOpen: false,
  paypalCheckout: false,
  applePayCheckout: false,
  applePayActive: !!window.ApplePaySession,
  applePaySelected: false,
  totalPrice: 0,
  discount: null,
  isCheckoutOpen: false,
  isLoadingToken: false,
  shippingOption: null,
  shippingCost: 0,
  upsell: false,
  orderId: newHexadecimal(),
  orderNo: '',
  klarnaActive: false,
  klarnaLoading: false,
  oneTimeOrSubscription: 'one-time',
  saveTextPrice: 0,
  totalWithSubscription: 0,
  orderNumber: newNumber(),
  tax: null,
  gift: false,
  paypalApproving: false,
  paymentError: null,
  page: 1,
};

const updateOrderId = (state) => {
  return {
    ...state,
    orderId: newHexadecimal(),
  };
};

const checkoutStart = (state) => {
  return {
    ...state,
    checkingout: true,
    checkedout: false,
    error: null,
  };
};

const checkoutError = (state, error) => {
  return {
    ...state,
    checkingout: false,
    checkedout: false,
    isCheckoutOpen: true,
    error,
  };
};

const checkoutEnd = (state, payload) => {
  return {
    ...state,
    orderNo: payload.orderId,
    checkingout: false,
    checkedout: true,
    error: null,
    stripe: null,
    cardBrand: null,
    last4: null,
  };
};

const updateStripe = (state, { stripe, cardBrand, last4 }) => {
  return {
    ...state,
    stripe,
    cardBrand,
    last4,
    error: !stripe,
    applePayActive: false,
    paypalActive: false,
    ready: stripe && !state.addressError,
  };
};

const simulateCheckout = (state) => {
  return {
    ...state,
    checkingout: true,
    checkedout: false,
    error: null,
    isCheckoutOpen: false,
  };
};

const showPaymentMenu = (state) => {
  return {
    ...state,
    cardPaymentOpen: true,
  };
};

const klarnaLoadingStart = (state) => {
  return {
    ...state,
    klarnaLoading: true,
  };
};

const klarnaLoadingStop = (state) => {
  return {
    ...state,
    klarnaLoading: false,
  };
};

const updateCheckout = (state, payload) => {
  // window.Klarna.OnsiteMessaging.refresh();
  return {
    ...state,
    ...payload,
    orderPrice:
      (payload.totalPrice ?? state.totalPrice) +
      (payload.shippingCost ?? state.shippingCost),
    ready: !state.error && !(payload.addressError ?? state.addressError),
  };
};

export const checkoutReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    // ------ CHECKOUT -----
    case TYPES.CHECKOUT_START:
      return checkoutStart(state);
    case TYPES.CHECKOUT_END:
      return checkoutEnd(state, payload);
    case TYPES.CHECKOUT_ERROR:
      return checkoutError(state, payload);

    case TYPES.UPDATE_ORDER_ID:
      return updateOrderId(state);

    // ----- UPDATE STRIPE ------
    case TYPES.UPDATE_STRIPE_INFO:
      return updateStripe(state, payload);

    case TYPES.KLARNA_LOADING_START:
      return klarnaLoadingStart(state);

    case TYPES.KLARNA_LOADING_STOP:
      return klarnaLoadingStop(state);

    // ----- SIMULATE CHECKOUT -----
    case TYPES.CHECKOUT_SIMULATION:
      return simulateCheckout(state);

    // ----- SHOW PAYMENT MENU -----
    case TYPES.SHOW_PAYMENT_MENU:
      return showPaymentMenu(state);

    // ----- ACTIVATE PAYPAL -----
    case TYPES.ACTIVATE_PAYPAL:
      return {
        ...state,
        paypalActive: true,
        cardPaymentOpen: false,
        applePayActive: false,
        applePaySelected: false,
        klarnaActive: false,
        stripe: null,
      };
    case TYPES.OPEN_CARD_FORM:
      return {
        ...state,
        cardPaymentOpen: true,
        paypalActive: false,
        applePayActive: false,
        applePaySelected: false,
        klarnaActive: false,
      };

    // ----- SHOW CARD INPUT -----
    case TYPES.SHOW_CARD_INPUT:
      return { ...state, displayCardInput: true };

    // ----- PAYPAL CHECKOUT -----
    case TYPES.PAYPAL_CHECKOUT:
      return { ...state, paypalCheckout: true };
    case TYPES.PAYPAL_CHECKOUT_CANCEL:
      return { ...state, paypalCheckout: false };
    case TYPES.UPDATE_PAYPAL_DATA:
      return { ...state, ...payload };
    // ----- APPLEPAY CHECKOUT ----
    case TYPES.APPLEPAY_CHECKOUT:
      return { ...state, applePayCheckout: true };
    // ----- ACTIVATE PAYPAL -----
    case TYPES.ACTIVATE_APPLEPAY:
      return {
        ...state,
        applePayActive: true,
        paypalActive: false,
        cardPaymentOpen: false,
        applePaySelected: true,
        klarnaActive: false,
        stripe: null,
        oneTimeOrSubscription: 'one-time',
      };

    case TYPES.ACTIVATE_KLARNA:
      return {
        ...state,
        paypalActive: false,
        cardPaymentOpen: false,
        applePayActive: false,
        applePaySelected: false,
        klarnaActive: true,
        stripe: null,
        oneTimeOrSubscription: 'one-time',
      };

    case TYPES.UPDATEID:
      return {
        ...state,
        orderNumber: newNumber(),
        orderId: newHexadecimal(),
      };

    // ----- INIT -----
    case TYPES.CHECKOUT_INIT:
      return {
        ...INITIAL_STATE,
        orderId: newHexadecimal(),
        orderNumber: newNumber(),
      };

    case TYPES.SET_ORIGINAL_PRICE:
      return {
        ...state,
        originalPrice: payload,
      };
    case TYPES.UPDATE_CHECKOUT:
      return updateCheckout(state, payload);
    default:
      return state;
  }
};

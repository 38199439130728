import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';

import { guranteeImages, checkoutImages } from '../../../lib/images';
import { ReactComponent as CouponIcon } from '../../../assets/images/header-footer/Coupon_Icon_white.svg';
import smileWithHand from '../../../assets/images/smileWithHand.png';

import { validateEmail } from './utils';
import { openGorgiasChat } from '../../../utils/features/gorgiasChat';
import { getVoucherify } from '../../../redux/coupon/actions';
import { updateUserEmail } from '../../../redux/user/actions';
import { toggleModal } from '../../../redux/app/actions';
import './AddCouponModal.scss';
import { isMobile } from 'react-device-detect';
import { Spinner } from './Spinner';
import { currencyFormatter } from '../upsell';
import history from '../../../app/history';

const AddCouponModal = () => {
  const [inputCode, setInputCode] = useState('');
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [showMessage, setShowMessage] = useState(false);

  const { email } = useSelector((state) => state.user);

  const [inputEmail, setInputEmail] = useState(email);

  const dispatch = useDispatch();
  const coupon = useSelector((state) => state.coupon);

  const handleRetry = () => {
    setInputCode('');
    setInputEmail('');
    setError(null);
    setShowMessage(false);
    setStep(1);
  };

  const clearModal = () => {
    dispatch(toggleModal({ modal: 'addCoupon' }));
    setShowMessage(false);
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setInputEmail(value);
    if (validateEmail(value)) {
      setError(null);
    } else {
      setError('Enter valid email');
    }
  };
  const hadleInputChange = (e) => {
    setShowMessage(false);
    setInputCode(e.target.value);
  };
  let messageText =
    coupon && coupon.voucherifyError && showMessage ? (
      <>
        {/* <img src={smileWithHand} alt="oops" /> */}
        <svg
          style={{ color: 'rgb(40, 78, 123)' }}
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-mood-sad"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
          <path d="M9 10l.01 0" />
          <path d="M15 10l.01 0" />
          <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" />
        </svg>
        <div style={{ background: '#fff', border: 'none' }}>
          <p style={{ fontSize: '18px', fontWeight: 700 }}>Code Expired</p>
          <span
            style={{
              color: '#000',
              width: 'unset',
              fontWeight: 500,
              marginTop: '8px',
              fontSize: '14px',
            }}
          >
            Sorry, looks like this code already expired.
          </span>
        </div>
      </>
    ) : coupon && coupon.coupon && showMessage ? (
      <>
        {/* <img src={guranteeImages.smileyFace} alt="smiley" /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-mood-smile-beam"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z" />
          <path d="M10 10c-.5 -1 -2.5 -1 -3 0" />
          <path d="M17 10c-.5 -1 -2.5 -1 -3 0" />
          <path d="M14.5 15a3.5 3.5 0 0 1 -5 0" />
        </svg>
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontSize: '18px', fontWeight: 700 }}>Congratulations!</p>
          <div
            style={{
              gap: '8px',
              color: '#000',
              width: 'unset',
              display: 'flex',
              marginTop: '8px',
              fontSize: '14px',
              flexDirection: 'column',
            }}
          >
            <div>Your coupon has been activated.</div>
            <hr
              style={{
                width: '100%',
                backgroundColor: 'rgba(40, 78, 123, .3)',
                height: '1px',
                border: 'none',
              }}
            />
            <span style={{ color: '#000', fontWeight: 500 }}>
              Your discount is activated and will appear at checkout.
            </span>
          </div>
        </div>
      </>
    ) : (
      ''
    );
  let activateButton =
    !showMessage && inputCode ? (
      <>
        <button
          style={{
            width: 'unset',
            height: 'unset',
            borderRadius: '4px',
            padding: '8px 12px',
            fontSize: '18px',
            color: 'rgb(40, 78, 123)',
            boxShadow: 'inset 0px 0px 0px 1px rgba(40, 78, 123, .2)',
          }}
          className={'popup-body-button popup-body-button-back'}
          onClick={() => {
            setStep(1);
            setError(null);
            setInputEmail('');
          }}
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#2c3e50"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            Back
          </span>
        </button>
        <button
          style={{
            width: 'unset',
            height: 'unset',
            borderRadius: '4px',
            padding: '8px 12px',
            fontSize: '18px',
          }}
          className={'popup-body-button'}
          disabled={inputEmail === '' || error}
          onClick={() => {
            if (!error && inputEmail) {
              setShowMessage(true);
              dispatch(getVoucherify(inputCode, true));
              localStorage.setItem('email', JSON.stringify(inputEmail));
              dispatch(updateUserEmail(inputEmail));
              setStep(3);
            } else {
              return;
            }
          }}
        >
          Activate
        </button>
      </>
    ) : (
      ''
    );
  let nextButton = (
    <button
      className={'popup-body-button'}
      disabled={inputCode === ''}
      onClick={() => {
        setStep(2);
      }}
      style={{
        width: 'unset',
        height: 'unset',
        borderRadius: '4px',
        padding: '8px 12px',
        fontSize: '18px',
      }}
    >
      Next
    </button>
  );
  let input =
    step === 1 ? (
      <input
        style={{
          border: '1px solid rgba(40, 78, 123, .3)',
          borderRadius: '4px',
          fontSize: '16px',
          color: 'rgb(40, 78, 123)',
        }}
        value={inputCode}
        onChange={(e) => hadleInputChange(e)}
        type="text"
        placeholder="Code"
      />
    ) : (
      <input
        style={{
          border: '1px solid rgba(40, 78, 123, .3)',
          borderRadius: '4px',
          fontSize: '16px',
          color: 'rgb(40, 78, 123)',
        }}
        value={inputEmail}
        onChange={(e) => handleEmailChange(e)}
        type="text"
        placeholder="Email"
      />
    );
  let btn = step === 1 ? nextButton : activateButton;
  return (
    <>
      <div
        className={classnames('popup', { 'popup-final': step === 3 })}
        style={{
          height: 'unset',
          right: !isMobile ? '24px' : '0px',
          top: !isMobile ? '74px' : '0px',
        }}
      >
        <div
          className={`popup-header`}
          style={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottom: '1px solid rgba(40, 78, 123, 0.3)',
          }}
        >
          <span onClick={() => clearModal()} style={{ flexBasis: '25%' }}>
            <img src={checkoutImages.closeBlueNew} alt="close" />
          </span>
          <p
            style={{
              color: 'rgb(40, 78, 123)',
              margin: '0 auto',
            }}
          >
            Redeem Coupon
          </p>
          <span
            style={{
              visibility: step === 3 ? 'visible' : 'hidden',
              flexBasis: '25%',
              textAlign: 'right',
            }}
            onClick={() => clearModal()}
          >
            Done
          </span>
        </div>
        <div
          className={`popup-body`}
          style={{
            background: '#fff',
            padding: '24px',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            margin: 0,
          }}
        >
          <div className={`popup-body-form`} style={{ alignItems: 'unset' }}>
            {step !== 3 && (
              <div
                style={{
                  textTransform: 'none',
                  color: 'rgb(40, 78, 123)',
                  fontSize: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '500',
                  }}
                >
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6333 8.25746L7.55444 1.1895C7.26287 0.912389 6.91964 0.695358 6.54428 0.550768C6.17921 0.388438 5.78587 0.299211 5.38649 0.288128H1.26662C1.10038 0.286749 0.935625 0.319404 0.782481 0.384082C0.629338 0.448761 0.491051 0.544094 0.37613 0.664216C0.256008 0.779137 0.160675 0.917424 0.0959964 1.07057C0.0313181 1.22371 -0.00133658 1.38847 4.18914e-05 1.5547V5.67302C0.0110848 6.07241 0.100314 6.46576 0.262682 6.83081C0.405632 7.20423 0.622934 7.54472 0.90141 7.83164L7.98025 14.9214C8.09575 15.0401 8.23435 15.1339 8.38751 15.197C8.54067 15.2601 8.70513 15.2911 8.87074 15.2881C9.03774 15.2894 9.20333 15.2576 9.35801 15.1946C9.51269 15.1317 9.65342 15.0388 9.77211 14.9214L14.6333 10.0509C14.752 9.93538 14.8458 9.79677 14.9089 9.64361C14.972 9.49046 15.003 9.326 15 9.16038C15.0015 8.99313 14.9698 8.82725 14.9069 8.67228C14.8439 8.51732 14.7509 8.37634 14.6333 8.25746ZM4.06552 4.35205C3.94937 4.47195 3.80987 4.56677 3.65564 4.63065C3.50141 4.69453 3.33573 4.72613 3.16882 4.72348C2.83302 4.72307 2.5111 4.58949 2.27366 4.35205C2.03622 4.11461 1.90265 3.79269 1.90224 3.4569C1.90265 3.12111 2.03622 2.79919 2.27366 2.56175C2.5111 2.32431 2.83302 2.19074 3.16882 2.19032C3.50461 2.19074 3.82653 2.32431 4.06397 2.56175C4.30141 2.79919 4.43498 3.12111 4.43539 3.4569C4.43783 3.62355 4.40613 3.78894 4.34225 3.94289C4.27838 4.09683 4.18367 4.23608 4.06397 4.35205H4.06552Z"
                      fill="#BF3EFB"
                    />
                  </svg>
                  {step === 1 ? 'Redeem Coupon' : 'Enter your Email'}
                </div>
                <span
                  style={{
                    fontSize: '14px',
                    color: 'rgb(40, 78, 123)',
                    fontWeight: 500,
                    width: 'unset',
                  }}
                >
                  Step {step < 3 ? step : 2}/2
                </span>
              </div>
            )}
            {step !== 3 && input}
            {error && (
              <p
                style={{ fontSize: '14px', color: 'red', margin: '8px 0 0 0' }}
              >
                {error}
              </p>
            )}
          </div>
          {!coupon.loading && messageText && (
            <div
              className={`popup-body-message`}
              style={{ margin: '0 auto', width: '100%' }}
            >
              {!coupon.loading && messageText}
            </div>
          )}
          <div
            className={'popup-body-buttons'}
            style={{
              marginTop:
                (step !== 3 || (step === 3 && coupon.voucherifyError)) &&
                '24px',
            }}
          >
            {btn}
            {step === 3 && coupon.voucherifyError && (
              <button
                style={{
                  width: 'unset',
                  height: 'unset',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  fontSize: '18px',
                  color: 'rgb(40, 78, 123)',
                  boxShadow: 'inset 0px 0px 0px 1px rgba(40, 78, 123, .2)',
                }}
                className={'popup-body-button popup-body-button-back'}
                onClick={handleRetry}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-chevron-left"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#2c3e50"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M15 6l-6 6l6 6" />
                  </svg>
                  Try Again
                </span>
              </button>
            )}
            {step === 3 && coupon.voucherifyError && (
              <button
                style={{
                  width: 'unset',
                  height: 'unset',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  fontSize: '18px',
                }}
                className={'popup-body-button'}
                onClick={() => openGorgiasChat()}
              >
                Contact Support
              </button>
            )}
          </div>
          <div style={{ margin: '0 auto', color: 'rgb(40, 78, 123)' }}>
            {coupon.loading && <Spinner />}
          </div>
        </div>
      </div>
      <div id={`sb-CheckoutInfo-overlay2`} onClick={clearModal} />
    </>
  );
};

export const AddVIPCouponModal = () => {
  const [inputCode, setInputCode] = useState('');
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [showMessage, setShowMessage] = useState(false);

  const { email } = useSelector((state) => state.user);

  const [inputEmail, setInputEmail] = useState(email);

  const dispatch = useDispatch();
  const coupon = useSelector((state) => state.coupon);

  const handleRetry = () => {
    setInputCode('');
    setInputEmail('');
    setError(null);
    setShowMessage(false);
    setStep(1);
  };

  const clearModal = () => {
    dispatch(toggleModal({ modal: 'addVIPCoupon' }));
    setShowMessage(false);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setInputEmail(value);
    if (validateEmail(value)) {
      setError(null);
    } else {
      setError('Enter valid email');
    }
  };

  const hadleInputChange = (e) => {
    setShowMessage(false);
    setInputCode(e.target.value);
  };

  let messageText =
    coupon && coupon.voucherifyError && showMessage ? (
      <>
        {/* <img src={smileWithHand} alt="oops" /> */}
        <svg
          style={{ color: 'rgb(40, 78, 123)' }}
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-mood-sad"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
          <path d="M9 10l.01 0" />
          <path d="M15 10l.01 0" />
          <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" />
        </svg>
        <div style={{ background: '#fff', border: 'none' }}>
          <p style={{ fontSize: '18px', fontWeight: 700 }}>
            Expired Code Alert
          </p>
          <span
            style={{
              color: '#000',
              width: 'unset',
              fontWeight: 500,
              marginTop: '8px',
              fontSize: '14px',
            }}
          >
            We're sorry, there seems to be an issue with your VIP code. For
            assistance, please reach out to us at&nbsp;
            <a
              style={{ color: 'rgb(40, 78, 123)' }}
              href="mailto:hi@storyboards.io"
            >
              hi@storyoards.io
            </a>
            .
          </span>
        </div>
      </>
    ) : coupon && coupon.coupon && showMessage ? (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-mood-smile-beam"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z" />
          <path d="M10 10c-.5 -1 -2.5 -1 -3 0" />
          <path d="M17 10c-.5 -1 -2.5 -1 -3 0" />
          <path d="M14.5 15a3.5 3.5 0 0 1 -5 0" />
        </svg>
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontSize: '18px', fontWeight: 700 }}>Congratulations!</p>
          <div
            style={{
              gap: '8px',
              color: '#000',
              width: 'unset',
              display: 'flex',
              marginTop: '8px',
              fontSize: '14px',
              flexDirection: 'column',
            }}
          >
            <div>Your VIP credit has been activated.</div>
            <div>
              Available VIP Balance:&nbsp;
              <span style={{ color: '#bf3efb', fontWeight: 600 }}>
                {currencyFormatter(coupon?.discount?.amount / 100, 'USD', 2)}
              </span>
            </div>
            <hr
              style={{
                width: '100%',
                backgroundColor: 'rgba(40, 78, 123, .3)',
                height: '1px',
                border: 'none',
              }}
            />
            <span style={{ color: '#000', fontWeight: 500 }}>
              Note: Your VIP story credit will be available for use and applied
              at checkout.
            </span>
          </div>
        </div>
      </>
    ) : (
      ''
    );

  let activateButton =
    !showMessage && inputCode ? (
      <>
        <button
          style={{
            width: 'unset',
            height: 'unset',
            borderRadius: '4px',
            padding: '8px 12px',
            fontSize: '18px',
            color: 'rgb(40, 78, 123)',
            boxShadow: 'inset 0px 0px 0px 1px rgba(40, 78, 123, .2)',
          }}
          className={'popup-body-button popup-body-button-back'}
          onClick={() => {
            setStep(1);
            setError(null);
            setInputEmail('');
          }}
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#2c3e50"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            Back
          </span>
        </button>
        <button
          style={{
            width: 'unset',
            height: 'unset',
            borderRadius: '4px',
            padding: '8px 12px',
            fontSize: '18px',
          }}
          className={'popup-body-button'}
          disabled={inputEmail === '' || error}
          onClick={() => {
            if (!error && inputEmail) {
              setShowMessage(true);
              dispatch(getVoucherify(inputCode, true));
              localStorage.setItem('email', JSON.stringify(inputEmail));
              dispatch(updateUserEmail(inputEmail));
              setStep(3);
            }
          }}
        >
          Activate
        </button>
      </>
    ) : (
      ''
    );
  let nextButton = (
    <button
      className={'popup-body-button'}
      disabled={inputCode === ''}
      onClick={() => {
        setStep(2);
      }}
      style={{
        width: 'unset',
        height: 'unset',
        borderRadius: '4px',
        padding: '8px 12px',
        fontSize: '18px',
      }}
    >
      Next
    </button>
  );
  let input =
    step === 1 ? (
      <input
        style={{
          border: '1px solid rgba(40, 78, 123, .3)',
          borderRadius: '4px',
          fontSize: '16px',
          color: 'rgb(40, 78, 123)',
        }}
        value={inputCode}
        onChange={(e) => hadleInputChange(e)}
        type="text"
        placeholder="Code"
      />
    ) : (
      <input
        style={{
          border: '1px solid rgba(40, 78, 123, .3)',
          borderRadius: '4px',
          fontSize: '16px',
          color: 'rgb(40, 78, 123)',
        }}
        value={inputEmail}
        onChange={(e) => handleEmailChange(e)}
        type="text"
        placeholder="Email"
      />
    );
  let btn = step === 1 ? nextButton : activateButton;
  return (
    <>
      <div
        className={classnames('popup', { 'popup-final': step === 3 })}
        style={{
          height: 'unset',
          right: !isMobile ? '24px' : '0px',
          top: !isMobile ? '74px' : '0px',
        }}
      >
        <div
          className={`popup-header`}
          style={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottom: '1px solid rgba(40, 78, 123, .3)',
          }}
        >
          <span onClick={() => clearModal()} style={{ flexBasis: '25%' }}>
            <img src={checkoutImages.closeBlueNew} alt="close" />
          </span>
          <p style={{ color: 'rgb(40, 78, 123)', margin: '0 auto' }}>
            Apply VIP Credit
          </p>
          <span
            style={{
              visibility: step === 3 ? 'visible' : 'hidden',
              flexBasis: '25%',
              textAlign: 'right',
            }}
            onClick={() => clearModal()}
          >
            Done
          </span>
        </div>
        <div
          className={`popup-body`}
          style={{
            background: '#fff',
            padding: '24px',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            margin: 0,
          }}
        >
          <div className={`popup-body-form`} style={{ alignItems: 'unset' }}>
            {step !== 3 && (
              <div
                style={{
                  textTransform: 'none',
                  color: 'rgb(40, 78, 123)',
                  fontSize: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '500',
                  }}
                >
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6333 8.25746L7.55444 1.1895C7.26287 0.912389 6.91964 0.695358 6.54428 0.550768C6.17921 0.388438 5.78587 0.299211 5.38649 0.288128H1.26662C1.10038 0.286749 0.935625 0.319404 0.782481 0.384082C0.629338 0.448761 0.491051 0.544094 0.37613 0.664216C0.256008 0.779137 0.160675 0.917424 0.0959964 1.07057C0.0313181 1.22371 -0.00133658 1.38847 4.18914e-05 1.5547V5.67302C0.0110848 6.07241 0.100314 6.46576 0.262682 6.83081C0.405632 7.20423 0.622934 7.54472 0.90141 7.83164L7.98025 14.9214C8.09575 15.0401 8.23435 15.1339 8.38751 15.197C8.54067 15.2601 8.70513 15.2911 8.87074 15.2881C9.03774 15.2894 9.20333 15.2576 9.35801 15.1946C9.51269 15.1317 9.65342 15.0388 9.77211 14.9214L14.6333 10.0509C14.752 9.93538 14.8458 9.79677 14.9089 9.64361C14.972 9.49046 15.003 9.326 15 9.16038C15.0015 8.99313 14.9698 8.82725 14.9069 8.67228C14.8439 8.51732 14.7509 8.37634 14.6333 8.25746ZM4.06552 4.35205C3.94937 4.47195 3.80987 4.56677 3.65564 4.63065C3.50141 4.69453 3.33573 4.72613 3.16882 4.72348C2.83302 4.72307 2.5111 4.58949 2.27366 4.35205C2.03622 4.11461 1.90265 3.79269 1.90224 3.4569C1.90265 3.12111 2.03622 2.79919 2.27366 2.56175C2.5111 2.32431 2.83302 2.19074 3.16882 2.19032C3.50461 2.19074 3.82653 2.32431 4.06397 2.56175C4.30141 2.79919 4.43498 3.12111 4.43539 3.4569C4.43783 3.62355 4.40613 3.78894 4.34225 3.94289C4.27838 4.09683 4.18367 4.23608 4.06397 4.35205H4.06552Z"
                      fill="#BF3EFB"
                    />
                  </svg>
                  {step === 1 ? 'Activate VIP Credit' : 'Enter your Email'}
                </div>
                <span
                  style={{
                    fontSize: '14px',
                    color: 'rgb(40, 78, 123)',
                    fontWeight: 500,
                    width: 'unset',
                  }}
                >
                  Step {step < 3 ? step : 2}/2
                </span>
              </div>
            )}
            {step !== 3 && input}
            {error && (
              <p
                style={{ fontSize: '14px', color: 'red', margin: '8px 0 0 0' }}
              >
                {error}
              </p>
            )}
          </div>
          {!coupon.loading && messageText && (
            <div
              className={`popup-body-message`}
              style={{ margin: '0 auto', width: '100%' }}
            >
              {!coupon.loading && messageText}
            </div>
          )}
          <div
            className={'popup-body-buttons'}
            style={{
              marginTop:
                (step !== 3 || (step === 3 && coupon.voucherifyError)) &&
                '24px',
            }}
          >
            {btn}
            {step === 3 && coupon.voucherifyError && (
              <button
                style={{
                  width: 'unset',
                  height: 'unset',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  fontSize: '18px',
                  color: 'rgb(40, 78, 123)',
                  boxShadow: 'inset 0px 0px 0px 1px rgba(40, 78, 123, .2)',
                }}
                className={'popup-body-button popup-body-button-back'}
                onClick={handleRetry}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-chevron-left"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#2c3e50"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M15 6l-6 6l6 6" />
                  </svg>
                  Try Again
                </span>
              </button>
            )}
            {step === 3 && coupon.voucherifyError && (
              <button
                style={{
                  width: 'unset',
                  height: 'unset',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  fontSize: '18px',
                }}
                className={'popup-body-button'}
                onClick={() => openGorgiasChat()}
              >
                Contact Support
              </button>
            )}
          </div>
          <div style={{ margin: '0 auto', color: 'rgb(40, 78, 123)' }}>
            {coupon.loading && <Spinner />}
          </div>
        </div>
      </div>
      <div id={`sb-CheckoutInfo-overlay2`} onClick={clearModal} />
    </>
  );
};

export default AddCouponModal;

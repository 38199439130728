export const names = {
	boards: 'boards',
	user: 'user',
	email: 'email',
	shippingInfo: 'shippingInfo',
	shippingInfoError: 'shippingInfoError',
	checkout: 'checkout',
	checkoutError: 'checkoutError',
	coupon: 'coupon',
	voucher: 'voucher',
	klaviyoEmail: 'klaviyoEmail',
	stateCode: 'stateCode',
	countryCode: 'counrtyCode'
};

export const get = name => {
	let item = localStorage.getItem(name);
	try {
		item = JSON.parse(item);
	} catch (err) {
		item = null;
	}
	return item;
};

export const set = (name, value) => {
	const item = localStorage.setItem(name, JSON.stringify(value));
	return !!item;
};

export const remove = name => {
	const item = localStorage.removeItem(name);
	return !item;
};

export const CHECKOUT_START = 'CHECKOUT_START';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
export const CHECKOUT_END = 'CHECKOUT_END';

export const CHECKOUT_INIT = 'CHECKOUT_INIT';

export const CLEAR_CHECKOUT_ERROR = 'CLEAR_CHECKOUT_ERROR';

export const CHECKOUT_SIMULATION = 'CHECKOUT_SIMULATION';

export const UPDATE_STRIPE_INFO = 'UPDATE_STRIPE_INFO';

export const SHOW_CARD_INPUT = 'SHOW_CARD_INPUT';

export const SHOW_PAYMENT_MENU = 'SHOW_PAYMENT_MENU';
export const HIDE_PAYMENT_MENU = 'HIDE_PAYMENT_MENU';

export const SET_ORIGINAL_PRICE = 'SET_ORIGINAL_PRICE';

export const ACTIVATE_PAYPAL = 'ACTIVATE_PAYPAL';
export const ACTIVATE_APPLEPAY = 'ACTIVATE_APPLEPAY';
export const OPEN_CARD_FORM = 'OPEN_CARD_FORM';

export const PAYPAL_CHECKOUT = 'PAYPAL_CHECKOUT';
export const UPDATE_PAYPAL_DATA = 'UPDATE_PAYPAL_DATA';
export const APPLEPAY_CHECKOUT = 'APPLEPAY_CHECKOUT';
export const PAYPAL_CHECKOUT_CANCEL = 'PAYPAL_CHECKOUT_CANCEL';

export const UPDATE_CHECKOUT = 'UPDATE_CHECKOUT';

export const ACTIVATE_KLARNA = 'ACTIVATE_KLARNA';
export const KLARNA_LOADING_STOP = 'KLARNA_LOADING_STOP';
export const KLARNA_LOADING_START = 'KLARNA_LOADING_START';

export const UPDATEID = 'UPDATEID';
export const UPDATE_ORDER_ID = 'UPDATE_ORDER_ID';

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';

import { usePrevious } from '../../../utils/hooks';
import calculatePrice, {
  calculatePriceForSize,
} from '../../../utils/price/calculatePrice';
import { saveSession, updateUser } from '../../../redux/user/actions';
import {
  activateApplePay,
  activateCard,
  activateKlarna,
  activatePaypal,
  updateCheckout,
} from '../../../redux/checkout/actions';
import { ReactComponent as Info } from '../../../assets/images/info.svg';
import { checkoutImages } from '../../../lib/images';

import { currencyFormatter } from '../upsell';

import { Ss } from '../checkout-info/CheckoutComponents';

import PaymentInfo from '../payment-info';
import ShippingInfo from '../shipping-info';
import {
  FreeBoardsLine,
  BoardsLine,
  CouponLine,
  ShippingByLine,
  TotalPriceLine,
  AddressBtn,
  SelectPayment,
} from './CheckoutComponents';
import { CustomShipping } from './CustomShipping';

import { Group, Radio, Accordion, Avatar, Loader } from '@mantine/core';

import './CheckoutInfo.scss';
import CustomCheckbox from '../CustomCheckbox';
import { Modal, Popover } from '@material-ui/core';
import { isMobile } from '../../../utils/dimensions';
import {
  a_learnMoreClicked,
  a_subscriptionClicked,
} from '../../../api/analytics';
import { usePayPalScriptReducer } from '@paypal/react-paypal-js';
import axios from 'axios';
import { updateApp } from '../../../redux/app/actions';

import { Check } from '../../../assets/images/Check';
import { Iks } from '../../../assets/images/Iks';
import { Back } from '../../../assets/images/Back';
import ShippingInfoMobile, {
  verifyAddress,
} from '../shipping-info/ShippingInfo';
import { SBLogo } from '../../../assets/images/SBLogo';
import { Cards } from '../../../assets/images/Cards';
import { Close } from '../../../assets/images/Close';
import { tokenize } from '../stripe-mobile-form/StripeMobileForm';
import { ApplePayNew } from './ApplePay';
import { applePayAvailable } from '../../../utils/envHandler';
import { ApplePayLogo } from '../../../assets/images/ApplePayLogo';
import { KlarnaLogo } from '../../../assets/images/KlarnaLogo';
import { PaypalLogo } from '../../../assets/images/PaypalLogo';
import { NewPaypalButton } from './Paypal';
import { Klarna } from './Klarna';

const CLASS = 'sb-CheckoutInfo';
const MOBILE_ID = 'sb-CheckoutInfo';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  margin: 0,
  padding: 0,
  outline: 0,
  overflow: 'hidden',
  width: '340px',
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
  background: 'white',
};

const CheckoutInfo = ({ btnType, tip, handleCheckout }) => {
  const dispatch = useDispatch();
  const [{ options }, paypalDispatch] = usePayPalScriptReducer();
  const { frames } = useSelector((state) => state.app.cms);
  const { app, user, checkout } = useSelector((state) => state);
  const { error } = useSelector((state) => state.checkout.error);
  const { boards, selectedFrame } = useSelector((state) => state.boards);
  const { coupon } = useSelector((state) => state.coupon);
  const frameArray = frames?.filter((frame) => frame.img === selectedFrame);
  const frame = frameArray[0];
  const { selectedSize, subscriptions } = app;

  const [payment, setPayment] = useState('credit-card');

  const { page: step } = checkout;

  const [activeSubscription] = subscriptions.filter(
    (subscription) => subscription.isDefault,
  );

  const {
    screenText,
    discount: subscriptionDiscount,
    stripeSubscriptionPriceAmount,
  } = activeSubscription;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    a_learnMoreClicked();
  };
  const handleClose = () => setOpen(false);

  const { subscriptionEnabled: subEnabled } = app;

  const {
    paypalActive,
    klarnaActive,
    oneTimeOrSubscription,
    applePaySelected,
  } = checkout;
  const previousFrame = usePrevious(frame);
  const previousFrameSize = usePrevious(selectedSize);
  const previousCoupon = usePrevious(coupon?.name);
  const previousBoards = usePrevious(boards);
  const previousPrice = usePrevious(app.priceOne);
  const previousUpsell = usePrevious(checkout.upsell);
  const previousCard = usePrevious(checkout.stripe?.token?.id);
  const previousUserData = usePrevious(user);
  const previousOneTimeOrSubscription = usePrevious(oneTimeOrSubscription);

  const {
    isCheckoutOpen,
    addressInfoOpen,
    cardPaymentOpen,
    discount,
    totalPrice,
    originalPrice,
    saveTextPrice,
    totalWithSubscription,
    textPerMaterial,
    shouldUseCustomtext,
    offerMessage,
    offerPerMaterial,
  } = checkout;
  const isBoardsAdded = (boards?.length || 0) >= app.minBoards;

  const getPrice = (size) => {
    const framePrice = frame?.sizes[size].price;
    const price = calculatePriceForSize(
      [boards[0]],
      coupon,
      framePrice,
      oneTimeOrSubscription,
      subscriptions,
    );
    return price;
  };

  const setPrices = () => {
    if (isNaN(app.priceOne)) return;
    let {
      saveTextPrice,
      discount,
      topTierDiscount,
      totalPrice,
      originalPrice,
      totalWithSubscription,
      textPerMaterial,
      shouldUseCustomtext,
      boardsUpsellMessage,
      offerMessage,
      offerPerMaterial,
      vipBoardPrice,
    } = calculatePrice(
      boards,
      coupon,
      frame?.extraPrice,
      oneTimeOrSubscription,
      subscriptions,
      selectedSize,
      selectedFrame,
    );
    if (checkout.upsell) totalPrice += Number(app.cms.upsell.price);
    dispatch(
      updateCheckout({
        discount,
        totalPrice,
        originalPrice,
        saveTextPrice,
        totalWithSubscription,
        textPerMaterial,
        shouldUseCustomtext,
        boardsUpsellMessage,
        topTierDiscount,
        offerMessage,
        offerPerMaterial,
        vipBoardPrice,
      }),
    );
  };

  useEffect(() => {
    setPrices();
  }, []);

  useEffect(() => {
    paypalDispatch({
      type: 'resetOptions',
      value: {
        ...options,
        vault: oneTimeOrSubscription === 'subscription',
        intent:
          oneTimeOrSubscription === 'subscription' ? 'subscription' : 'capture',
      },
    });
  }, [paypalDispatch, oneTimeOrSubscription]);

  useEffect(() => {
    async function updateSubscription() {
      try {
        const { data } = await axios.post('/api/order/subscription', {
          email: user?.email,
        });
        dispatch(updateApp({ subscriptionEnabled: !data.result }));
        if (data.result) {
          dispatch(updateCheckout({ oneTimeOrSubscription: 'one-time' }));
          if (!sessionStorage.getItem('vip_modal')) {
            dispatch(updateUser({ showVipModal: true }));
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (checkout?.isCheckoutOpen) {
      updateSubscription();
    }
  }, [checkout?.isCheckoutOpen, user?.email]);

  useEffect(() => {
    const couponChanged = previousCoupon !== coupon?.name;
    const boardsChanged = previousBoards?.length !== boards?.length;
    const priceChanged = previousPrice !== app.priceOne;
    const upsellChanged = previousUpsell !== checkout.upsell;
    const cardChanged = previousCard !== checkout.stripe?.token?.id;
    const frameChanged = previousFrame !== frame;
    const frameSizeChanged = previousFrameSize !== selectedSize;
    const oneTimeOrSubscriptionChanged =
      previousOneTimeOrSubscription !== oneTimeOrSubscription;

    if (
      couponChanged ||
      boardsChanged ||
      priceChanged ||
      upsellChanged ||
      frameChanged ||
      oneTimeOrSubscriptionChanged ||
      frameSizeChanged
    ) {
      setPrices();
    }

    if (!boards && !previousBoards) {
      return;
    }

    const noWidthPrev =
      previousBoards && previousBoards.some((board) => !board?.width);
    const noWidth = boards && boards.some((board) => !board.width);
    if (noWidth || noWidthPrev || !user) {
      return;
    }

    const newUserData =
      JSON.stringify(user) !== JSON.stringify(previousUserData);

    const hasBoardsChanged =
      JSON.stringify(boards) !== JSON.stringify(previousBoards);

    const newSessionData =
      hasBoardsChanged || newUserData || couponChanged || cardChanged;

    if (newSessionData) {
      if (!boards?.length || checkout.checkingout || checkout.checkedout) {
        return;
      }
      const newBoards = boards
        .filter((board) => board.secureUrl)
        .map((b) => ({ ...b, s3Params: null }));
      const data = {
        app,
        user,
        coupon,
        checkout,
        token: app.token,
        boards: newBoards,
        subscription: oneTimeOrSubscription,
      };
      dispatch(saveSession(data));
    }
  }, [app, user, boards, coupon, checkout, oneTimeOrSubscription]);

  const closeCheckout = () => {
    // const { addressInfoOpen, cardPaymentOpen } = checkout;
    // if (!(addressInfoOpen || cardPaymentOpen)) {
    dispatch(updateCheckout({ isCheckoutOpen: false }));
    // }
  };

  const handleSelect = (value) => {
    a_subscriptionClicked();
    dispatch(updateCheckout({ oneTimeOrSubscription: value }));
    paypalDispatch({
      type: 'resetOptions',
      value: {
        ...options,
        vault: value === 'subscription',
        intent: value === 'subscription' ? 'subscription' : 'capture',
      },
    });
  };

  const renderInfoMessage = () => {
    let numberOfTiles = !boards ? 0 : boards.length;
    const style = tip ? { animation: 'shake 0.5s linear' } : undefined;

    return (
      <div
        className={`${CLASS}-infoMessage`}
        style={{ width: '90%', margin: '0 auto' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-alert-octagon-filled"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#00abfb"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M14.897 1a4 4 0 0 1 2.664 1.016l.165 .156l4.1 4.1a4 4 0 0 1 1.168 2.605l.006 .227v5.794a4 4 0 0 1 -1.016 2.664l-.156 .165l-4.1 4.1a4 4 0 0 1 -2.603 1.168l-.227 .006h-5.795a3.999 3.999 0 0 1 -2.664 -1.017l-.165 -.156l-4.1 -4.1a4 4 0 0 1 -1.168 -2.604l-.006 -.227v-5.794a4 4 0 0 1 1.016 -2.664l.156 -.165l4.1 -4.1a4 4 0 0 1 2.605 -1.168l.227 -.006h5.793zm-2.887 14l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -8a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z"
            strokeWidth="0"
            fill="rgb(166, 55, 252)"
          />
        </svg>
        <span
          style={{
            fontSize: '16px',
            fontWeight: 600,
            marginLeft: '12px',
            color: 'rgb(48, 78, 120)',
          }}
        >
          Add at least {app.minBoards - numberOfTiles} board
          {numberOfTiles === 0 || numberOfTiles === 1 ? 's' : ''} to checkout.
        </span>
      </div>
    );
  };

  const shippingCost = useSelector((state) => state.checkout.shippingCost);

  const { applePayActive, orderPrice } = checkout;

  let price;
  if (oneTimeOrSubscription === 'one-time') {
    price =
      totalPrice +
      shippingCost +
      (checkout.tax && !applePayActive && !paypalActive && !klarnaActive
        ? checkout.tax
        : 0);
  } else {
    price =
      totalWithSubscription +
      shippingCost +
      (checkout.tax && !applePayActive && !paypalActive && !klarnaActive
        ? checkout.tax
        : 0);
  }

  return (
    <>
      <div
        id={MOBILE_ID}
        style={{
          ...(!isMobile && { maxHeight: '75%' }),
        }}
        className={`${CLASS}-${isCheckoutOpen ? 'slideUp' : 'slideDown'} ${
          isBoardsAdded ? 'withInfo' : ''
        }`}
      >
        <div
          className={`${CLASS}-header`}
          style={{
            width: '100%',
            height: '52px',
            display: 'flex',
            padding: '12px',
            alignItems: 'center',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        >
          <div
            onClick={() => {
              if (step !== 1) {
                dispatch(updateCheckout({ page: 1 }));
              } else {
                closeCheckout();
              }
            }}
            style={{ display: 'flex', cursor: 'pointer', flex: 1 }}
          >
            {step === 1 ? (
              <Close style={{ height: '24px', width: '24px', opacity: 0.5 }} />
            ) : (
              <Back />
            )}
          </div>
          <div style={{ display: 'flex' }}>
            <SBLogo style={{ width: '154px' }} />
          </div>
          <div style={{ flex: 1 }} />
        </div>
        {isBoardsAdded ? (
          <>
            <div
              style={{
                display: step === 1 ? 'block' : 'none',
                overflow: 'auto',
                padding: '0px 18px 12px 18px',
              }}
            >
              {/* step 1 */}
              {subEnabled &&
                !(
                  coupon?.type === 'CARD' ||
                  klarnaActive ||
                  applePaySelected
                ) && (
                  <table
                    style={{
                      width: '100%',
                      fontSize: '14px',
                      color: '#304E78',
                      paddingTop: '12px',
                      paddingBottom: '12px',
                      borderCollapse: 'collapse',
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: '1px solid rgba(157, 157, 157, 0.2)',
                      }}
                    >
                      <td></td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'subscription' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        <div
                          style={{
                            padding: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '12px',
                          }}
                        >
                          <CustomCheckbox
                            size={18}
                            value={'subscription'}
                            handler={() => handleSelect('subscription')}
                            checked={oneTimeOrSubscription === 'subscription'}
                          />
                          <span
                            style={{
                              marginTop: '12px',
                              fontWeight: 600,
                              color: '#0B6B65',
                            }}
                          >
                            VIP Member
                          </span>
                        </div>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'one-time' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '12px',
                          }}
                        >
                          <CustomCheckbox
                            size={18}
                            value={'one-time'}
                            handler={() => handleSelect('one-time')}
                            checked={oneTimeOrSubscription === 'one-time'}
                          />
                          <span
                            style={{
                              marginTop: '12px',
                              fontWeight: 600,
                              color: '#304E78',
                            }}
                          >
                            Non-Member
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'center',
                        borderBottom: '1px solid rgba(157, 157, 157, 0.2)',
                      }}
                    >
                      <td style={{ textAlign: 'left' }}>
                        <span style={{ padding: '8px 0px', display: 'block' }}>
                          Welcome Deal
                        </span>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'subscription' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        {offerMessage || offerPerMaterial}
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'one-time' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        No Offers
                      </td>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'center',
                        borderBottom: '1px solid rgba(157, 157, 157, 0.2)',
                      }}
                    >
                      <td style={{ textAlign: 'left' }}>
                        <span
                          style={{
                            display: 'block',
                            padding: '8px 0px',
                          }}
                        >
                          Min 75% off
                        </span>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'subscription' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Check />
                        </div>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'one-time' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Iks />
                        </div>
                      </td>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'center',
                        borderBottom: '1px solid rgba(157, 157, 157, 0.2)',
                      }}
                    >
                      <td style={{ textAlign: 'left' }}>
                        <span style={{ padding: '8px 0px', display: 'block' }}>
                          Free Photo Album
                        </span>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'subscription' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Check />
                        </div>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'one-time' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Iks />
                        </div>
                      </td>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'center',
                        borderBottom: '1px solid rgba(157, 157, 157, 0.2)',
                      }}
                    >
                      <td style={{ textAlign: 'left' }}>
                        <span style={{ padding: '8px 0px', display: 'block' }}>
                          Member Offers
                        </span>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'subscription' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Check />
                        </div>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'one-time' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Iks />
                        </div>
                      </td>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'center',
                        borderBottom: '1px solid rgba(157, 157, 157, 0.2)',
                      }}
                    >
                      <td style={{ textAlign: 'left' }}>
                        <span style={{ padding: '8px 0px', display: 'block' }}>
                          Cancel Anytime
                        </span>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'subscription' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Check />
                        </div>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'one-time' && {
                            backgroundColor: '#D6F3D4',
                          }),
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Iks />
                        </div>
                      </td>
                    </tr>
                    <tr style={{ textAlign: 'center' }}>
                      <td
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        <span style={{ padding: '8px 0px', display: 'block' }}>
                          You Pay
                        </span>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'subscription' && {
                            boxShadow: 'inset 0px 0px 0px 2px #D6F3D4',
                          }),
                        }}
                      >
                        <span style={{ fontSize: '16px', fontWeight: 600 }}>
                          {currencyFormatter(totalWithSubscription, 'usd', 2)}
                        </span>
                      </td>
                      <td
                        style={{
                          ...(oneTimeOrSubscription === 'one-time' && {
                            boxShadow: 'inset 0px 0px 0px 2px #D6F3D4',
                          }),
                        }}
                      >
                        <span style={{ fontSize: '16px', fontWeight: 600 }}>
                          {currencyFormatter(totalPrice, 'usd', 2)}
                        </span>
                      </td>
                    </tr>
                  </table>
                )}
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#304E78',
                  display:
                    subEnabled && oneTimeOrSubscription === 'subscription'
                      ? 'block'
                      : 'none',
                }}
              >
                As a VIP Member you'll be charged&nbsp;
                {currencyFormatter(stripeSubscriptionPriceAmount, 'usd', 2)}
                /month. This charge becomes store credit in your account. You
                always have the option to Skip each month to avoid being
                charged. Store credit can be used to shop anytime, is refundable
                for up to one year and never expires.
              </p>
              <span
                onClick={handleOpen}
                style={{
                  fontWeight: 700,
                  color: '#BF3EFB',
                  fontSize: '12px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  display:
                    subEnabled && oneTimeOrSubscription === 'subscription'
                      ? 'inline-block'
                      : 'none',
                }}
              >
                Learn more
              </span>
              <div
                style={{
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  borderBottom: '1px solid rgba(157, 157, 157, 0.50)',
                  display:
                    subEnabled && oneTimeOrSubscription === 'subscription'
                      ? 'block'
                      : 'none',
                }}
              ></div>
              <p
                style={{
                  fontWeight: 700,
                  color: '#284E7B',
                  fontSize: '18px',
                  marginTop: '12px',
                  marginBottom: '12px',
                  textTransform: 'uppercase',
                }}
              >
                SHOPPING BAG
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: '16px',
                    marginLeft: '12px',
                    textTransform: 'none',
                  }}
                >
                  {boards?.length} Tiles
                </span>
              </p>
              <div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Ss style={{ width: '40px', height: '40px' }} />
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      marginLeft: '24px',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        gap: '8px',
                        display: 'flex',
                        marginRight: 'auto',
                      }}
                    >
                      <span>{frame?.checkoutDescription}</span>
                      <span
                        style={{
                          color: '#1C3E64',
                          fontSize: '16px',
                          fontWeight: '400',
                        }}
                      >
                        {selectedSize}” x {selectedSize}”
                      </span>
                    </div>
                    <div
                      style={{
                        gap: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          textAlign: 'right',
                          flexDirection: 'column',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '14px',
                            color: '#304E78',
                            fontWeight: 600,
                            display:
                              oneTimeOrSubscription !== 'subscription'
                                ? 'none'
                                : 'block',
                          }}
                          className={
                            totalPrice !== originalPrice
                              ? `${CLASS}-checkoutInfoWrapper-checkoutInfo-fullPrice`
                              : ''
                          }
                        >
                          {currencyFormatter(
                            oneTimeOrSubscription === 'subscription'
                              ? discount?.couponAmountToDeduct
                                ? discount?.amountWithBDisc / 100
                                : totalPrice
                              : originalPrice,
                            'usd',
                            2,
                          )}
                        </span>
                        <span
                          style={{
                            fontSize: '16px',
                            color: '#0B6B65',
                            fontWeight: '600',
                            backgroundColor: '#D6F3D4',
                          }}
                        >
                          {oneTimeOrSubscription === 'subscription' && (
                            <span style={{ fontWeight: 700 }}>VIP </span>
                          )}
                          {currencyFormatter(
                            oneTimeOrSubscription === 'subscription'
                              ? totalWithSubscription
                              : discount?.couponAmountToDeduct
                                ? discount?.amountWithBDisc / 100
                                : totalPrice,
                            'usd',
                            2,
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  paddingBlock: '8px',
                  borderBottom: '1px solid rgba(157, 157, 157, 0.50)',
                }}
              ></div>
              <div
                style={{
                  gap: '12px',
                  display: 'flex',
                  fontWeight: 400,
                  fontSize: '16px',
                  color: '#304E78',
                  marginTop: '12px',
                  flexDirection: 'column',
                }}
              >
                {discount?.couponAmountToDeduct && (
                  <div style={{ display: 'flex' }}>
                    <span>VIP Credit Applied</span>
                    <span style={{ marginLeft: 'auto' }}>
                      -
                      {currencyFormatter(
                        discount?.couponAmountToDeduct,
                        'usd',
                        2,
                      )}
                    </span>
                  </div>
                )}
                <div style={{ display: 'flex' }}>
                  <span>Subtotal</span>
                  <span style={{ marginLeft: 'auto' }}>
                    {currencyFormatter(totalPrice, 'usd', 2)}
                  </span>
                </div>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: '16px',
                    color: '#0B6B65',
                    display:
                      oneTimeOrSubscription === 'subscription'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <span>
                    {shouldUseCustomtext ? textPerMaterial : screenText}
                  </span>
                  <span style={{ marginLeft: 'auto' }}>-{saveTextPrice}</span>
                </div>
                <div style={{ display: 'flex' }}>
                  <span>Shipping</span>
                  <span style={{ marginLeft: 'auto' }}>
                    {currencyFormatter(shippingCost, 'usd', 2)}
                  </span>
                </div>
                <div style={{ display: 'none' }}>
                  <CustomShipping />
                </div>
                <div style={{ display: 'flex' }}>
                  <span>Sales Tax</span>
                  <span style={{ marginLeft: 'auto' }}>
                    <TaxLine />
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    fontWeight: 600,
                    fontSize: '16px',
                    color: '#304E78',
                    marginTop: '12px',
                  }}
                >
                  <span>Order Total (USD)</span>
                  <span style={{ marginLeft: 'auto' }}>
                    {price === 0
                      ? 'FREE'
                      : `${currencyFormatter(price, 'usd', 2)}`}
                  </span>
                </div>
              </div>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#304E78',
                  display:
                    subEnabled && oneTimeOrSubscription === 'subscription'
                      ? 'block'
                      : 'none',
                }}
              >
                With this purchase you choose to become a VIP member and
                understand that you will be charged&nbsp;
                {currencyFormatter(stripeSubscriptionPriceAmount, 'usd', 2)}
                /month that becomes store credit. You can use your store credit
                to shop whenever (with major perks!) or get a refund. Of course,
                you can cancel your membership anytime.
              </p>
              <div
                onClick={() => dispatch(updateCheckout({ page: 2 }))}
                style={{
                  width: '100%',
                  color: 'white',
                  fontWeight: 600,
                  fontSize: '20px',
                  cursor: 'pointer',
                  marginTop: '24px',
                  borderRadius: '4px',
                  textAlign: 'center',
                  padding: '14px 32px',
                  background:
                    'linear-gradient(90deg, #A638FC 0%, #C03CFC 104.31%)',
                }}
              >
                {subEnabled && oneTimeOrSubscription === 'subscription'
                  ? 'Agree & Continue'
                  : 'Continue to checkout'}
              </div>
            </div>
            <div
              style={{
                display: step === 2 ? 'block' : 'none',
                overflow: 'auto',
                padding: '0px 18px 12px 18px',
              }}
            >
              {/* step 2 */}
              <div>
                <p
                  style={{
                    fontSize: '20px',
                    fontWeight: 700,
                    color: '#284E7B',
                    margin: '12px 0px',
                  }}
                >
                  SHIP TO
                </p>
                <ShippingInfoMobile toggleShippingInfo={true} />
                <div
                  style={{
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    borderBottom: '1px solid rgba(157, 157, 157, 0.50)',
                  }}
                ></div>
                {price !== 0 && (
                  <Fragment>
                    <p
                      style={{
                        fontSize: '20px',
                        fontWeight: 700,
                        color: '#284E7B',
                        margin: '12px 0px',
                      }}
                    >
                      PAYMENT
                      <br />
                      <span style={{ fontSize: '14px', fontWeight: 400 }}>
                        All transactions are secure and encrypted.
                      </span>
                    </p>
                    <Accordion
                      value={payment}
                      variant="contained"
                      onChange={(value) => {
                        if (value) {
                          setPayment(value);
                        }
                        switch (value) {
                          case 'credit-card':
                            dispatch(activateCard());
                            break;
                          case 'apple-pay':
                            dispatch(activateApplePay());
                            break;
                          case 'paypal':
                            dispatch(activatePaypal());
                            break;
                          case 'klarna':
                            dispatch(activateKlarna());
                            break;
                        }
                      }}
                      styles={{
                        chevron: {
                          display: 'none',
                        },
                        label: { padding: 0 },
                      }}
                    >
                      <Accordion.Item value="credit-card">
                        <Accordion.Control
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: '12px',
                            paddingRight: '12px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                gap: '12px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <SelectedOrNot
                                selected={payment === 'credit-card'}
                              />
                              <p>Credit Card</p>
                            </div>
                            <Cards />
                          </div>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <PaymentInfo />
                        </Accordion.Panel>
                      </Accordion.Item>
                      {applePayAvailable() &&
                        oneTimeOrSubscription !== 'subscription' && (
                          <Accordion.Item value="apple-pay">
                            <Accordion.Control
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: '12px',
                                paddingRight: '12px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div
                                  style={{
                                    gap: '12px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <SelectedOrNot
                                    selected={payment === 'apple-pay'}
                                  />
                                  <p>Apple Pay</p>
                                </div>
                                <ApplePayLogo />
                              </div>
                            </Accordion.Control>
                          </Accordion.Item>
                        )}
                      <Accordion.Item value="paypal">
                        <Accordion.Control
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: '12px',
                            paddingRight: '12px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                gap: '12px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <SelectedOrNot selected={payment === 'paypal'} />
                              <p>PayPal</p>
                            </div>
                            <PaypalLogo />
                          </div>
                        </Accordion.Control>
                      </Accordion.Item>
                      {oneTimeOrSubscription !== 'subscription' && (
                        <Accordion.Item value="klarna">
                          <Accordion.Control
                            disabled={oneTimeOrSubscription === 'subscription'}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: '12px',
                              paddingRight: '12px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  gap: '12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <SelectedOrNot
                                  selected={payment === 'klarna'}
                                />
                                <p>Klarna</p>
                              </div>
                              <KlarnaLogo />
                            </div>
                          </Accordion.Control>
                        </Accordion.Item>
                      )}
                    </Accordion>
                    <div
                      style={{
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        borderBottom: '1px solid rgba(157, 157, 157, 0.50)',
                      }}
                    ></div>
                  </Fragment>
                )}
                <div
                  style={{
                    gap: '12px',
                    display: 'flex',
                    fontWeight: 400,
                    fontSize: '16px',
                    color: '#304E78',
                    marginTop: '12px',
                    flexDirection: 'column',
                  }}
                >
                  {discount?.couponAmountToDeduct && (
                    <div style={{ display: 'flex' }}>
                      <span>VIP Credit Applied</span>
                      <span style={{ marginLeft: 'auto' }}>
                        -
                        {currencyFormatter(
                          discount?.couponAmountToDeduct,
                          'usd',
                          2,
                        )}
                      </span>
                    </div>
                  )}
                  <div style={{ display: 'flex' }}>
                    <span>Subtotal</span>
                    <span style={{ marginLeft: 'auto' }}>
                      {currencyFormatter(totalPrice, 'usd', 2)}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: '16px',
                      color: '#0B6B65',
                      display:
                        oneTimeOrSubscription === 'subscription'
                          ? 'flex'
                          : 'none',
                    }}
                  >
                    <span>
                      {shouldUseCustomtext ? textPerMaterial : screenText}
                    </span>
                    <span style={{ marginLeft: 'auto' }}>-{saveTextPrice}</span>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <span>Shipping</span>
                    <span style={{ marginLeft: 'auto' }}>
                      {currencyFormatter(shippingCost, 'usd', 2)}
                    </span>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <span>Sales Tax</span>
                    <span style={{ marginLeft: 'auto' }}>
                      <TaxLine />
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      fontWeight: 600,
                      fontSize: '16px',
                      color: '#304E78',
                      marginTop: '12px',
                    }}
                  >
                    <span>Order Total (USD)</span>
                    <span style={{ marginLeft: 'auto' }}>
                      {price === 0
                        ? 'FREE'
                        : `${currencyFormatter(price, 'usd', 2)}`}
                    </span>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#304E78',
                    display:
                      oneTimeOrSubscription === 'subscription'
                        ? 'block'
                        : 'none',
                  }}
                >
                  With this purchase you choose to become a VIP member and
                  understand that you will be charged&nbsp;
                  {currencyFormatter(stripeSubscriptionPriceAmount, 'usd', 2)}
                  /month that becomes store credit. You can use your store
                  credit to shop whenever (with major perks!) or get a refund.
                  Of course, you can cancel your membership anytime.
                </p>
                <div
                  style={{
                    gap: '8px',
                    padding: '12px',
                    marginTop: '12px',
                    borderRadius: '4px',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    display:
                      oneTimeOrSubscription !== 'subscription'
                        ? 'flex'
                        : 'none',
                    border: '1px solid rgba(157, 157, 157, 0.5)',
                  }}
                >
                  <KlarnaLogo style={{ flex: 1 }} />
                  <div style={{ flex: 4 }}>
                    <span style={{ fontSize: '14px', fontWeight: 500 }}>
                      4 interest-free payments of ${(price / 4).toFixed(2)}.
                    </span>
                    <br />
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.klarna.com/us/pay-in-4/"
                      style={{
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: 'rgb(48, 78, 120)',
                        textDecoration: 'underline',
                      }}
                    >
                      Learn more
                    </a>
                  </div>
                </div>
                <div
                  id="payment-wrapper"
                  style={{
                    marginTop: '12px',
                  }}
                >
                  {oneTimeOrSubscription !== 'subscription' &&
                    payment === 'apple-pay' && <ApplePayNew />}
                  {payment === 'credit-card' && (
                    <div
                      onClick={() => {
                        if (price === 0) {
                          handleCheckout();
                          return;
                        }
                        if (user.shippingInfoError) {
                          verifyAddress();
                          return;
                        }
                        if (!checkout.isLoadingToken && !checkout.stripe) {
                          tokenize();
                          return;
                        }
                        handleCheckout();
                      }}
                      style={{
                        gap: '12px',
                        width: '100%',
                        display: 'flex',
                        color: 'white',
                        fontWeight: 600,
                        fontSize: '20px',
                        cursor: 'pointer',
                        alignItems: 'center',
                        borderRadius: '4px',
                        padding: '14px 32px',
                        justifyContent: 'center',
                        ...(checkout.isLoadingToken && {
                          opacity: 0.5,
                          pointerEvents: 'none',
                        }),
                        background:
                          'linear-gradient(90deg, #A638FC 0%, #C03CFC 104.31%)',
                      }}
                    >
                      Place Order
                    </div>
                  )}
                  {payment === 'paypal' && <NewPaypalButton />}
                  {oneTimeOrSubscription !== 'subscription' &&
                    payment === 'klarna' && <Klarna />}
                </div>
              </div>
            </div>
          </>
        ) : (
          renderInfoMessage()
        )}
        {checkout.paypalApproving && (
          <div
            id="sb-CheckoutInfo-overlay3"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader
              size="xl"
              style={{ zIndex: 200, stroke: 'rgb(255, 255, 255)' }}
            />
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div
            onClick={() => setOpen(false)}
            style={{
              position: 'absolute',
              right: '12px',
              top: '12px',
              cursor: 'pointer',
              zIndex: 2,
            }}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2.00002L2 12"
                stroke="#00649D"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 2.00002L12 12"
                stroke="#00649D"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div style={{ position: 'relative', padding: '16px' }}>
            <div>
              <Info />
            </div>
          </div>
        </div>
      </Modal>
      {/* Gray Overlay */}
      <div
        id={`${MOBILE_ID}-overlay`}
        className={`${CLASS}-${isCheckoutOpen ? 'fadeIn' : 'fadeOut'}`}
        onClick={closeCheckout}
      />
    </>
  );
};

export default CheckoutInfo;

const TaxLine = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const checkout = useSelector((state) => state.checkout);
  const { selectedSize } = useSelector((state) => state.app);
  const { selectedFrame } = useSelector((state) => state.boards);
  const { city, state, street, country, postalCode, shippingInfoError } = user;
  const {
    totalPrice,
    shippingCost,
    isCheckoutOpen,
    totalWithSubscription,
    oneTimeOrSubscription,
  } = checkout;

  const previousCity = usePrevious(city);
  const previousState = usePrevious(state);
  const previousStreet = usePrevious(street);
  const previousCountry = usePrevious(country);
  const previousTotalPrice = usePrevious(totalPrice);
  const previousPostalCode = usePrevious(postalCode);
  const previousShippingInfoError = usePrevious(shippingInfoError);
  const previousOneTimeOrSubscription = usePrevious(oneTimeOrSubscription);

  useEffect(() => {
    if (!city || !state || !street || !country || !postalCode) {
      return;
    }

    const cityChanged = previousCity !== city;
    const stateChanged = previousState !== state;
    const streetChanged = previousStreet !== street;
    const countryChanged = previousCountry !== country;
    const totalPriceChanged = previousTotalPrice !== totalPrice;
    const postalCodeChanged = previousPostalCode !== postalCode;
    const errorChanged = previousShippingInfoError !== shippingInfoError;
    const oneTimeOrSubscriptionChanged =
      previousOneTimeOrSubscription !== oneTimeOrSubscription;

    async function getTax() {
      setLoading(true);
      try {
        const response = await axios.post('/api/order/tax', {
          pricing: {
            taxCode: 'txcd_99999999',
            amount: (oneTimeOrSubscription === 'subscription'
              ? totalWithSubscription * 100
              : totalPrice * 100
            ).toFixed(0),
            shipping: (shippingCost * 100).toFixed(0),
            reference: `${selectedFrame}-${selectedSize}`,
          },
          customer: {
            city,
            state,
            street,
            country,
            postalCode,
          },
        });
        dispatch(updateCheckout({ tax: response.data.result }));
        setUpdate(false);
        setError('');
      } catch (error) {
        setError(error);
        dispatch(updateCheckout({ tax: null }));
      } finally {
        setLoading(false);
      }
    }

    if (
      cityChanged ||
      stateChanged ||
      streetChanged ||
      countryChanged ||
      totalPriceChanged ||
      postalCodeChanged ||
      oneTimeOrSubscriptionChanged ||
      errorChanged
    ) {
      setUpdate(true);
    }

    if (isCheckoutOpen && update) {
      getTax();
    }
  }, [
    city,
    country,
    isCheckoutOpen,
    postalCode,
    previousCity,
    previousCountry,
    previousPostalCode,
    previousShippingInfoError,
    previousTotalPrice,
    selectedFrame,
    selectedSize,
    previousState,
    previousStreet,
    shippingCost,
    state,
    street,
    totalPrice,
    totalWithSubscription,
    oneTimeOrSubscription,
    shippingInfoError,
    previousOneTimeOrSubscription,
  ]);

  if (loading) {
    return <span>...</span>;
  }

  return <span>{currencyFormatter(checkout.tax || 0, 'usd', 2)}</span>;
};

const SelectedOrNot = ({ selected }) =>
  selected ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m-.997-6l7.07-7.071l-1.413-1.414l-5.657 5.657l-2.829-2.829l-1.414 1.414z"
      />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16"
      />
    </svg>
  );

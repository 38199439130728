import React from 'react';
import classnames from 'classnames';

import detectPlatform from '../../../utils/detectPlatform';

import './PageWrapper.scss';

const { os, vpWidth } = detectPlatform();
const CLASS = 'sb-PageWrapper';

const PageWrapper = ({ children, home }) => {
	const pageWrapperClass = classnames(CLASS, {
		[`${CLASS}-home`]: home,
		[`${CLASS}-home-iOS`]: home && os === 'iOS' && vpWidth < 990,
	});
	return <div className={pageWrapperClass}>{children}</div>;
};

export default PageWrapper;

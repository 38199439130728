import React, {useEffect, useRef} from 'react';

import './Confetti.scss';

// const MyPromise = require('bluebird');
const confetti = require('canvas-confetti');

const Conffeti = () => {
	const canvas = useRef(null);
	// confetti.Promise = MyPromise;

	useEffect(() => {
		let myConfetti = confetti.create(canvas.current, {resize: true});
		let duration = 3 * 1000;
		let animationEnd = Date.now() + duration;
    let defaults = {startVelocity: 30, spread: 360, ticks: 60, zIndex: 0};
    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }    
    let interval = setInterval(function() {
      let timeLeft = animationEnd - Date.now();
    
      if (timeLeft <= 0) {
        return clearInterval(interval);
      } 
      let particleCount = 50 * (timeLeft / duration);
      myConfetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
      myConfetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
    }, 250);
	}, []);
	return <canvas ref={canvas} width={700} height={900} />;
};
export default Conffeti;

import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Klarna } from '../checkout-info/Klarna';
import {
  PayPalBtn,
  ApplePayBtn,
  PlaceOrderBtn,
} from '../../../views/components/checkout-info/CheckoutComponents';

const CLASS = 'sb-Footer';

const FooterCta = ({ btnType, onCtaClick }) => {
  const ctaClass = classnames({
    'sb-Footer-button': true,
  });

  return (
    <div className={`${CLASS}-actions`}>
      {btnType && (
        <div className={ctaClass} onClick={onCtaClick}>
          {/* <ApplePayBtn btnType={btnType} /> */}
          {/* <PayPalBtn btnType={btnType} /> */}
          <PlaceOrderBtn btnType={btnType} />
          {/* <Klarna btnType={btnType} /> */}
        </div>
      )}
    </div>
  );
};

const FooterCta2 = ({ btnType, onCtaClick }) => {
  const { applePay, paypal } = btnType || {};
  const { checkout } = useSelector((state) => state);

  const ctaClass = classnames({
    'sb-Footer-button': true,
    'btn-paypal': paypal && checkout.isCheckoutOpen,
    'btn-applepay': applePay && checkout.isCheckoutOpen,
  });
  return (
    <div>
      {btnType && (
        <div
          className={ctaClass}
          onClick={onCtaClick}
          style={{ width: '100%' }}
        >
          <ApplePayBtn btnType={btnType} />
          <PayPalBtn btnType={btnType} />
          <PlaceOrderBtn btnType={btnType} />
          <Klarna btnType={btnType} />
        </div>
      )}
    </div>
  );
};

export default FooterCta;
export { FooterCta2 };

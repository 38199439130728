import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PaymentRequestButtonElement,
  injectStripe,
} from 'react-stripe-elements';

import { a_applePayPressed, a_applePayPurchase } from '../../../api/analytics';
import { charge } from '../../../redux/checkout/actions';
import { logAny } from '../../../utils/events';

import './ApplePayButton.scss';
import { verifyAddress } from '../shipping-info/ShippingInfo';

const isBigButton = document.documentElement?.clientWidth >= 1200;
const CLASS = 'sb-ApplePayButton';

class ApplePayButton extends Component {
  constructor(props) {
    super(props);

    const paymentRequest = props.stripe.paymentRequest({
      country: 'US',
      currency: (this.props.coup.currency || 'USD').toLowerCase(),
      total: {
        label: 'Photo Boards',
        amount: Math.floor(this.props.price * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    paymentRequest.on('token', ({ complete, payerEmail, token, ...data }) => {
      const { user, totalPrice } = this.props;
      const stripe = { ...data, token };
      const userWithMail = { ...user, email: payerEmail };
      let cp = setInterval(async () => {
        const { boards, charge } = this.props;
        const allHaveStatus = boards.every((b) => b.status);
        if (allHaveStatus) {
          clearInterval(cp);
          charge({ user: userWithMail, applepay: complete, stripe });
          a_applePayPurchase(boards, totalPrice);
        }
      }, 1000);
    });

    paymentRequest.canMakePayment().then((result) => {
      this.setState({ canMakePayment: !!result });
      this.props.setShowApplePay(!!result);
      if (this.props.user.shippingInfoError) {
        this.setState({ canMakePayment: false });
      }
    });

    this.state = {
      canMakePayment: false,
      paymentRequest,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.price !== prevProps.price) {
      this.state.paymentRequest.update({
        total: {
          label: 'Photo Boards',
          amount: Math.floor(this.props.price * 100),
        },
      });
    }

    if (
      this.props.user.shippingInfoError !== prevProps.user.shippingInfoError
    ) {
      this.state.paymentRequest.canMakePayment().then((result) => {
        this.setState({ canMakePayment: !!result });
        this.props.setShowApplePay(!!result);
        if (this.props.user.shippingInfoError) {
          this.setState({ canMakePayment: false });
        }
      });
    }
  }

  render() {
    logAny({
      message: 'rendering real apple pay button',
      checkout: this.props.checkout,
    });
    return this.state.canMakePayment ? (
      <PaymentRequestButtonElement
        paymentRequest={this.state.paymentRequest}
        onClick={() => a_applePayPressed()}
        className={CLASS}
        style={{
          paymentRequestButton: {
            theme: 'dark',
            height: isBigButton ? '65px' : '55px',
          },
        }}
      />
    ) : (
      <div
        onClick={() => verifyAddress()}
        style={{
          height: '55px',
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '4px',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'black',
        }}
      >
        <svg
          fill="white"
          height="40px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-76.79115 -52.55 665.5233 315.3"
        >
          <path d="M93.541 27.1c-6 7.1-15.6 12.7-25.2 11.9-1.2-9.6 3.5-19.8 9-26.1 6-7.3 16.5-12.5 25-12.9 1 10-2.9 19.8-8.8 27.1m8.7 13.8c-13.9-.8-25.8 7.9-32.4 7.9-6.7 0-16.8-7.5-27.8-7.3-14.3.2-27.6 8.3-34.9 21.2-15 25.8-3.9 64 10.6 85 7.1 10.4 15.6 21.8 26.8 21.4 10.6-.4 14.8-6.9 27.6-6.9 12.9 0 16.6 6.9 27.8 6.7 11.6-.2 18.9-10.4 26-20.8 8.1-11.8 11.4-23.3 11.6-23.9-.2-.2-22.4-8.7-22.6-34.3-.2-21.4 17.5-31.6 18.3-32.2-10-14.8-25.6-16.4-31-16.8m80.3-29v155.9h24.2v-53.3h33.5c30.6 0 52.1-21 52.1-51.4s-21.1-51.2-51.3-51.2zm24.2 20.4h27.9c21 0 33 11.2 33 30.9s-12 31-33.1 31h-27.8zm129.8 136.7c15.2 0 29.3-7.7 35.7-19.9h.5v18.7h22.4V90.2c0-22.5-18-37-45.7-37-25.7 0-44.7 14.7-45.4 34.9h21.8c1.8-9.6 10.7-15.9 22.9-15.9 14.8 0 23.1 6.9 23.1 19.6v8.6l-30.2 1.8c-28.1 1.7-43.3 13.2-43.3 33.2 0 20.2 15.7 33.6 38.2 33.6zm6.5-18.5c-12.9 0-21.1-6.2-21.1-15.7 0-9.8 7.9-15.5 23-16.4l26.9-1.7v8.8c0 14.6-12.4 25-28.8 25zm82 59.7c23.6 0 34.7-9 44.4-36.3l42.5-119.2h-24.6l-28.5 92.1h-.5l-28.5-92.1h-25.3l41 113.5-2.2 6.9c-3.7 11.7-9.7 16.2-20.4 16.2-1.9 0-5.6-.2-7.1-.4v18.7c1.4.4 7.4.6 9.2.6z" />
        </svg>
      </div>
    );
  }
}

const mapStateToProps = ({ app, boards, checkout, coupon, user, error }) => ({
  coup: coupon,
  boards: boards.boards,
  coupon: coupon.coupon,
  totalPrice: checkout.totalPrice,
  shippingCost: checkout.shippingCost,
  error: app.error,
  checkout,
  user,
});

export default connect(mapStateToProps, { charge })(
  injectStripe(ApplePayButton),
);

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthenticationContext } from '../context/AuthenticationContext';

import { jwtDecode } from 'jwt-decode';

import { Cookies } from '../app/App';

const eventEmmiter = {
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
};

const isTokenExpired = (token) => {
  const decodedToken = jwtDecode(token);
  const isExpired = Date.now() >= decodedToken.exp * 1000;
  return isExpired;
};

export const ValidateAuthentication = () => {
  const { pathname } = useLocation();
  const { logout, setLoggedIn } = useAuthenticationContext();
  useEffect(() => {
    const logoutEvent = () => logout();
    eventEmmiter.on('logout', logoutEvent);
    return () => {
      eventEmmiter.remove('logout');
    };
  }, [logout]);
  useEffect(() => {
    const payloadCookie = Cookies.get('_p');
    if (payloadCookie && !isTokenExpired(payloadCookie)) {
      setLoggedIn(jwtDecode(payloadCookie));
      return;
    }
    setLoggedIn(null);
  }, [pathname, setLoggedIn]);
  return null;
};

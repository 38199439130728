export const Back = () => (
  <svg
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M7.75441 11.2544L13.7544 5.25441C14.1645 4.84436 14.8293 4.84436 15.2393 5.25441C15.6494 5.66446 15.6494 6.32929 15.2393 6.73934L9.9818 11.9969L15.2393 17.2544C15.6494 17.6645 15.6494 18.3293 15.2393 18.7393C14.8293 19.1494 14.1645 19.1494 13.7544 18.7393L7.75441 12.7393C7.34436 12.3293 7.34436 11.6645 7.75441 11.2544Z"
        fill="#1D3E64"
      />
    </g>
  </svg>
);

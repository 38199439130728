import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Board from '../../components/board';
import { getNextSiblings } from '../../../utils/dom';
import { clickAndDrag, drag } from '../../../utils/clickAndDrag';
import classNames from 'classnames';
import { boardsActions } from '../../../redux/boards';
import * as events from '../../../utils/events';

import './BoardsStripNew.scss';

import starStruck from '../../../assets/images/star-struck.png';
import { nanoid } from 'nanoid';
import { isMobile } from '../../../utils/dimensions';
import { usePrevious } from '../../../utils/hooks';
import { CSSTransition } from 'react-transition-group';

import bg_desktop from '../../../assets/images/boards-bg-desktop.png';
import bg_mobile from '../../../assets/images/boards-bg.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { updateUser } from '../../../redux/user/actions';
import { a_VipModalHandled } from '../../../api/analytics';

const CLASS = 'sb-BoardsStrip';

const BoardsStrip = ({
  setBoardInMenu,
  onRemove,
  toggleCropper,
  showBoardMenu,
}) => {
  const [initialUpload, setInitialUpload] = useState();
  const dispatch = useDispatch();
  const { app, checkout } = useSelector((state) => state);
  const { boards } = useSelector((state) => state.boards);
  const { coupon } = useSelector((state) => state.coupon);
  const { discount } = useSelector((state) => state.checkout);
  const framesData = app.cms.frames;
  const frame = useSelector((state) => state.boards.selectedFrame);

  const newBoards = boards;
  let upload = null;
  const { uploadImage, hideUploadOptions } = boardsActions;

  const boardRefFirst = useRef();
  let activeImages = framesData?.filter((x) => x.isShown).length;

  const previousFrame = usePrevious(frame);

  const { topTierDiscount, boardsUpsellMessage } = checkout;

  let btext = coupon?.btext;
  if (btext === 'HIDE' || btext === 999 || btext === '999') {
    btext = null;
  }

  if (discount && discount.couponType && discount.couponType.BUND) {
    btext = discount.ctext;
  }

  let highestDiscount;

  try {
    highestDiscount = Object.values(coupon?.override)
      .map(({ tiers }) => tiers)
      .flat()
      .sort((a, b) => a.discount - b.discount)
      .reverse()[0].discount;
  } catch {}

  const loadFiles = (files) => {
    const images = [];
    for (let file of files) {
      const id = nanoid();
      const blob = URL.createObjectURL(file);
      images.push({ id, file, blob, frame });
    }
    onUpload(images);
    dispatch(uploadImage(images, false, app.remarketingId));
    if (upload) upload.value = null;
  };

  const onFileChange = (event) => {
    const { files } = event.target;
    loadFiles(files);
  };

  const onUploadClick = (e) => {
    e.stopPropagation();
    events.onUploadClick();
    upload.click();
    dispatch(hideUploadOptions());
  };

  const BS_CLASS = classNames(CLASS, {
    [`${CLASS}-noBoards`]: boards === null || boards?.length < 1,
    [`${CLASS}-iOS`]: app.platform.os === 'iOS',
    [`${CLASS}-frames`]: activeImages >= 2,
    [`${CLASS}-offer`]: !!btext,
  });

  useEffect(() => {
    clickAndDrag(CLASS);
    drag('.drag');
  }, []);

  const onUploadLeft = (blobs, board) => {
    setInitialUpload(false);
    const transformWidth = blobs.length * 100;
    const nextSiblings = getNextSiblings(board);
    const boardToAnimate = nextSiblings[blobs.length - 1];
    boardToAnimate?.classList.remove('no-animation');
    boardToAnimate?.classList.add('right');
    boardToAnimate?.style.setProperty('--r', `-${transformWidth}%`);

    setTimeout(() => {
      boardRefFirst?.current?.scrollTo(280, 0);
    }, 600);
  };

  const onUploadRight = (blobs, board) => {
    setInitialUpload(false);

    const transformWidth = blobs.length * 100;
    board?.classList.remove('no-animation');
    board?.classList.add('right');
    board?.style.setProperty('--r', `-${transformWidth}%`);

    const scrollTo = boardRefFirst.current.scrollWidth;
    setTimeout(() => {
      boardRefFirst.current.scroll(scrollTo, 0);
    }, 600);
  };

  const onUpload = (blobs, board, isLeft) => {
    if (isLeft) {
      onUploadLeft(blobs, board);
    } else {
      onUploadRight(blobs, board);
    }
  };

  const renderImages = (boards) => {
    if (!boards) return null;
    return boards.map((board, idx) => {
      const { id, blob, secureUrl, cropData } = board;
      return (
        <Board
          index={idx}
          nema={newBoards?.length}
          setBoardInMenu={setBoardInMenu}
          onRemove={onRemove}
          toggleCropper={toggleCropper}
          key={`boardWithImage-${id}`}
          board={board}
          cropData={cropData}
          blob={blob}
          secure={secureUrl}
          showBoardMenu={showBoardMenu}
        />
      );
    });
  };

  const renderBoardsDesktop = () => (
    <>
      <div
        className="sb-BoardsStrip drag"
        style={{
          overflow: 'auto',
          alignItems: 'center',
          ...(!boards?.length && {
            background: `url(${bg_desktop}) no-repeat center center`,
            backgroundSize: '100% 100%',
          }),
        }}
        ref={boardRefFirst}
      >
        <div
          style={{ height: '100%' }}
          className={`boardsWrapper ${boards ? '' : 'boardsWrapper-noBoards'}`}
        >
          {!boards?.length && (
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
              }}
            >
              <div
                style={{
                  flexBasis: '15%',
                }}
              >
                <div
                  style={{
                    gap: '16px',
                    display: 'flex',
                    padding: '12px',
                    borderRadius: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(255, 255, 255, 0.5)',
                  }}
                >
                  <svg
                    width={35}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 35 35"
                  >
                    <circle cx="17.5" cy="17.5" r="17.5" fill="#E8E8EC" />
                    <path
                      fill="#BF3EFB"
                      d="M24.633 17.97 17.554 10.9a3.066 3.066 0 0 0-1.01-.638A3.06 3.06 0 0 0 15.386 10h-4.12a1.219 1.219 0 0 0-.89.376 1.22 1.22 0 0 0-.376.89v4.119c.011.4.1.793.263 1.158.143.373.36.714.638 1l7.08 7.09a1.212 1.212 0 0 0 .89.367 1.268 1.268 0 0 0 .901-.367l4.861-4.87a1.213 1.213 0 0 0 .367-.89 1.268 1.268 0 0 0-.367-.904Zm-10.567-3.906a1.22 1.22 0 0 1-.897.371 1.268 1.268 0 0 1-.895-2.161 1.268 1.268 0 0 1 1.79 0c.237.237.371.559.371.895a1.223 1.223 0 0 1-.371.895h.002Z"
                    />
                  </svg>
                  <span
                    style={{
                      color: '#284E7B',
                      fontWeight: 700,
                      fontSize: '16px',
                    }}
                  >
                    Up to&nbsp;
                    {highestDiscount
                      ? highestDiscount
                      : coupon?.discount?.percent
                        ? `${coupon?.discount?.percent}`
                        : ''}
                    % <span style={{ color: '#A637FC' }}>OFF!</span>
                  </span>
                </div>
              </div>
              {!boards?.length && (
                <div style={{ flexBasis: '33%' }}>
                  <Board
                    isLeft
                    nema={boards?.length}
                    setBoardInMenu={setBoardInMenu}
                    upload
                    initial={initialUpload}
                    onUpload={(blobs, board) => onUpload(blobs, board, true)}
                  />
                </div>
              )}
              <div style={{ flexBasis: '15%' }} />
            </div>
          )}
          {renderImages(boards)}
        </div>
      </div>
      {!!boards?.length && (
        <div
          style={{
            position: 'relative',
            width: isMobile ? '90%' : '440px',
            alignSelf: 'center',
            flexBasis: '25%',
          }}
        >
          <div
            style={{
              background: '#EAECEE',
              paddingTop: '12px',
              paddingBottom: '12px',
              borderRadius: '16px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                color: '#284E7B',
                fontSize: '16px',
                fontWeight: 600,
                flexBasis: '100%',
              }}
            >
              {topTierDiscount
                ? boardsUpsellMessage
                : coupon?.discount?.percent
                  ? `${coupon?.discount?.percent}% discount awaits!`
                  : ''}
            </span>
            <span style={{ width: '68px' }}></span>
          </div>
          <div
            onClick={onUploadClick}
            style={{
              position: 'absolute',
              right: -28,
              top: -40,
              cursor: 'pointer',
              borderRadius: '100%',
            }}
          >
            <svg
              width="120"
              height="120"
              viewBox="0 0 120 120"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_143_2850)">
                <g filter="url(#filter0_d_143_2850)">
                  <path
                    d="M94 60C94 41.2223 78.7777 26 60 26C41.2223 26 26 41.2223 26 60C26 78.7777 41.2223 94 60 94C78.7777 94 94 78.7777 94 60Z"
                    fill="white"
                  />
                  <path
                    d="M93.3708 59.9996C93.3708 41.5695 78.4302 26.6289 60.0001 26.6289C41.57 26.6289 26.6294 41.5695 26.6294 59.9996C26.6294 78.4297 41.57 93.3703 60.0001 93.3703C78.4302 93.3703 93.3708 78.4297 93.3708 59.9996Z"
                    stroke="#284E7B"
                    stroke-opacity="0.1"
                    stroke-width="1.25862"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M58.5391 58.5342V48.2932C58.5391 47.4852 59.1941 46.8301 60.0021 46.8301C60.8101 46.8301 61.4651 47.4852 61.4651 48.2932V58.5342H71.7062C72.5142 58.5342 73.1692 59.1893 73.1692 59.9972C73.1692 60.8052 72.5142 61.4603 71.7062 61.4603H61.4651V71.7013C61.4651 72.5093 60.8101 73.1644 60.0021 73.1644C59.1941 73.1644 58.5391 72.5093 58.5391 71.7013V61.4603H48.298C47.49 61.4603 46.835 60.8052 46.835 59.9972C46.835 59.1893 47.49 58.5342 48.298 58.5342H58.5391Z"
                    fill="#BF3EFB"
                  />
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_d_143_2850"
                  x="0"
                  y="7"
                  width="120"
                  height="120"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="7" />
                  <feGaussianBlur stdDeviation="13" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_143_2850"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_143_2850"
                    result="shape"
                  />
                </filter>
                <clipPath id="clip0_143_2850">
                  <rect width="120" height="120" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              ref={(ref) => {
                upload = ref;
              }}
              onChange={onFileChange}
            />
          </div>
        </div>
      )}
    </>
  );

  const renderBoards = () => (
    <>
      <div
        className="sb-BoardsStrip"
        style={{
          overflow: 'auto',
          ...(!boards?.length && {
            background: `url(${bg_mobile}) no-repeat center center`,
            backgroundSize: '100% 100%',
          }),
        }}
        ref={boardRefFirst}
      >
        <div
          className={`boardsWrapper ${boards ? '' : 'boardsWrapper-noBoards'}`}
          style={{ height: '100%' }}
        >
          {!boards?.length && (
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
              }}
            >
              <div
                style={{
                  flexBasis: '15%',
                }}
              >
                <div
                  style={{
                    gap: '16px',
                    display: 'flex',
                    padding: '12px',
                    borderRadius: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(255, 255, 255, 0.5)',
                  }}
                >
                  <svg
                    width={35}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 35 35"
                  >
                    <circle cx="17.5" cy="17.5" r="17.5" fill="#E8E8EC" />
                    <path
                      fill="#BF3EFB"
                      d="M24.633 17.97 17.554 10.9a3.066 3.066 0 0 0-1.01-.638A3.06 3.06 0 0 0 15.386 10h-4.12a1.219 1.219 0 0 0-.89.376 1.22 1.22 0 0 0-.376.89v4.119c.011.4.1.793.263 1.158.143.373.36.714.638 1l7.08 7.09a1.212 1.212 0 0 0 .89.367 1.268 1.268 0 0 0 .901-.367l4.861-4.87a1.213 1.213 0 0 0 .367-.89 1.268 1.268 0 0 0-.367-.904Zm-10.567-3.906a1.22 1.22 0 0 1-.897.371 1.268 1.268 0 0 1-.895-2.161 1.268 1.268 0 0 1 1.79 0c.237.237.371.559.371.895a1.223 1.223 0 0 1-.371.895h.002Z"
                    />
                  </svg>
                  <span
                    style={{
                      color: '#284E7B',
                      fontWeight: 700,
                      fontSize: '16px',
                    }}
                  >
                    Up to&nbsp;
                    {highestDiscount
                      ? highestDiscount
                      : coupon?.discount?.percent
                        ? `${coupon?.discount?.percent}`
                        : ''}
                    % <span style={{ color: '#A637FC' }}>OFF!</span>
                  </span>
                </div>
              </div>
              {!boards?.length && (
                <div>
                  <Board
                    isLeft
                    nema={boards?.length}
                    setBoardInMenu={setBoardInMenu}
                    upload
                    initial={initialUpload}
                    onUpload={(blobs, board) => onUpload(blobs, board, true)}
                  />
                </div>
              )}
              <div style={{ flexBasis: '15%' }} />
            </div>
          )}
          {renderImages(boards)}
        </div>
      </div>
      {!!boards?.length && (
        <div
          style={{
            position: 'relative',
            width: isMobile ? '85%' : '50%',
            ...(!isMobile && {
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              alignItems: 'center',
            }),
            alignSelf: 'center',
            flexBasis: '25%',
          }}
        >
          <div
            style={{
              background: '#EAECEE',
              paddingTop: '12px',
              paddingBottom: '12px',
              borderRadius: '12px',
              textAlign: 'center',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                color: '#284E7B',
                fontSize: '15px',
                fontWeight: 600,
                flexBasis: '100%',
              }}
            >
              {topTierDiscount
                ? boardsUpsellMessage
                : coupon?.discount?.percent
                  ? `${coupon?.discount?.percent}% discount awaits!`
                  : ''}
            </span>
            <span style={{ width: '54px' }}></span>
          </div>
          <div
            onClick={onUploadClick}
            style={{
              ...(isMobile && {
                position: 'absolute',
                right: -10,
                top: -10,
              }),
              width: '64px',
              height: '64px',
              borderRadius: '100%',
              backgroundColor: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1.259px solid rgba(40, 78, 123, 0.10)',
            }}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.1877 11.1877V1.39847C11.1877 0.626115 11.8139 0 12.5862 0C13.3586 0 13.9847 0.626115 13.9847 1.39847V11.1877H23.7739C24.5463 11.1877 25.1724 11.8139 25.1724 12.5862C25.1724 13.3586 24.5463 13.9847 23.7739 13.9847H13.9847V23.7739C13.9847 24.5463 13.3586 25.1724 12.5862 25.1724C11.8139 25.1724 11.1877 24.5463 11.1877 23.7739V13.9847H1.39847C0.626115 13.9847 0 13.3586 0 12.5862C0 11.8139 0.626115 11.1877 1.39847 11.1877H11.1877Z"
                fill="#BF3EFB"
              />
            </svg>

            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              ref={(ref) => {
                upload = ref;
              }}
              onChange={onFileChange}
            />
          </div>
        </div>
      )}
    </>
  );

  return (
    <CSSTransition
      timeout={100}
      unmountOnExit
      in={previousFrame === frame}
      classNames={'sb-Board-animation'}
    >
      <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
        <div className={BS_CLASS}>
          <SpecialOfferBanner btext={btext} boards={boards} />
          {isMobile ? renderBoards() : renderBoardsDesktop()}
          {/* {!boards?.length && <span style={{visibility: 'hidden'}} className={`${CLASS}-text`}>Add your PHOTOS ;)</span>} */}
        </div>
      </div>
    </CSSTransition>
  );
};

const SpecialOfferBanner = ({ btext, boards }) => {
  if (!btext) return null;
  return (
    <div
      className={`message${
        boards && boards.length > 2 ? ' message-multiple' : ''
      }`}
    >
      <img src={starStruck} alt="smiley with stars" />
      <p>
        <span className="message-bold">{btext}</span>
      </p>
    </div>
  );
};

export default BoardsStrip;

export const VipModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div
      style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 3333,
        width: '100%',
        height: '100%',
        display: 'flex',
        padding: '24px',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(242, 241, 231, .5)',
      }}
    >
      <div
        style={{
          gap: '16px',
          padding: '24px',
          display: 'flex',
          borderRadius: '12px',
          alignItems: 'center',
          position: 'relative',
          flexDirection: 'column',
          backgroundColor: 'white',
          width: isMobile ? '100%' : '400px',
        }}
      >
        <div
          style={{
            top: '16px',
            right: '16px',
            cursor: 'pointer',
            position: 'absolute',
          }}
          onClick={() => {
            sessionStorage.setItem('vip_modal', 'handled');
            dispatch(updateUser({ showVipModal: false }));
            a_VipModalHandled();
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_2974_1327"
              maskUnits="userSpaceOnUse"
              style={{ maskType: 'alpha' }}
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2974_1327)">
              <path
                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                fill="black"
                fill-opacity="0.8"
              />
            </g>
          </svg>
        </div>
        <span
          style={{
            fontSize: '68px',
          }}
        >
          😎
        </span>
        <div
          style={{
            gap: '16px',
            display: 'flex',
            fontSize: '16px',
            flexDirection: 'column',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
          }}
        >
          <p
            style={{
              margin: 0,
              fontWeight: 700,
              fontSize: '20px',
              textAlign: 'center',
              fontFamily: "'Plus Jakarta Sans', sans-serif",
            }}
          >
            Great news!
          </p>
          <p
            style={{
              margin: 0,
              fontWeight: 500,
              fontSize: '16px',
              fontFamily: "'Plus Jakarta Sans', sans-serif",
            }}
          >
            You are already a VIP member.
          </p>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <p
              style={{
                margin: 0,
                fontWeight: 700,
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
            >
              As a VIP member you are entitled to:
            </p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_2974_1338"
                  maskUnits="userSpaceOnUse"
                  style={{ maskType: 'alpha' }}
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2974_1338)">
                  <path
                    d="M9.54961 18L3.84961 12.3L5.27461 10.875L9.54961 15.15L18.7246 5.97498L20.1496 7.39998L9.54961 18Z"
                    fill="#AD37FD"
                  />
                </g>
              </svg>
              <p
                style={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: '16px',
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                }}
              >
                75% off your order
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_2974_1338"
                  maskUnits="userSpaceOnUse"
                  style={{ maskType: 'alpha' }}
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2974_1338)">
                  <path
                    d="M9.54961 18L3.84961 12.3L5.27461 10.875L9.54961 15.15L18.7246 5.97498L20.1496 7.39998L9.54961 18Z"
                    fill="#AD37FD"
                  />
                </g>
              </svg>
              <p
                style={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: '16px',
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                }}
              >
                Utilize store credit for this purchase
              </p>
            </div>
          </div>
          <p
            style={{
              margin: 0,
              fontWeight: 700,
              fontSize: '16px',
              fontFamily: "'Plus Jakarta Sans', sans-serif",
            }}
          >
            Please log in to receive your 75% discount and activate you store
            credit.
          </p>
        </div>
        <div
          style={{
            gap: '16px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            onClick={() => {
              history.push({ pathname: '/portal', search: '?redirect=/' });
              sessionStorage.setItem('vip_modal', 'handled');
              dispatch(updateUser({ showVipModal: false }));
              a_VipModalHandled();
            }}
            style={{
              color: '#fff',
              padding: '12px',
              fontWeight: 700,
              fontSize: '16px',
              cursor: 'pointer',
              textAlign: 'center',
              borderRadius: '12px',
              backgroundColor: '#AD37FD',
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              background:
                'linear-gradient(90deg, #AD37FD 0%, #C03CFC 104.31%))',
              boxShadow:
                '0px 2px 2px 0px rgba(255, 255, 255, 0.45) inset, 0px -2px 2px 0px rgba(0, 0, 0, 0.10) inset',
            }}
          >
            Log into my account
          </div>
          <p
            style={{
              margin: 0,
              fontWeight: 500,
              fontSize: '14px',
              textAlign: 'center',
              fontFamily: "'Plus Jakarta Sans', sans-serif",
            }}
          >
            Don't stress! After logging in, you will return here to complete
            your order.
          </p>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect, Suspense, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import Countdown, { zeroPad } from 'react-countdown';

import BoardsStrip from '../../containers/boards-strip';
import Footer from '../../components/footer';
import PageWrapper from '../../components/page-wrapper';
import LowQuality from '../../components/low-quality';
import PayPalCheckoutLoader from '../../components/paypal-checkout-loader';
import ErrorStripe from './ErrorStripe';
import BoardMenu from '../../components/board-menu';
import ProcessingOrder from '../../components/processing-order/ProcessingOrder';
import OrderCompleted from '../../components/order-completed/OrderCompleted';
import CheckoutInfo from '../../components/checkout-info';
import CropperMobileWrapper from '../../containers/cropper-mobile-wrapper';
import EmailPopup from '../../components/email-popup/index';
import { getNextSiblings } from '../../../utils/dom';
import toggleChatPopup from '../../../utils/toggleChatPopup';
import { usePrevious } from '../../../utils/hooks';
import { utmMediumChecker } from '../../../utils/envHandler';
import {
  updateApp,
  toggleModal,
  closeModals,
  setFooterVisibility,
} from '../../../redux/app/actions';
import { removeImage } from '../../../redux/boards/actions';
import {
  updateCheckout,
  simulateCheckout,
  showPaymentMenu,
  klarnaLoadingStart,
  klarnaLoadingStop,
  activateKlarna,
} from '../../../redux/checkout/actions';
import { updateUserEmail } from '../../../redux/user/actions';
import {
  a_klaviyoFormClose,
  a_klaviyoFormOpen,
  a_klaviyoFormSubmit,
  a_placeOrderPressed,
  a_upsellShown,
  a_viewBoardsPage,
} from '../../../api/analytics';
import * as events from '../../../utils/events';
import './Boards.scss';
import { readCookie, readExitIntelEmail } from '../../../utils/readCookie';
import { Base64 } from 'js-base64';
import * as storage from '../../../utils/storage';
import FrameSelect from '../../components/frame-select/FrameSelect';
import { SizeSelect } from '../../components/frame-select/SizeSelect';
import { isMobile } from 'react-device-detect';
import { VipModal } from '../../containers/boards-strip/BoardsStrip';

const CLASS = 'sb-Boards';

const Boards = () => {
  const [emailPopup, setEmailPopup] = useState(false);
  const [boardInMenu, setBoardInMenu] = useState();
  const [boardInMenuId, setBoardInMenuId] = useState(null);
  const [gtmEvent, setGtmEvent] = useState(false);
  const [tip, setTip] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const dispatch = useDispatch();

  const { app, user, checkout } = useSelector((state) => state);
  const { coupon } = useSelector((state) => state.coupon);
  const { boards, lowQualityBoards } = useSelector((state) => state.boards);

  const { shippingInfoError } = user;
  const { error } = checkout;

  const previousBoards = usePrevious(boards);
  const enabled = app?.cms?.emailPopup?.enabled;
  const triggerNumber = app?.cms?.emailPopup?.triggerNumber;

  useEffect(() => {
    const url = new URL(window.location);
    const clientSecret = url.searchParams.get('payment_intent_client_secret');
    if (!clientSecret) {
      return;
    }
    dispatch(activateKlarna());
    dispatch(klarnaLoadingStart());
    const fetch = async () => {
      const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      const { paymentIntent, error } =
        await stripe.retrievePaymentIntent(clientSecret);
      if (error) {
        dispatch(klarnaLoadingStop());
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        !checkout.upsell &&
          setTimeout(() => {
            a_upsellShown();
            dispatch(toggleModal({ modal: 'upsell', toggle: true }));
          }, 700);
        dispatch(simulateCheckout());
        dispatch(klarnaLoadingStop());
      } else {
        dispatch(klarnaLoadingStop());
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    document.title = 'Storyboards | Create Boards';
    if (window.location.pathname !== '/gift') {
      document.body.classList.add('body-boards');
      document.querySelector('html').classList.add('body-boards');
    }

    localStorage.setItem('checkoutError', true);

    window.klaviyoFormOpened = false;
    window.klaviyoFormClosed = false;
    window.klaviyoFormSubmitted = false;

    window.addEventListener('klaviyoForms', (event) => {
      const { type } = event.detail;

      if ((type === 'open' || type === 'embedOpen') && !coupon.coupon) {
        !window.klaviyoFormOpened && a_klaviyoFormOpen();
        window.klaviyoFormOpened = true;
      }

      if (type === 'submit') {
        !window.klaviyoFormSubmitted && a_klaviyoFormSubmit();
        window.klaviyoFormSubmitted = true;

        const firstCookie = readCookie('__kla_id');
        const firstCookieParsed = JSON.parse(Base64.decode(firstCookie));
        const secondCookie = readExitIntelEmail('exitintel_email');

        const klaviyoEmail = firstCookieParsed?.$email || secondCookie;
        klaviyoEmail && updateUserEmail();
        storage.set(storage.names.klaviyoEmail, klaviyoEmail);
      }

      if (type === 'close') {
        !window.klaviyoFormClosed && a_klaviyoFormClose();
        window.klaviyoFormClosed = true;
      }
    });

    window.snaptr('track', 'PAGE_VIEW', { page: 'Boards' });

    return () => {
      document.body.classList.remove('body-boards');
      document.querySelector('html').classList.remove('body-boards');

      dispatch(toggleModal({ modal: 'addCoupon', toggle: false }));
    };
  }, []);

  useEffect(() => {
    if (!gtmEvent && app.priceOne) {
      setGtmEvent(true);
      a_viewBoardsPage();
    }
  }, [boards, gtmEvent, app, user]);

  useEffect(() => {
    let popupTimer;

    if (
      enabled &&
      (!previousBoards || previousBoards.length < triggerNumber) &&
      boards?.length >= triggerNumber &&
      !user.klaviyoEmail &&
      !localStorage.getItem('popup_displayed') &&
      !utmMediumChecker()
    ) {
      popupTimer = setTimeout(() => {
        localStorage.setItem('popup_displayed', true);
        setEmailPopup(true);
      }, 3000);
    }

    return () => {
      if (popupTimer) clearTimeout(popupTimer);
    };
  }, [boards?.length, user.klaviyoEmail]);

  const getCtaType = (app, boards, checkout, shippingInfoError) => {
    const { smallAmount, minBoards } = app;
    const {
      applePayActive,
      paypalActive,
      orderPrice,
      applePaySelected,
      klarnaActive,
    } = checkout;
    const cantProceed =
      shippingInfoError ||
      shippingInfoError === null ||
      !boards ||
      boards.length < minBoards;
    const freeBtn =
      orderPrice <= smallAmount && boards && boards.length >= minBoards;
    const applePay =
      !freeBtn && applePayActive && applePaySelected && !cantProceed;
    const fakeApplePay =
      !freeBtn &&
      !paypalActive &&
      applePayActive &&
      applePaySelected &&
      cantProceed;
    const paypal = !freeBtn && paypalActive && !cantProceed;
    const fakePaypal = !freeBtn && paypalActive && cantProceed;
    const klarna = !freeBtn && klarnaActive && !cantProceed;
    const fakeKlarna = !freeBtn && klarnaActive && cantProceed;
    const placeOrder =
      !paypal && !fakePaypal && !applePay && !fakeApplePay && !klarnaActive;
    const btnType = {
      free: freeBtn,
      applePay,
      fakeApplePay,
      paypal,
      fakePaypal,
      placeOrder,
      klarna,
      fakeKlarna,
    };
    return btnType;
  };

  const btnType = getCtaType(app, boards, checkout, shippingInfoError);

  const handleCheckout = () => {
    const { error, orderPrice, paypalActive, klarnaActive } = checkout;
    const canCheckout = allFieldsAreValid(user);

    a_placeOrderPressed(canCheckout, app.remarketingUrl);

    if (boards?.length && (!user.fullName || user.shippingInfoError)) {
      dispatch(updateCheckout({ addressInfoOpen: true }));
    } else if (!boards || boards.length < app.minBoards) {
      toggleTip();
    } else if (error && orderPrice) {
      dispatch(showPaymentMenu());
    } else if (canCheckout && !paypalActive && !klarnaActive) {
      dispatch(simulateCheckout());
      !checkout.upsell &&
        setTimeout(() => {
          a_upsellShown();
          dispatch(toggleModal({ modal: 'upsell', toggle: true }));
        }, 700);
    } else if (!paypalActive && !klarnaActive) {
      dispatch(updateCheckout({ isCheckoutOpen: true }));
      dispatch(closeModals());
    }
  };

  const allFieldsAreValid = () => {
    const { orderPrice } = checkout;

    if (shippingInfoError === true || shippingInfoError === null) {
      return false;
    } else if (coupon?.discountPercentage === 100) {
      return true;
    } else if (checkout.error && orderPrice > 0) {
      return false;
    } else if (orderPrice === 0 && boards && boards.length >= app.minBoards) {
      dispatch(updateCheckout({ error: false }));
      return true;
    } else if (checkout.error) {
      return false;
    } else {
      return true;
    }
  };

  const toggleCheckout = (toggle) => {
    const { isCheckoutOpen, addressInfoOpen } = checkout;
    const isOpen = toggle ? !isCheckoutOpen : false;
    !addressInfoOpen &&
      dispatch(
        updateCheckout({ isCheckoutOpen: isOpen, addressInfoOpen: false }),
      );
  };

  const toggleCropper = () => {
    dispatch(setFooterVisibility(app.modals.cropper));
    dispatch(updateApp({ modals: { cropper: !app.modals.cropper } }));
  };

  const toggleTip = () => {
    setTip(true);
    setTimeout(() => setTip(false), 500);
  };

  const handleSetBoardInMenu = (boardId, board) => {
    setBoardInMenu(board);
    setBoardInMenuId(boardId);
    setShowMenu(true);
    isMobile &&
      dispatch(updateApp({ modals: { material: false, size: false } }));
  };

  const onRemove = () => {
    events.onRemove();

    boardInMenu.classList.remove('no-animation');
    boardInMenu.classList.add('disappear');

    const nextBoards = getNextSiblings(boardInMenu);
    setTimeout(() => {
      nextBoards.forEach((nextBoard) => {
        nextBoard.classList.remove('no-animation');
        nextBoard.classList.add('left');
      });
      dispatch(removeImage(boardInMenuId));
    }, 600);

    setBoardInMenu(null);
    setBoardInMenuId(null);
  };

  const renderLowQualityMenu = () => {
    if (!lowQualityBoards || !lowQualityBoards.length) {
      return null;
    }
    toggleChatPopup(false, 'renderLowQualityMenu');
    return <LowQuality />;
  };

  const renderPayPalCheckout = () => {
    if (!checkout.paypalCheckout) return null;
    return <PayPalCheckoutLoader />;
  };

  const handleSetBoardMenu = (toggle) =>
    setShowMenu(toggle !== undefined ? toggle : false);

  return (
    <div className={CLASS}>
      <CropperMobileWrapper toggleCropper={toggleCropper} />
      {user.showVipModal ? <VipModal /> : null}
      <EmailPopup
        show={emailPopup}
        setShow={(show) => {
          setEmailPopup(show);
        }}
      />
      {checkout.checkingout ? (
        <ProcessingOrder />
      ) : checkout.checkedout ? (
        <OrderCompleted />
      ) : error?.message && error.message.startsWith('(token)') ? (
        <ErrorStripe />
      ) : (
        <>
          <PageWrapper>
            {!!boards?.length && <FrameSelect />}
            {!!boards?.length && <SizeSelect />}
            <BoardsStrip
              setBoardInMenu={handleSetBoardInMenu}
              onRemove={onRemove}
              toggleCropper={toggleCropper}
            />
            {boards?.length ? <>{coupon?.metadata?.otext && <></>}</> : <></>}
            {!!boards?.length && (
              <Footer
                toggleCheckout={toggleCheckout}
                btnType={btnType}
                handleCheckout={handleCheckout}
              />
            )}
          </PageWrapper>
          <CSSTransition
            unmountOnExit
            in={showMenu}
            classNames="sb-BoardMenu-animation"
            timeout={300}
          >
            <div
              className={'sb-BoardMenu-backdrop'}
              onClick={() => {
                handleSetBoardMenu(false);
                toggleChatPopup(true, 'backdrop click');
              }}
            />
          </CSSTransition>
          <CSSTransition
            unmountOnExit
            in={showMenu}
            classNames={'sb-BoardMenu-mobile-animation'}
            timeout={300}
          >
            <BoardMenu
              onRemove={onRemove}
              key={1}
              show={showMenu}
              setShow={handleSetBoardMenu}
              board={boardInMenu}
            />
          </CSSTransition>
          {renderPayPalCheckout()}
          <Suspense fallback={<div>Loading...</div>}>
            <CheckoutInfo
              tip={tip}
              btnType={btnType}
              handleCheckout={handleCheckout}
            />
          </Suspense>
          {renderLowQualityMenu()}
        </>
      )}
    </div>
  );
};

export default Boards;

export const u_returnFirstFrame = (frames) => {
  const frameExists = frames.find(
    (frame) =>
      frame.img === localStorage.getItem('selectedFrame') && frame.isShown,
  );
  const lsOrBackendFrame =
    frameExists?.img || frames.filter((frame) => frame.isSelected)[0]?.img;

  return lsOrBackendFrame;
};

export const u_returnFirstSize = () => {
  const size = localStorage.getItem('selectedSize') || '8';
  return size;
};

import { store as reduxStore } from '../../app/App';

export const getPrice = () => {
	let reduxApp = reduxStore.getState().app;
	return {
		singleBoard: reduxApp.priceOne,
		bundle: reduxApp.priceThree,
	};
};

export const roundTwo = num => Math.round((num + Number.EPSILON) * 100) / 100;

export const subtractPercent = (num, percent) => (num * (100 - percent)) / 100;

export const getPriceBDisc = (price, percent) => ({
	singleBoard: subtractPercent(price.singleBoard, percent),
	bundle: subtractPercent(price.bundle, percent),
});

export const getBaseAmount = (numOfBoards, price) => numOfBoards * price.singleBoard * 100;

export const Small = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 175 109"
    style={{
      width: props.isMobile ? '100px' : '136px',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    }}
  >
    <g clip-path="url(#a3)">
      <path
        fill="#EEE"
        fill-opacity=".4"
        d="M0 12C0 5.373 5.373 0 12 0h151c6.627 0 12 5.373 12 12v97H0V12Z"
      />
      <g filter="url(#b3)">
        <path
          fill="url(#c3)"
          fill-opacity=".2"
          d="m122 161.234 12.358-7.548L159 100.564l-7.888-2.33-9.193 19.579-12.449 25.975-7.47 17.446Z"
        />
      </g>
      <path
        stroke={props.active ? '#A637FC' : '#284E7B'}
        d="M63.5 30.5h49v49h-49z"
        opacity=".5"
      />
      <g filter="url(#d3)" opacity=".8">
        <path
          fill={props.active ? '#A637FC' : '#284E7B'}
          d="M87.728 63.258c-1.204 0-2.357-.158-3.46-.473-1.104-.33-1.993-.752-2.666-1.268l1.182-2.623c.645.459 1.404.839 2.278 1.14.889.286 1.785.43 2.687.43.688 0 1.24-.065 1.656-.194.43-.143.745-.337.945-.58.2-.244.301-.523.301-.838 0-.402-.157-.717-.473-.946-.315-.244-.73-.437-1.246-.58-.516-.158-1.09-.302-1.72-.43a24.406 24.406 0 0 1-1.87-.516 7.983 7.983 0 0 1-1.698-.774 3.903 3.903 0 0 1-1.268-1.247c-.316-.516-.473-1.175-.473-1.977 0-.86.23-1.641.688-2.343.473-.717 1.175-1.283 2.106-1.699.946-.43 2.128-.644 3.547-.644.946 0 1.877.114 2.794.343.917.215 1.727.545 2.43.99l-1.076 2.643a8.792 8.792 0 0 0-2.106-.881 7.487 7.487 0 0 0-2.064-.301c-.673 0-1.225.079-1.655.236-.43.158-.738.366-.924.624-.186.243-.28.53-.28.86 0 .386.158.702.473.945.316.23.731.416 1.247.56.516.142 1.082.286 1.698.43.63.142 1.254.307 1.87.493a7.18 7.18 0 0 1 1.72.753c.516.315.931.73 1.247 1.247.33.515.494 1.167.494 1.956 0 .845-.236 1.619-.71 2.321-.472.702-1.182 1.268-2.127 1.698-.932.43-2.114.645-3.547.645Z"
        />
      </g>
    </g>
    <defs>
      <filter
        id="b3"
        width="38.34"
        height="64.34"
        x="121.33"
        y="97.564"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_1140_1034"
          stdDeviation=".335"
        />
      </filter>
      <filter
        id="d3"
        width="151.384"
        height="154.436"
        x="12.165"
        y="16.983"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="38.724" />
        <feGaussianBlur stdDeviation="34.718" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.542688 0 0 0 0 0.303116 0 0 0 0 0.729022 0 0 0 0.17 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1140_1034"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1140_1034"
          result="shape"
        />
      </filter>
      <linearGradient
        id="c3"
        x1="120.276"
        x2="137.371"
        y1="154.256"
        y2="147.993"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset=".578" stop-color="#D9D9D9" stop-opacity="0" />
      </linearGradient>
      <clipPath id="a3">
        <path
          fill="#fff"
          d="M0 12C0 5.373 5.373 0 12 0h151c6.627 0 12 5.373 12 12v97H0V12Z"
        />
      </clipPath>
    </defs>
  </svg>
);

import * as TYPES from './action-types';
import ExpiredStorage from 'expired-storage';
import axios from 'axios';
import { post } from '../../utils/http';
import { a_voucherifyCoupon } from '../../api/analytics/index';
import * as storage from '../../utils/storage';
import { updateApp } from '../../redux/app/actions';
import { updateCheckout } from '../checkout/actions';

let expiredStorage = new ExpiredStorage();

const setCoupon = coupon => {
	expiredStorage.setJson(storage.names.coupon, coupon, 86400);
};

export const getCoupon = () => storage.get(storage.names.coupon);
const getVoucher = () => expiredStorage.getJson(storage.names.voucher);

// ----- INITIALIZE COUPON -----

const initializeStart = () => {
	return {
		type: TYPES.INITIALIZE_COUPON_START,
	};
};

const initializeError = error => {
	return {
		type: TYPES.INITIALIZE_COUPON_ERROR,
		payload: error,
	};
};

const initializeEnd = coupon => {
	return {
		type: TYPES.INITIALIZE_COUPON_END,
		payload: coupon,
	};
};

const resetCurrency = () => {
	return {
		type: TYPES.RESET_CURRENCY,
	};
};

export const initializeCoupon = () => {
	return dispatch => {
		dispatch(initializeStart());
		try {
			const coupon = getVoucher() || getCoupon();
			if (!coupon) {
				dispatch(validateCoupon('Default'));
			}
			dispatch(initializeEnd(coupon));
		} catch (error) {
			dispatch(initializeError(error));
		}
	};
};

// ---- VALIDATE COUPON ----

const validateStart = () => {
	return {
		type: TYPES.VALIDATE_COUPON_START,
	};
};

const validateError = error => {
	return {
		type: TYPES.VALIDATE_COUPON_ERROR,
		payload: error,
	};
};

const validateEnd = data => {
	return {
		type: TYPES.VALIDATE_COUPON_END,
		payload: data,
	};
};

export const validateCoupon = (couponName = 'Default', utmParams) => {
	return dispatch => {
		dispatch(validateStart());
		axios
			.get(`/api/coupon/getFromName?name=${couponName}`)
			.then(({ data }) => {
				const coupon = data.data;
				const { base } = coupon;
				if (base) {
					base.utmParams = utmParams;
				}
				if (couponName === 'NextOrder' || couponName === 'Popup') {
					setCoupon(base);
					dispatch(validateEnd(base));
				} else if (base) {
					base.isEntered = false;
					dispatch(vouchEnd(base));
					a_voucherifyCoupon(base);
					setCoupon(base);
				} else {
					setCoupon(coupon);
					dispatch(validateEnd(coupon));
				}
				if (base.name.startsWith('CARDV')) {
					dispatch(updateCheckout({
						oneTimeOrSubscription: 'one-time'
					}))
				}
			})
			.catch(error => {
				dispatch(validateError(error));
			});
	};
};

// ---- CLEAR COUPON ----
export const clearCoupon = () => {
	localStorage.removeItem(storage.names.coupon);
	localStorage.removeItem(storage.names.voucher);
	return {
		type: TYPES.CLEAR_COUPON,
	};
};

// After removing coupon / finishing checkout that changes board price this should be run to reset price
export const restorePrice = ({ minBoards, priceOne }) => {
	return dispatch => {
		dispatch(updateApp({ minBoards, priceOne }));
		dispatch(resetCurrency());
	};
};

// ---- GET VOUCHERIFY COUPON ----

const getVoucherifyStart = () => {
	return {
		type: TYPES.GET_VOUCHERIFY_START,
	};
};

const getVoucherifyError = error => {
	return {
		type: TYPES.GET_VOUCHERIFY_ERROR,
		payload: error,
	};
};

const getVoucherifyEnd = data => {
	return {
		type: TYPES.GET_VOUCHERIFY_END,
		payload: data,
	};
};

const vouchEnd = (data) => {
	return (dispatch, getState) => {
		const state = getState();
		dispatch(getVoucherifyEnd(data));
		if (data.name.startsWith('CARDV')) {
			dispatch(updateCheckout({
				oneTimeOrSubscription: 'one-time'
			}))
		}
		if (data.metadata.boards_price) {
			dispatch(updateApp({ priceOne: data.metadata.boards_price }));
		}
		if (data.metadata.min_boards) {
			dispatch(updateApp({ minBoards: data.metadata.min_boards }));
		} else {
			dispatch(updateApp({ minBoards: state.app.originalMinBoards }));
		}
	};
};

export const getVoucherify = (code, isEntered) => {
	return dispatch => {
		dispatch(getVoucherifyStart());
		post('/coupon/voucher', null, { code })
			.then(response => {
				const { base, msg, error } = response;
				if (error) {
					dispatch(getVoucherifyError(msg));
				} else {
					base.isEntered = isEntered;
					dispatch(vouchEnd(base));
					a_voucherifyCoupon(base);
					setCoupon(base);
				}
			})
			.catch(err => {
				dispatch(getVoucherifyError(err));
			});
	};
};

import { detect } from 'detect-browser';

import { post } from './http';
import { store as reduxStore } from '../app/App';

export const logAny = data => {
	return;
};

export const onInfo = (remarketingId, email) => {
	logAny({
		remarketingId,
		events: {
			email: email || '', // We still don't have email
			browserInfo: detect(),
		},
	});
};

export const onUploadClick = () => {
	logAny({
		events: {
			isUploadButtonPressed: true,
		},
		incButtonPressed: true,
	});
};

export const onUploadDone = () => {
	logAny({ incUploaded: true, incAmount: 1 });
};

export const onUploadFail = () => {
	logAny({ incFailedUpload: true });
};

export const onReuploadDone = () => {
	logAny({ incUploaded: true, incAmount: 1 });
};

export const onRemove = () => {
	logAny({ incRemoved: true });
};

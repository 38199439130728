import { getPrice, getPriceBDisc, getBaseAmount, roundTwo } from './price';
import applyCoupon, { checkCouponType, getTierValues } from './coupons';
import { currencyFormatter } from '../../views/components/upsell';

const getBlueText = (discount, priceWithBDisc) => {
  if (!discount?.couponType || !discount?.nextTier) return;
  const { BUND } = discount.couponType;
  const TPER = discount.couponType['3TPER'];
  const { minBoardsDisc } = discount;
  const { singleBoard } = priceWithBDisc;
  const { addMore, freeLeft, nextTier } = discount;
  const { tierSize, tierPrice } = getTierValues(discount, nextTier.hierarchy);
  const boardsBundle = minBoardsDisc || tierSize || 0;
  const bundlePrice = tierPrice || boardsBundle * singleBoard - discount.amount;
  let blueText = '';
  if (addMore) {
    if (discount.free)
      blueText = `Add ${addMore} more board${addMore > 1 ? 's' : ''} and get ${
        discount.free
      } FREE`;
    else if (TPER && discount.percent) {
      blueText = `Add ${addMore} board${addMore > 1 ? 's' : ''} and save ${
        discount.coupon.metadata.level_2_value
      }% off.`;
    } else if (discount.amount)
      blueText = `Add ${addMore} more board${
        addMore > 1 ? 's' : ''
      } and get ${boardsBundle} for $${bundlePrice}`;
  } else if (discount.addAmount) {
    blueText = `Spend $${discount.addAmount} more and get ${discount.percent}% off`;
  } else if (typeof freeLeft === 'number') {
    blueText = `Congratulations, add ${freeLeft} for FREE`;
  }
  discount.blueText = BUND || TPER ? blueText : null;
};

const calculatePrice = (
  boards,
  coupon,
  framePrice = 0,
  oneTimeOrSubscription,
  subscriptions,
  selectedSize,
  selectedFrame,
) => {
  const [activeSubscription] = subscriptions.filter(
    (subscription) => subscription.isDefault,
  );

  const {
    json,
    charge,
    discountRule,
    discount: subscriptionDiscount,
  } = activeSubscription;

  let topTierDiscount;
  const price = getPrice();
  let boardsUpsellMessage = '';
  const boardsLength = boards?.length ?? 0;

  if (coupon?.override) {
    const { override } = coupon;
    const sku = `${selectedFrame}-${selectedSize}`;
    const tiers = override[sku];
    if (tiers?.tiers?.length) {
      for (const [index, rule] of tiers?.tiers.entries()) {
        const { discount, tierBoardsLimit } = rule;
        const realBoardsLimit = tierBoardsLimit + 1;
        if (boardsLength < realBoardsLimit) {
          coupon.b_disc = discount;
          coupon.metadata.b_disc = discount;
          if (index === tiers?.tiers?.length - 1) {
            boardsUpsellMessage = `Your ${discount}% discount awaits`;
          } else {
            boardsUpsellMessage = tiers?.tiers[index + 1]?.upsellMessage
              ?.replace('$amount', realBoardsLimit - boardsLength)
              .replace(
                '$tile',
                realBoardsLimit - boardsLength > 1 ? 'tiles' : 'tile',
              )
              .replace('$percentage', tiers?.tiers[index + 1]?.discount);
          }
          coupon.discountPercentage = discount;
          coupon.discount.percent = discount;
          break;
        } else {
          if (index < tiers?.tiers?.length) {
            continue;
          }
        }
      }
      topTierDiscount = tiers?.tiers[tiers?.tiers?.length - 1].discount;
    }
  }

  price.singleBoard = price.singleBoard + framePrice;

  const b_disc = coupon && coupon.b_disc ? coupon.b_disc : 0;
  const priceWithBDisc = getPriceBDisc(price, b_disc);
  const originalAmount = getBaseAmount(boardsLength, price);
  const amountWithBDisc = getBaseAmount(boardsLength, priceWithBDisc);
  let amountInCents = originalAmount;
  let originalPrice = amountInCents / 100;
  let discount = null;
  if (coupon && coupon.discount) {
    discount = {
      amount: coupon.discount.amount,
      percent: coupon.discount.percent || coupon.discountPercentage,
      free: coupon.discount.free,
    };
  } else if (coupon) {
    discount = {
      percent: coupon.discountPercentage,
    };
  }
  if (coupon && coupon.gift) {
    discount.gift = coupon.gift.balance;
  }
  if (coupon) {
    if (!coupon.valid) {
      return null;
    }
    const { metadata = {} } = coupon;
    Object.assign(discount, {
      metadata,
      coupon,
      originalAmount,
      amountWithBDisc,
      b_disc,
      price,
      boardsLength,
      couponType: checkCouponType(metadata.type),
      text: coupon.text,
      ctext: metadata.ctext || coupon.name,
      btext: metadata.btext,
      applied: false,
      ...coupon.validFor,
    });

    amountInCents = applyCoupon(amountInCents, coupon, discount);

    getBlueText(discount, priceWithBDisc);
  }
  let totalPrice = amountInCents / 100;
  if (discount) {
    discount.amount = discount.amount / 100;
    if (discount.gift) {
      let saveAmount = roundTwo((originalPrice * discount.percent) / 100);
      discount.gift = discount.gift / 100;
      discount.saveAmountDefault = saveAmount;
    }
    discount.discountAmount = discount.amount;
    discount.discountPercent = discount.percent || b_disc;
    discount.couponAmount = coupon.discount?.amount ?? 0;
  }
  if (coupon && !discount.applied) {
    amountInCents = (amountInCents * (100 - b_disc)) / 100;
    totalPrice = amountInCents / 100;
    const saveAmount = originalPrice - totalPrice;
    discount.saveAmount = roundTwo(saveAmount);
    discount.savePercent = Math.round(
      (discount.saveAmount / originalPrice) * 100,
    );
  }
  amountInCents = Math.max(0, amountInCents);
  totalPrice = Math.max(0, totalPrice);
  originalPrice = discount?.originalPrice ?? originalPrice;

  if (totalPrice < price.smallAmount) {
    amountInCents = 0;
  }

  let offerMessage = '';
  let saveTextPrice = 0;
  let vipBoardPrice = '';
  let totalWithSubscription = 0;
  let shouldUseCustomtext = false;
  const materialString = `${selectedFrame}-${selectedSize}`;
  const selectedMaterial = JSON.parse(json)[materialString];
  let textPerMaterial = selectedMaterial?.text;
  let offerPerMaterial = selectedMaterial?.offer;
  const amountOrPercentagePerMaterial = selectedMaterial?.price;

  if (amountOrPercentagePerMaterial) {
    shouldUseCustomtext = true;
    const isPercentage = String(amountOrPercentagePerMaterial).endsWith('%');
    if (isPercentage) {
      saveTextPrice = currencyFormatter(
        totalPrice -
          (originalPrice -
            originalPrice *
              (amountOrPercentagePerMaterial.split('%')[0] / 100)),
        'USD',
        2,
      );
      totalWithSubscription =
        originalPrice -
        originalPrice * (amountOrPercentagePerMaterial.split('%')[0] / 100);
    } else {
      saveTextPrice = currencyFormatter(
        totalPrice - amountOrPercentagePerMaterial * boards?.length,
        'USD',
        2,
      );
      vipBoardPrice = amountOrPercentagePerMaterial;
      totalWithSubscription = amountOrPercentagePerMaterial * boards?.length;
    }
  } else {
    const isPercentage = subscriptionDiscount > 0;
    if (isPercentage) {
      saveTextPrice = currencyFormatter(
        totalPrice -
          (originalPrice - originalPrice * (subscriptionDiscount / 100)),
        'USD',
        2,
      );
      totalWithSubscription =
        originalPrice - originalPrice * (subscriptionDiscount / 100);
    } else {
      const parsedDiscountRule = JSON.parse(discountRule);
      for (const rule of parsedDiscountRule.rules) {
        const {
          offer,
          message,
          targetLowPrice,
          targetHighPrice,
          discountAmount,
        } = rule;
        if (totalPrice >= targetLowPrice && totalPrice <= targetHighPrice) {
          saveTextPrice =
            totalPrice > discountAmount
              ? currencyFormatter(discountAmount, 'USD', 2)
              : currencyFormatter(totalPrice - 0.99, 'USD', 2);
          totalWithSubscription =
            totalPrice > discountAmount ? totalPrice - discountAmount : 0.99;
          textPerMaterial = message;
          shouldUseCustomtext = true;
          offerMessage = offer;
          break;
        } else {
          continue;
        }
      }
    }
  }

  const result = {
    discount,
    totalPrice,
    boardsLength,
    originalPrice,
    amountInCents,
    saveTextPrice,
    topTierDiscount,
    textPerMaterial,
    shouldUseCustomtext,
    boardsUpsellMessage,
    totalWithSubscription,
    offerMessage,
    offerPerMaterial,
    vipBoardPrice,
  };

  return result;
};

export const calculatePriceForSize = (
  boards,
  coupon,
  framePrice = 0,
  oneTimeOrSubscription,
  subscriptions,
  selectedSize,
  selectedFrame,
) => {
  const [activeSubscription] = subscriptions.filter(
    (subscription) => subscription.isDefault,
  );

  const {
    json,
    charge,
    discountRule,
    discount: subscriptionDiscount,
  } = activeSubscription;

  let topTierDiscount;
  const price = getPrice();
  let boardsUpsellMessage = '';
  const boardsLength = boards?.length ?? 0;

  if (coupon?.override) {
    const { override } = coupon;
    const sku = `${selectedFrame}-${selectedSize}`;
    const tiers = override[sku];
    if (tiers?.tiers?.length) {
      for (const [index, rule] of tiers?.tiers.entries()) {
        const { discount, tierBoardsLimit } = rule;
        const realBoardsLimit = tierBoardsLimit + 1;
        if (boardsLength < realBoardsLimit) {
          coupon.b_disc = discount;
          coupon.metadata.b_disc = discount;
          if (index === tiers?.tiers?.length - 1) {
            boardsUpsellMessage = `Your ${discount}% discount awaits`;
          } else {
            boardsUpsellMessage = tiers?.tiers[index + 1]?.upsellMessage
              ?.replace('$amount', realBoardsLimit - boardsLength)
              .replace(
                '$tile',
                realBoardsLimit - boardsLength > 1 ? 'tiles' : 'tile',
              )
              .replace('$percentage', tiers?.tiers[index + 1]?.discount);
          }
          coupon.discountPercentage = discount;
          coupon.discount.percent = discount;
          break;
        } else {
          if (index < tiers?.tiers?.length) {
            continue;
          }
        }
      }
      topTierDiscount = tiers?.tiers[tiers?.tiers?.length - 1].discount;
    }
  }

  price.singleBoard = price.singleBoard + framePrice;

  const b_disc = coupon && coupon.b_disc ? coupon.b_disc : 0;
  const priceWithBDisc = getPriceBDisc(price, b_disc);
  const originalAmount = getBaseAmount(boardsLength, price);
  const amountWithBDisc = getBaseAmount(boardsLength, priceWithBDisc);
  let amountInCents = originalAmount;
  let originalPrice = amountInCents / 100;
  let discount = null;
  if (coupon && coupon.discount) {
    discount = {
      amount: coupon.discount.amount,
      percent: coupon.discount.percent || coupon.discountPercentage,
      free: coupon.discount.free,
    };
  } else if (coupon) {
    discount = {
      percent: coupon.discountPercentage,
    };
  }
  if (coupon && coupon.gift) {
    discount.gift = coupon.gift.balance;
  }
  if (coupon) {
    if (!coupon.valid) {
      return null;
    }
    const { metadata = {} } = coupon;
    Object.assign(discount, {
      metadata,
      coupon,
      originalAmount,
      amountWithBDisc,
      b_disc,
      price,
      boardsLength,
      couponType: checkCouponType(metadata.type),
      text: coupon.text,
      ctext: metadata.ctext || coupon.name,
      btext: metadata.btext,
      applied: false,
      ...coupon.validFor,
    });

    amountInCents = applyCoupon(amountInCents, coupon, discount);

    getBlueText(discount, priceWithBDisc);
  }

  let totalPrice = discount && discount.sizeAmount / 100;
  if (discount) {
    discount.amount = discount.amount / 100;
    if (discount.gift) {
      let saveAmount = roundTwo((originalPrice * discount.percent) / 100);
      discount.gift = discount.gift / 100;
      discount.saveAmountDefault = saveAmount;
    }
    discount.discountAmount = discount.amount;
    discount.discountPercent = discount.percent || b_disc;
    discount.couponAmount = coupon.discount?.amount ?? 0;
  }
  if (coupon && !discount.applied) {
    amountInCents = (amountInCents * (100 - b_disc)) / 100;
    totalPrice = amountInCents / 100;
    const saveAmount = originalPrice - totalPrice;
    discount.saveAmount = roundTwo(saveAmount);
    discount.savePercent = Math.round(
      (discount.saveAmount / originalPrice) * 100,
    );
  }
  amountInCents = Math.max(0, amountInCents);
  totalPrice = Math.max(0, totalPrice);
  originalPrice = discount?.originalPrice ?? originalPrice;

  if (totalPrice < price.smallAmount) {
    amountInCents = 0;
  }

  let saveTextPrice = 0;
  let totalWithSubscription = 0;
  let shouldUseCustomtext = false;
  const materialString = `${selectedFrame}-${selectedSize}`;
  const selectedMaterial = JSON.parse(json)[materialString];
  let textPerMaterial = selectedMaterial?.text;
  const amountOrPercentagePerMaterial = selectedMaterial?.price;

  if (amountOrPercentagePerMaterial) {
    shouldUseCustomtext = true;
    const isPercentage = String(amountOrPercentagePerMaterial).endsWith('%');
    if (isPercentage) {
      saveTextPrice = currencyFormatter(
        totalPrice -
          (originalPrice -
            originalPrice *
              (amountOrPercentagePerMaterial.split('%')[0] / 100)),
        'USD',
        2,
      );
      totalWithSubscription =
        originalPrice -
        originalPrice * (amountOrPercentagePerMaterial.split('%')[0] / 100);
    } else {
      saveTextPrice = currencyFormatter(
        totalPrice - amountOrPercentagePerMaterial * boards?.length,
        'USD',
        2,
      );
      totalWithSubscription = amountOrPercentagePerMaterial * boards?.length;
    }
  } else {
    const isPercentage = subscriptionDiscount > 0;
    if (isPercentage) {
      saveTextPrice = currencyFormatter(
        totalPrice -
          (originalPrice - originalPrice * (subscriptionDiscount / 100)),
        'USD',
        2,
      );
      totalWithSubscription =
        originalPrice - originalPrice * (subscriptionDiscount / 100);
    } else {
      const parsedDiscountRule = JSON.parse(discountRule);
      for (const rule of parsedDiscountRule.rules) {
        const { message, targetLowPrice, targetHighPrice, discountAmount } =
          rule;
        if (totalPrice >= targetLowPrice && totalPrice <= targetHighPrice) {
          saveTextPrice =
            totalPrice > discountAmount
              ? currencyFormatter(discountAmount, 'USD', 2)
              : (totalPrice - 0.99).toFixed(2);
          totalWithSubscription =
            totalPrice > discountAmount ? totalPrice - discountAmount : 0.99;
          textPerMaterial = message;
          shouldUseCustomtext = true;
          break;
        } else {
          continue;
        }
      }
    }
  }

  const result = {
    // discount,
    totalPrice,
    // boardsLength,
    originalPrice,
    // amountInCents,
    // saveTextPrice,
    // topTierDiscount,
    // textPerMaterial,
    // shouldUseCustomtext,
    // boardsUpsellMessage,
    // totalWithSubscription,
  };

  return result;
};

export default calculatePrice;

import React from 'react';
import { useSelector } from 'react-redux';
import { Elements } from 'react-stripe-elements';

import { FakeBtn } from './CheckoutComponents';
import ApplePayButton from '../../../views/components/apple-pay-button';
import { applePayAvailable } from '../../../utils/envHandler';

const ApplePay = ({ btnType }) => {
  const { applePay, fakeApplePay } = btnType;
  const { user, checkout } = useSelector((state) => state);
  const { totalPrice, shippingCost } = checkout;
  if (!checkout.isCheckoutOpen) return null;
  return applePay ? (
    <Elements>
      <ApplePayButton
        price={totalPrice + shippingCost}
        setShowApplePay={() => {}}
      />
    </Elements>
  ) : fakeApplePay && applePayAvailable() ? (
    <FakeBtn user={user} type="applepay" />
  ) : null;
};

export const ApplePayNew = () => {
  const { checkout } = useSelector((state) => state);
  const { totalPrice, shippingCost } = checkout;
  if (!checkout.isCheckoutOpen) return null;
  return applePayAvailable() ? (
    <Elements>
      <ApplePayButton
        price={totalPrice + shippingCost}
        setShowApplePay={() => {}}
      />
    </Elements>
  ) : null;
};

export default ApplePay;

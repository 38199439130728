import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { checkoutActions } from '../../../redux/checkout';
import { checkoutImages } from '../../../lib/images';
import { applePayAvailable } from '../../../utils/envHandler';

const CLASS = 'sb-CheckoutInfo';

const SelectPayment = ({ freeButton, price1, checkout, ...props }) => {
  const [menu, showMenu] = useState(false);

  const innerRef = useRef(null);

  const handleClickOutside = (e) => {
    if (!innerRef.current.contains(e.target)) {
      showMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const {
    cardBrand,
    cardLast4,
    applePayActive,
    paypalActive,
    applePaySelected,
    klarnaActive,
  } = checkout;

  const paymentBtnClass = classnames({
    [`${CLASS}-button`]: true,
    [`${CLASS}-button-inactive`]: freeButton,
    [`${CLASS}-button-expanded`]: menu,
  });
  const paymentMenuClass = classnames({
    'payment-menu': true,
    'payment-menu-show': menu,
  });
  return (
    <>
      <div
        disabled={freeButton}
        className={paymentBtnClass}
        onClick={(event) => {
          event.stopPropagation();
          if (!freeButton) {
            showMenu((p) => !p);
          }
        }}
        ref={innerRef}
      >
        {(checkout.error || checkout.error === null) && !freeButton ? (
          <div className="payment">
            <img src={checkoutImages.creditCardPurple} alt="credit card" />
            {paypalActive ? (
              <span style={{ color: '#a637fc' }}>Pay with PayPal</span>
            ) : applePayActive && applePaySelected ? (
              <span style={{ color: '#a637fc' }}>Pay with Apple Pay</span>
            ) : klarnaActive ? (
              <span style={{ color: '#a637fc' }}>Pay with Klarna</span>
            ) : (
              <span>Add Payment Method</span>
            )}
            <span className="expand">
              <img
                src={checkoutImages.dropdownTrianglePurple}
                alt="dropdown triangle"
              />
            </span>
          </div>
        ) : (
          <>
            {freeButton ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={checkoutImages.creditCardPurple} alt="credit card" />
                <span className={`${CLASS}-button-textDone`}>
                  Payment Method
                </span>
              </div>
            ) : (
              <>
                <img
                  style={{ paddingTop: '5px' }}
                  src={checkoutImages.creditCardPurple}
                  alt="credit card"
                />
                <span className={`${CLASS}-button-textDone`}>
                  {paypalActive
                    ? 'Pay with PayPal'
                    : applePayActive
                    ? 'Pay with Apple Pay'
                    : `${cardBrand ? cardBrand : ''}...${
                        cardLast4 ? cardLast4 : ''
                      }`}
                </span>
              </>
            )}
          </>
        )}
        <div className={paymentMenuClass}>
          {' '}
          <div onClick={props.activateCard}>
            <span>Credit Card</span>
          </div>
          {!props.klarnaActive && (
            <div onClick={props.activateKlarna}>
              <span>Use Klarna</span>
            </div>
          )}
          {!props.paypal && (
            <div onClick={props.activatePaypal}>
              <span>Use PayPal</span>
            </div>
          )}
          {!props.applePay && applePayAvailable() ? (
            <div onClick={props.activateApplePay}>
              <span>Use Apple Pay</span>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ app, user, boards, checkout }) => ({
  app,
  user,
  boards,
  checkout,
});

const mapDispatchToProps = {
  ...checkoutActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPayment);

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import StripeMobile from '../stripe-mobile';
import { updateUserEmail } from '../../../redux/user/actions';
import { updateCheckout } from '../../../redux/checkout/actions';
import { checkoutImages } from '../../../lib/images';
import { a_addCreditCard, a_snapSignUp } from '../../../api/analytics';

import './PaymentInfo.scss';

const CLASS = 'sb-PaymentInfo';
const ID = 'sb-PaymentInfo';

const PaymentInfo = () => {
  const { paymentError } = useSelector((state) => state.checkout);
  const [state, setState] = useState({ email: '', isValidEmail: false });

  const dispatch = useDispatch();
  const { cardPaymentOpen, error: checkoutError } = useSelector(
    (state) => state.checkout,
  );
  const { remarketingUrl } = useSelector((state) => state.app);

  const handleDone = () => {
    const { email, isValidEmail } = state;
    if (isValidEmail) {
      localStorage.setItem('email', JSON.stringify(email));
      dispatch(updateUserEmail(email));
      a_snapSignUp(email);
    }

    document.getElementById('btn-stripeMobileCheckout').click();

    a_addCreditCard(remarketingUrl);
  };

  const renderErrorMessage = () => {
    if (!paymentError) return null;
    return (
      <p className={`${CLASS}-errorMsg`}>
        {paymentError.message}
        <br />
        Please Try Again
      </p>
    );
  };

  return (
    <div id={ID}>
      <div className={`${CLASS}-content`}>
        <StripeMobile
          handleUpdateEmail={(emailData) => setState({ ...emailData })}
        />
        {renderErrorMessage()}
      </div>
    </div>
  );
};

export default PaymentInfo;

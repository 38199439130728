import React from 'react';

import './BoardLoader.scss';

const CLASS = 'sb-BoardLoader';

const BoardLoader = () => {
	const renderDots = () => {
		let dots = [];
		for (let i = 0; i < 12; i++) {
			dots.push(<div key={`dot-${i}`} />);
		}
		return dots;
	};

	return <div className={CLASS}>{renderDots()}</div>;
};

export default BoardLoader;

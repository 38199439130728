import React from 'react';

import frameClassic from '../../../assets/images/boards/frames/Default.png'; // Classic - No padding
import frameClassicDark from '../../../assets/images/boards/frames/Default-Dark.png'; // Classic Dark - No padding
import frameClassicLight from '../../../assets/images/boards/frames/Default-Light.png';
import frameBold from '../../../assets/images/boards/frames/Bold-1.png'; // White - No padding
import frameClean from '../../../assets/images/boards/frames/Clean-1.png'; // White - With padding
import frameEver from '../../../assets/images/boards/frames/Ever-1.png'; // Black - No padding
import frameEdge from '../../../assets/images/boards/frames/Edge-1.png'; // Black - With padding

const CLASS = 'sb-Board';

function BoardFrame({ boards }) {
	return (
    <div className={`${CLASS}-frame`}>
      <img
        src={frameClassic}
        className={`classic ${boards.selectedFrame === 'classic' && 'show'}`}
        alt="Frame"
      />
      <img
        src={frameBold}
        className={`bold ${boards.selectedFrame === 'bold' && 'show'}`}
        alt="Frame"
      />
      <img
        src={frameClean}
        className={`clean ${boards.selectedFrame === 'clean' && 'show'}`}
        alt="Frame"
      />
      <img
        src={frameEver}
        className={`ever ${boards.selectedFrame === 'ever' && 'show'}`}
        alt="Frame"
      />
      <img
        src={frameEdge}
        className={`edge ${boards.selectedFrame === 'edge' && 'show'}`}
        alt="Frame"
      />
      <img
        src={frameClassicDark}
        className={`classic ${
          (boards.selectedFrame === 'classic-dark' ||
            boards.selectedFrame === 'eco' ||
            boards.selectedFrame === 'metallic' ||
            boards.selectedFrame === 'canvas' ||
            boards.selectedFrame === 'black-foam' ||
            boards.selectedFrame === 'canvas-foam' ||
            boards.selectedFrame === 'metallic-foam' ||
            boards.selectedFrame === 'gray-foam' ||
            boards.selectedFrame === 'frame' ||
            boards.selectedFrame === 'original') &&
          'show'
        }`}
        alt="Frame"
      />
      <img
        src={frameClassicLight}
        className={`classic ${
          boards.selectedFrame === 'classic-light' && 'show'
        }`}
        alt="Frame"
      />
    </div>
  );
}

export default BoardFrame;

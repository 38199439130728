export const Iks = () => (
  <svg
    width="17"
    height="16"
    fill="none"
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.75 1L1.75 15"
      stroke="#304E78"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.75 1L15.75 15"
      stroke="#304E78"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';
import { Helmet } from 'react-helmet';

import seo from '../../../lib/seo';

import './Gift.scss';

const Gift = () => {
  const CLASS = 'Gift';
  return (
    <div className={CLASS}>
      <Helmet>
        <meta name="description" content={seo.giftCard.description} />
        <meta
          name="og:description"
          property="og:description"
          content={seo.giftCard.description}
        />
        <meta
          name="og:title"
          property="og:title"
          content={seo.giftCard.title}
        />
        <title>{seo.giftCard.title}</title>
      </Helmet>
      <div
        data-site-id="372f4128-3d7b-4a4d-b97e-d719946ac5cc"
        data-platform="Other"
        className="gift-up-target"
      ></div>
    </div>
  );
};

export default Gift;

import exifParser from 'exif-parser';

import * as TYPES from './action-types';
import { s3Upload } from '../../api/s3';
import {
	a_networkFailure,
	a_photoUpload,
	a_photoUploadKlaviyo,
	a_reuploadImages,
} from '../../api/analytics';

import { store as reduxStore } from '../../app/App';
import * as storage from '../../utils/storage';
import notify from '../../utils/notify';
import date from '../../utils/getDate';
import { post } from '../../utils/http';
import getArrayBuffer from '../../utils/getArrayBuffer';
import { getClientIp } from '../../utils/getClientIp';
import * as events from '../../utils/events';
import heic2any from "heic2any";

// ----- HELPERS -----

export const getBoards = () => storage.get(storage.names.boards);

export const setBoards = boards =>
	storage.set(
		storage.names.boards,
		boards.map(b => ({ ...b, s3Params: null, blob: null })),
	);

const removeBoards = () => storage.remove(storage.names.boards);

const getAndFilterBoards = () => {
	let boards = getBoards();
	if (boards) {
		boards = boards.filter(board => board.secureUrl);
		boards = boards.length === 0 ? null : boards;
		boards = boards.length ? boards.map(b => ({ ...b, status: 'done' })) : null;
		setBoards(boards);
	}
	return boards;
};

const storeBlobWidthHeight = (images, isLeft) => {
	let boards = getBoards();
	if (!boards) boards = [];

	for (let i = 0; i < images.length; i++) {
		const { id, blob, width, height } = images[i];
		const newBoard = { id, blob, width, height };
		if (isLeft) {
			boards.unshift(newBoard);
		} else {
			boards.push(newBoard);
		}
	}
	setBoards(boards);
};

const storeImage = images => {
	try {
		const boards = getBoards();
		images.forEach(image => {
			const board = boards.find(board => board.id === image.id);
			if (!board) return;
			board.secureUrl = image.secureUrl;
			board.status = image.secureUrl ? 'done' : 'failed';
		});
		setBoards(boards);
	} catch (err) {}
};

const removeImageFromStorage = id => {
	let boards = getBoards();
	boards = boards.filter(board => board.id !== id);
	if (boards.length) {
		setBoards(boards);
	} else {
		removeBoards();
	}
};

// ----- FRAMES -----

export const framesSet = frame => {
	return {
		type: TYPES.FRAMES_SET,
		payload: frame,
	};
};

// ----- INITIALIZE -----

const initializeStart = () => {
	return {
		type: TYPES.INITIALIZE_BOARDS_START,
	};
};

const initializeError = error => {
	return {
		type: TYPES.INITIALIZE_BOARDS_ERROR,
		payload: error,
	};
};

const initializeEnd = boards => {
	return {
		type: TYPES.INITIALIZE_BOARDS_END,
		payload: boards,
	};
};

export const initializeBoards = () => {
	return dispatch => {
		dispatch(initializeStart());
		try {
			const boards = getAndFilterBoards();
			dispatch(initializeEnd(boards));
		} catch (error) {
			dispatch(initializeError(error));
		}
	};
};

// ----- UPLOAD -----

const uploadStart = () => {
	return {
		type: TYPES.UPLOAD_IMAGE_START,
	};
};

const uploadError = error => async (dispatch, getState) => {
	a_networkFailure();
	const msg = error?.message || 'No error message';
	notify({ error, msg, snapshot: getState() });
	dispatch({
		type: TYPES.UPLOAD_IMAGE_ERROR,
		payload: error,
	});
};

const uploadBlobEnd = boards => {
	return {
		type: TYPES.UPLOAD_BLOB_END,
		payload: boards,
	};
};
const updateLowQuality = images => {
	return {
		type: TYPES.UPDATE_LOW_QUALITY,
		payload: images,
	};
};

export const uploadEnd = (images, uploadsDone) => {
	// images.forEach(image => {
	// 	let preloadedImage = new Image();
	// 	preloadedImage.src = image;
	// });
	return {
		type: TYPES.UPLOAD_IMAGE_END,
		payload: { images, uploadsDone },
	};
};

export const reuploadImages = async (boards, snapshot) => {
	const failedTiles = boards.filter(b => b.status === 'failed');
	notify({ msg: `Reuploading started for ${failedTiles.length} images`, snapshot });
	a_reuploadImages(failedTiles.length, false);
	const reuploaded = [];
	let fileBodyErrors = 0;
	for (let tile of failedTiles) {
		try {
			let result = await uploadFile(tile.s3Params).catch(() => null);
			if (!result) {
				fileBodyErrors++;
				const blob = await fetch(tile.blob).then(r => r.blob());
				tile.s3Params.Body = blob;
				result = await uploadFile(tile.s3Params);
			}
			events.onReuploadDone();
			reuploaded.push({
				...tile,
				secureUrl: result,
				status: result ? 'done' : 'failed',
			});
		} catch (reuploadErr) {}
	}
	reduxStore.dispatch(uploadEnd(reuploaded, true));
	a_reuploadImages(reuploaded.length, true);
	const fileBodyErrMsg = fileBodyErrors ? `(${fileBodyErrors} file body problems)` : '';
	notify({
		msg: `Reuploading done for ${reuploaded.length} images ${fileBodyErrMsg}`,
		snapshot,
	});
};

export const uploadFile = params => s3Upload(params);

// image, blob, width, height, isLeft
export const uploadImage = (images, isLeft, remarketingId) => async (dispatch, getState) => {
	const selectedBoard = getState().boards.selectedFrame;

	let clientIp = '';
	getClientIp()
		.then(res => (clientIp = res))
		.catch(console.log);

	dispatch(uploadStart());
	storeBlobWidthHeight(images, isLeft);
	dispatch(uploadBlobEnd({ images, isLeft }));

	const lowQuality = images.filter(image => image.file.size < 120000);
	if (lowQuality.length) dispatch(updateLowQuality(lowQuality));

	const uploaded = [];
	for (let [index, image] of images.entries()) {
		let { file } = image;
		const ext = `${file.type.split('/').pop()}`;
		const folder = date(0, 'YY/MM/DD/');
		const fileName = `${Date.now().toString()}_${index.toString()}`;
		const objectKey = `${folder}${fileName}.${ext}`;
		const userEmail = getState().user.email;

		const needsConverting = ['heif', 'heic'];
		if (needsConverting.includes(ext)) {
			const convert = async() => {
				const source = await fetch(image.blob);
				const blob = await source.blob();
				const result = await heic2any({ blob, toType: 'image/jpeg', quality: 1 });
				return result;
			};
			file = await convert();
		}
 
		const params = {
			// ACL: 'public-read',
			Bucket: process.env.REACT_APP_AWS_BUCKET_PICTURES,
			ContentType: file.type,
			Key: objectKey,
			Body: file,
			StorageClass: 'INTELLIGENT_TIERING'
		};
		
		const board = {
			...image,
			s3Params: params,
			frame: selectedBoard || 'classic',
		};

		try {
			const s3Res = await uploadFile(params);
			const imageUrl = s3Res;

			a_photoUpload();

			let resultMeta;

			if (file.type === 'image/jpeg') {
				// arrayBuffer = await file.arrayBuffer();
				const arrayBuffer = await getArrayBuffer(file);

				// Exif - parser;
				resultMeta = exifParser
					.create(arrayBuffer)
					.enableBinaryFields(true)
					.enablePointers(true)
					.enableImageSize(true)
					.parse();
			}

			onUploadEnd(remarketingId, userEmail, imageUrl, resultMeta, clientIp);

			uploaded.push({
				...board,
				meta: resultMeta?.imageSize,
				secureUrl: imageUrl,
				status: imageUrl ? 'done' : 'failed',
			});
			storeImage(uploaded);
		} catch (err) {
			events.onUploadFail();
			dispatch(uploadError(err));
			uploaded.push({
				...board,
				status: 'failed',
			});
		}

		dispatch(uploadEnd(uploaded));
		const remarketingUrl = getState().app.remarketingUrl;
		a_photoUploadKlaviyo(uploaded, remarketingUrl);
	}
};

const onUploadEnd = (remarketingId, userEmail, imageUrl, resultMeta, clientIp) => {
	events.onUploadDone();
	post('/image/add', null, {
		url: imageUrl,
		meta: resultMeta,
		remarketingId,
		email: userEmail,
		ipAddress: clientIp,
	}).catch(console.log);
};

// ----- REMOVE -----

const removeStart = () => {
	return {
		type: TYPES.REMOVE_IMAGE_START,
	};
};

const removeError = error => {
	return {
		type: TYPES.REMOVE_IMAGE_ERROR,
		payload: error,
	};
};

const removeEnd = board => {
	return {
		type: TYPES.REMOVE_IMAGE_END,
		payload: board,
	};
};

export const removeImage = blob => {
	return dispatch => {
		dispatch(removeStart());
		try {
			removeImageFromStorage(blob);
			dispatch(removeEnd(blob));
		} catch (error) {
			dispatch(removeError(error));
		}
	};
};

// ----- INVALIDATE -----

const invalidateStart = () => {
	return {
		type: TYPES.INVALIDATE_BOARDS_START,
	};
};

const invalidateError = error => {
	return {
		type: TYPES.INVALIDATE_BOARDS_ERROR,
		payload: error,
	};
};

const invalidateEnd = () => {
	return {
		type: TYPES.INVALIDATE_BOARDS_END,
	};
};

export const invalidateBoards = () => {
	return dispatch => {
		dispatch(invalidateStart());
		try {
			removeBoards();
			dispatch(invalidateEnd());
		} catch (error) {
			dispatch(invalidateError(error));
		}
	};
};

// ----- MENU -----

export const showMenu = () => {
	return dispatch => {
		dispatch({
			type: TYPES.SHOW_BOARDS_MENU,
		});
	};
};

export const hideMenu = () => {
	return dispatch => {
		dispatch({
			type: TYPES.HIDE_BOARDS_MENU,
		});
	};
};

// ----- SHOW UPLOAD OPTIONS -----

export const showUploadOptions = () => {
	return dispatch => {
		dispatch({
			type: TYPES.SHOW_UPLOAD_OPTIONS,
		});
	};
};

export const hideUploadOptions = () => {
	return dispatch => {
		dispatch({
			type: TYPES.HIDE_UPLOAD_OPTIONS,
		});
	};
};

export const setBoardInCropper = board => {
	return dispatch => {
		dispatch({
			type: TYPES.SET_BOARD_IN_CROPPER,
			payload: board,
		});
	};
};

export const updateImageCropData = (cropData, imageId) => (dispatch, getState) => {
	const boards = getState().boards?.boards.map(oldBoard => {
		const board = { ...oldBoard };
		if (board.id === imageId) {
			board.cropData = cropData;
		}
		return board;
	});
	if (!boards) return;

	setBoards(boards);
	dispatch({
		type: TYPES.UPDATE_CROP_DATA,
		payload: boards,
	});
};

export const updateImagesForFrameOption = frameName => (dispatch, getState) => {
	let boards = getState().boards?.boards?.map(b => ({ ...b }));
	if (!boards) return;

	boards.forEach(board => (board.frame = frameName));

	setBoards(boards);
	dispatch({
		type: TYPES.UPDATE_CROP_DATA,
		payload: boards,
	});
};

export const updateWidthAndHeight = (board, image) => (dispatch, getState) => {
	let boards = getState().boards?.boards.map(b => ({ ...b }));
	if (!boards) return;
	for (let i = 0; i < boards.length; i++) {
		if (
			boards[i].id === board.id ||
			(boards[i].secureUrl && boards[i].secureUrl === board.secureUrl)
		) {
			boards[i].width = image.naturalWidth;
			boards[i].height = image.naturalHeight;
		}
	}
	setBoards(boards);
	dispatch({
		type: TYPES.UPDATE_WIDTH_AND_HEIGHT,
		payload: { board, image },
	});
};

// ----- LOW QUALITY -----

export const keepLowQuality = id => {
	return {
		type: TYPES.KEEP_LOW_QUALITY,
		payload: id,
	};
};

export const removeLowQuality = id => {
	return dispatch => {
		dispatch({
			type: TYPES.REMOVE_LOW_QUALITY,
			payload: id,
		});
		dispatch(removeImage(id));
	};
};

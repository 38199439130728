const platformDetector = () => {
	let userAgent = window.navigator.userAgent,
		platform = window.navigator.platform,
		macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
		windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
		os = null;

	if (iOS()) {
		os = 'iOS';
	} else if (macosPlatforms.includes(platform)) {
		os = 'macOS';
	} else if (windowsPlatforms.includes(platform)) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	const browser = detectBrowser();
	const vpWidth = document.documentElement?.clientWidth;
	const vpHeight = document.documentElement?.clientHeight;
	const isMobile = (vpWidth || 0) < 990;
	const deviceType = isMobile ? 'mobile' : 'desktop';
	return { os, browser, vpWidth, vpHeight, deviceType, device: platform };
};

function iOS() {
	return (
		['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
			navigator.platform,
		) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	);
}

const detectBrowser = () => {
	const ua = navigator.userAgent || navigator.vendor || window.opera;

	const Firefox = typeof InstallTrigger !== 'undefined';
	const Safari = window.navigator.vendor?.match(/apple/i);
	const IE = /*@cc_on!@*/ false || !!document.documentMode;
	const Edge = !IE && !!window.StyleMedia;
	const Chrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
	const EdgeChromium = Chrome && navigator.userAgent.includes('Edg');
	const FbApp = ua.includes('FBAN') || ua.includes('FBAV');

	const browsers = {
		Firefox,
		Safari,
		IE,
		Edge,
		Chrome,
		EdgeChromium,
		FbApp,
	};

	let browser = 'Unknown';
	for (let [key, value] of Object.entries(browsers)) {
		if (value) browser = key;
	}
	return browser;
};

export default platformDetector;

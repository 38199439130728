import React from 'react';

const CLASS = 'sb-Boards';

const StripeError = () => (
    <div className={`${CLASS}-orderCompleted`}>
        <div className={`${CLASS}-orderStatus`}>
            <p>An error occured during payment and your card was not charged.</p>
            <p>
                Please&nbsp;
                <span
                    className={`${CLASS}-reload`}
                    onClick={() => window.location.reload()}>
                    reload
                </span>
                &nbsp;and click Place Order again.
            </p>
        </div>
    </div>
);

export default StripeError;

import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Input from '../input';
import * as storage from '../../../utils/storage';
import { userActions } from '../../../redux/user';
import { checkoutActions } from '../../../redux/checkout';
import {
  a_addAddress,
  a_openShippingForm,
  a_addPhoneNumber,
} from '../../../api/analytics'; // a_openShippingForm
import { useForm } from 'react-hook-form';

import './ShippingInfo.scss';
import CustomCheckbox from '../CustomCheckbox';
import { updateCheckout } from '../../../redux/checkout/actions';

const CLASS = 'sb-ShippingInfo';
const ID = 'sb-ShippingInfo';

export const verifyAddress = () => {
  document.getElementById('verify-address').click();
};

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const ShippingInfoMobile = ({ toggleShippingInfo }) => {
  const {
    user,
    checkout: { gift },
    app: { remarketingUrl },
    boards: { cloudinaryURL },
  } = useSelector((state) => state);

  const url = new URL(window.location);
  const clientSecret = url.searchParams.get('payment_intent_client_secret');

  const dispatch = useDispatch();

  const formRef = useRef();

  const [info, setInfo] = useState({
    fullName: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    phone: '',
    // this should be users location by default
    // let's put US for now
    country: 'US',
    email: '',
    isValidFullName: true,
    isValidStreet: true,
    isValidCity: true,
    isValidState: true,
    isValidPostalCode: true,
    isValidPhone: true,
    isValidCountry: true,
    isValidEmail: true,
    appartmentNum: '',
    canceled: false,
  });

  const debouncedInfo = useDebounce(info, 1000);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    fillInputs(user);
  }, []);

  useEffect(() => {
    if (clientSecret) {
      return;
    }
    localStorage.setItem('shippingInfo', JSON.stringify(info));
    handleDone();
    console.log();
    setValue('name', info.fullName);
    setValue('email', info.email);
    setValue('address', info.street);
    setValue('city', info.city);
    setValue('state', info.state);
    setValue('zip', info.postalCode);
    setValue('phone', info.phone);
    setValue('country', 'US');
  }, [debouncedInfo, setValue]);

  const fillInputs = (user) => {
    setInfo((prevState) => {
      return {
        ...prevState,
        ...user,
        isValidFullName: user.fullName ? true : false,
        isValidStreet: user.street ? true : false,
        isValidCity: user.city ? true : false,
        isValidState: user.state ? true : false,
        isValidPostalCode: user.postalCode ? true : false,
        isValidEmail: user.email ? true : false,
        postalCode: user.postalCode ?? '',
        street: user.street?.split(', Apt #')?.[0] ?? '',
        appartmentNum: user.street?.split(', Apt #')?.[1] ?? '',
        canceled: false,
      };
    });
  };

  const handleDone = () => {
    const shippingData = {
      ...info,
      street: `${info.street}${info.appartmentNum ? `, Apt #${info.appartmentNum} ` : ''
        }`,
    };
    dispatch(userActions.updateShippingInfo(shippingData));
    handleGlobalValidation(shippingData);
    dispatch(checkoutActions.updateCheckout({ addressInfoOpen: false }));
  };

  const handleGlobalValidation = (shippingData) => {
    const {
      fullName,
      street,
      appartmentNum,
      phone,
      city,
      state,
      postalCode,
      country,
      email,
    } = info;
    const streetAndNum = appartmentNum
      ? `${street}, Apt #${appartmentNum}`
      : street;

    if (allFieldsFilled() && allFieldsValid()) {
      storage.set(storage.names.shippingInfoError, false);
      storage.set(storage.names.shippingInfo, {
        ...shippingData,
        shippingInfoError: false,
      });
      a_addAddress(street, remarketingUrl);
      a_addPhoneNumber(phone);
      dispatch(
        userActions.saveUserDetails({
          fullName,
          email,
          phone,
          city,
          state,
          postalCode,
          country,
          cloudinaryURL,
          remarketingUrl,
          street: streetAndNum,
          shippingInfoError: false,
        }),
      );
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        user.checkoutEmail = email;
        localStorage.setItem('user', JSON.stringify(user));
      }
      localStorage.setItem('email', JSON.stringify(email));
      dispatch(userActions.updateUserEmail(email));
    } else {
      storage.set(storage.names.shippingInfoError, true);
      storage.set(storage.names.shippingInfo, {
        ...shippingData,
        shippingInfoError: true,
      });
      dispatch(userActions.saveUserDetails(null));
    }
  };

  const allFieldsFilled = () => {
    const { fullName, street, city, state, postalCode, country, email } = info;
    return (
      !!fullName &&
      !!street &&
      !!city &&
      !!state &&
      !!postalCode &&
      !!country &&
      !!email
    );
  };

  const allFieldsValid = () => {
    const {
      isValidStreet,
      isValidCity,
      isValidState,
      isValidFullName,
      isValidPhone,
      isValidPostalCode,
      isValidCountry,
      isValidEmail,
    } = info;
    return (
      isValidStreet &&
      isValidCity &&
      isValidState &&
      isValidFullName &&
      isValidPhone &&
      isValidPostalCode &&
      isValidCountry &&
      isValidEmail
    );
  };

  function onChangeInputHandler(value, error) {
    setInfo((prevState) => {
      return {
        ...prevState,
        [this]: value,
        [`isValid${this[0].toUpperCase() + this.slice(1)}`]: !error,
        canceled: false,
      };
    });
  }

  const handleSelect = (value) => {
    dispatch(updateCheckout({ gift: !!value }));
  };

  const onSubmit = (data) => { };

  const renderForm = () => {
    const {
      street,
      fullName,
      appartmentNum,
      city,
      state,
      postalCode,
      phone,
      country,
    } = info;

    return (
      <div className={`${CLASS}-form`}>
        <form
          ref={formRef}
          id="address-form"
          autoComplete="nope"
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
        >
          <Input
            name="name"
            errors={errors}
            value={fullName}
            label="Full Name"
            register={register}
            placeholder="Full Name"
            canceled={info.canceled}
            onChange={onChangeInputHandler.bind('fullName')}
          />
          <Input
            name="email"
            type="email"
            label="Email"
            errors={errors}
            value={info.email}
            placeholder="Email"
            register={register}
            canceled={info.canceled}
            onChange={onChangeInputHandler.bind('email')}
          />
          <Input
            value={street}
            name="address"
            label="Address"
            errors={errors}
            register={register}
            placeholder="Address"
            canceled={info.canceled}
            className="form-control sb-Input-field"
            onChange={onChangeInputHandler.bind('street')}
          />
          <div style={{ display: 'flex', gap: '8px' }}>
            <Input
              name="city"
              label="City"
              value={city}
              errors={errors}
              placeholder="City"
              register={register}
              style={{ flex: 1 }}
              canceled={info.canceled}
              onChange={onChangeInputHandler.bind('city')}
            />
            <Input
              name="state"
              label="State"
              value={state}
              errors={errors}
              register={register}
              style={{ flex: 1 }}
              placeholder="State"
              canceled={info.canceled}
              onChange={onChangeInputHandler.bind('state')}
            />
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <Input
              name="zip"
              errors={errors}
              label="Zip Code"
              value={postalCode}
              register={register}
              style={{ flex: 1 }}
              placeholder="Zip Code"
              canceled={info.canceled}
              onChange={onChangeInputHandler.bind('postalCode')}
            />
            <Input
              type="tel"
              name="phone"
              label="Phone"
              value={phone}
              errors={errors}
              register={register}
              style={{ flex: 1 }}
              canceled={info.canceled}
              placeholder="Phone Number"
              onChange={onChangeInputHandler.bind('phone')}
            />
          </div>
          <label>
            <span
              style={{
                opacity: 0.4,
                fontSize: '14px',
                fontWeight: 400,
                color: '#284E7B',
              }}
            >
              Country
            </span>
            <select
              {...register('country', {
                required: 'This field is required',
              })}
              className="country-select"
              style={{
                width: '100%',
                fontWeight: 400,
                outline: 'none',
                color: '#284E7B',
                fontSize: '16px',
                margin: '6px 0px',
                borderRadius: '4px',
                padding: '11px 20px',
                border: '1px solid rgba(40, 78, 123, .3)',
                backgroundColor: 'rgba(40, 78, 123, 0.05)',
              }}
              name="country"
              value={country}
              onChange={(e) => {
                const sd = onChangeInputHandler.bind('country');
                return sd(e.target.value);
              }}
            >
              {/* <option value="CA">Canada</option> */}
              <option value="US">United States</option>
            </select>
          </label>

          <div style={{ display: 'flex', marginTop: '8px' }}>
            <CustomCheckbox
              square
              size={16}
              value={!gift}
              checked={gift}
              handler={handleSelect}
            />
            <span
              onClick={() => handleSelect(!gift)}
              style={{
                marginLeft: '8px',
                fontSize: '14px',
                color: '#284E7B',
                display: 'block',
                cursor: 'pointer',
              }}
            >
              This is a gift.
            </span>
          </div>
          <button id="verify-address" hidden></button>
        </form>
      </div>
    );
  };

  return (
    <div ref={(ref) => (formRef.current = ref)} id={ID} style={{ zIndex: 100 }}>
      {renderForm()}
    </div>
  );
};

export default ShippingInfoMobile;

import {
  getPriceBDisc,
  getBaseAmount,
  subtractPercent,
  roundTwo,
} from './price';

const COUPONS = {
  GIFT: 'GIFT',
  CARD: 'CARD',
  DPER: 'DPER',
  DVAL: 'DVAL',
  BUND: 'BUND',
  FREE: 'FREE',
  '3TPER': '3TPER',
};

const getSavePercent = (oldAmount, newAmount) =>
  Math.round(100 - (newAmount / oldAmount) * 100);

export const checkCouponType = (type) => {
  const coupons = {};
  for (let [key, value] of Object.entries(COUPONS)) {
    coupons[key] = type === value;
  }
  return coupons;
};

const countFreeBoards = (numOfBoards, minBoardsDisc, discount) => {
  const { free, metadata } = discount;
  let num = numOfBoards - minBoardsDisc;
  let freeLeft = 0;
  let res = 0;
  if (metadata?.onetime && num >= 0) {
    res = free >= num ? num : free;
    return res;
  }
  const round = minBoardsDisc + free;
  let numOfRounds = Math.ceil(numOfBoards / round);
  while (num >= 0) {
    if (num - free < 0) {
      res += num;
      freeLeft = free - num;
      break;
    }
    num -= round;
    res += free;
  }
  discount.addMore = !freeLeft ? numOfRounds * round - numOfBoards : 0;
  discount.freeLeft = freeLeft;

  return res;
};

export const getTierValues = (discount, hierarchy) => {
  const { metadata } = discount;
  let tierSize = 0,
    tierPrice = 0,
    tierText = '';
  for (let [key, value] of Object.entries(metadata)) {
    if (key === `level_${hierarchy}_boards`) {
      tierSize = value;
    } else if (key === `level_${hierarchy}_value`) {
      tierPrice = value;
    } else if (key === `level_${hierarchy}_text`) {
      tierText = value;
    }
  }
  return { tierSize, tierPrice, tierText };
};

const applyBundle = (numOfBoards, price, discount) => {
  const { tier, originalAmount } = discount;
  const priceWithBDisc = getPriceBDisc(price, discount.b_disc);
  const amountWithBDisc = getBaseAmount(numOfBoards, priceWithBDisc);
  discount.amount =
    tier?.action.discount.amount_off || originalAmount - amountWithBDisc;
  discount.applied = true;
  if (!tier) {
    return amountWithBDisc;
  }
  const { singleBoard } = priceWithBDisc;
  let amount = getBaseAmount(numOfBoards, price);
  const { tierSize, tierPrice } = getTierValues(discount, tier?.hierarchy);
  let newAmount = tierPrice * 100;
  const boardsLeft = numOfBoards - tierSize;
  newAmount = newAmount + boardsLeft * singleBoard * 100;
  discount.saveAmount = (amount - newAmount) / 100;
  discount.savePercent = getSavePercent(amount, newAmount);
  discount.amountWithBDisc = amountWithBDisc;
  amount = newAmount;
  return amount;
};

const applyVoucher = (numOfBoards, price, discount) => {
  const priceWithBDisc = getPriceBDisc(price, discount.b_disc);
  let amountWithBDisc = getBaseAmount(numOfBoards, priceWithBDisc);
  const { singleBoard } = priceWithBDisc;
  let couponAmountToDeduct;
  let sizeAmount;
  let amount = getBaseAmount(numOfBoards, price);
  let newAmount = amount,
    savePercent,
    saveAmount,
    freeBoards = 0;
  if (discount.percent) {
    newAmount = subtractPercent(amount, discount.percent);
    newAmount = Math.round(newAmount);
    saveAmount = amount - newAmount;
    savePercent = discount.percent;
    sizeAmount = newAmount;
  }
  if (discount.amount) {
    if (discount.minBoardsDisc && discount.minBoardsDisc > 3) {
      newAmount = getBaseAmount(discount.minBoardsDisc, price);
      newAmount = newAmount - discount.amount;
      const boardsLeft = numOfBoards - discount.minBoardsDisc;
      newAmount = newAmount + boardsLeft * singleBoard * 100;
      sizeAmount = newAmount;
    } else {
      sizeAmount = newAmount;
      couponAmountToDeduct =
        (discount.amount > newAmount ? newAmount : discount.amount) / 100;
      newAmount = newAmount - discount.amount;
    }
    saveAmount = discount.amount;
    savePercent = getSavePercent(amount, newAmount);
  }
  if (discount.free) {
    const { minBoardsDisc } = discount;
    freeBoards = countFreeBoards(numOfBoards, minBoardsDisc, discount);
    amountWithBDisc = getBaseAmount(numOfBoards - freeBoards, priceWithBDisc);
    let originalAmount = getBaseAmount(numOfBoards - freeBoards, price);
    discount.originalPrice = roundTwo(originalAmount / 100);
    newAmount = Math.round((numOfBoards - freeBoards) * singleBoard * 100);
    saveAmount = amount - newAmount;
    savePercent = getSavePercent(amount, newAmount);
  }
  amount = newAmount;
  discount.applied = true;
  discount.sizeAmount = sizeAmount;
  discount.savePercent = savePercent;
  discount.saveAmount = saveAmount / 100;
  discount.freeBoards = freeBoards;
  discount.amountWithBDisc = amountWithBDisc;
  discount.couponAmountToDeduct = couponAmountToDeduct;
  return amount;
};

const applyCoupon = (amountInCents, coupon, discount) => {
  const {
    price,
    boardsLength,
    couponType,
    minAmount,
    minBoardsDisc,
    amountWithBDisc,
  } = discount;
  const { GIFT, CARD, DPER, DVAL, BUND, FREE } = couponType;
  const TPER = couponType['3TPER'];
  const DISC = DPER || DVAL;
  if (GIFT) {
    amountInCents = applyVoucher(boardsLength, price, discount);
  } else if (DISC) {
    const boardsCondition = (minBoardsDisc || Infinity) <= boardsLength;
    const amountCondition = (minAmount || Infinity) * 100 <= amountWithBDisc;
    const condition =
      boardsCondition || amountCondition || (!minBoardsDisc && !minAmount);
    if (condition) {
      amountInCents = applyVoucher(boardsLength, price, discount);
    } else if (minBoardsDisc) {
      discount.addMore = minBoardsDisc - boardsLength;
    } else if (minAmount) {
      discount.addAmount = minAmount - amountWithBDisc / 100;
      discount.addAmount = roundTwo(discount.addAmount);
    }
  } else if (FREE) {
    const boardsCondition = (minBoardsDisc || Infinity) <= boardsLength;
    if (boardsCondition) {
      amountInCents = applyVoucher(boardsLength, price, discount);
    } else {
      discount.addMore = minBoardsDisc - boardsLength;
    }
  } else if (BUND) {
    const tiers = [...coupon.tiers].sort((a, b) => b.hierarchy - a.hierarchy);
    const tier = tiers.find((tier) => {
      const tierSize = +tier.name.match(/\d+/gi);
      return tierSize && tierSize <= boardsLength;
    });
    const nextTier = coupon.tiers.find(
      (t) => t.hierarchy === (tier?.hierarchy || 0) + 1,
    );
    const { tierSize, tierText } = getTierValues(discount, tier?.hierarchy);
    discount.tier = tier;
    discount.nextTier = nextTier;
    if (nextTier && !(tier && tierSize === boardsLength)) {
      const { tierSize: nextTierSize } = getTierValues(
        discount,
        nextTier.hierarchy,
      );
      discount.addMore = nextTierSize - boardsLength;
    }
    amountInCents = applyBundle(boardsLength, price, discount);
    discount.ctext = tierText;
    discount.text = tier?.banner;
  } else if (TPER) {
    const {
      level_1_value,
      level_2_boards,
      level_2_value,
      level_3_boards,
      level_3_value,
    } = coupon.metadata;
    if (boardsLength < level_2_boards) {
      discount.nextTier = true;
      discount.percent = level_1_value;
      discount.addMore = level_2_boards - boardsLength;
      coupon.ctext = `${level_1_value}% ${discount.ctext}`;
      amountInCents = applyVoucher(boardsLength, price, discount);
    } else if (boardsLength < level_3_boards) {
      discount.percent = level_2_value;
      coupon.ctext = `${level_2_value}% ${discount.ctext}`;
      amountInCents = applyVoucher(boardsLength, price, discount);
    } else {
      discount.percent = level_3_value;
      coupon.ctext = `${level_3_value}% ${discount.ctext}`;
      amountInCents = applyVoucher(boardsLength, price, discount);
    }
  } else {
    if (CARD) {
      discount.amount = discount.gift;
      discount.giftCard = discount.gift / 100;
      amountInCents = applyVoucher(boardsLength, price, discount);
    } else {
      amountInCents = applyVoucher(boardsLength, price, discount);
    }
  }
  return amountInCents;
};

export default applyCoupon;

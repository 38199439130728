const seoSettings = {
	main: {
		title: 'Create A Picture Wall From Your Photos | StoryBoards | StoryBoards',
		description:
			'Upload, Click and Stick, you favorite photos using Affordable Photo Tiles delivered to your Door. Mix and match to create new stories using StoryBoard’s unique picture sticking solution.',
		h1Tag: 'Create Wall Art Simply & Online From Your Photos',
	},
	faq: {
		title: 'Frequently Asked Questions | StoryBoards',
		description:
			'Our frequently asked questions and answers when it comes to our wall art and wall tiles. We have a range of resources to help you find what you need.',
		h1Tag: 'Frequently Asked Questions – All You Need to Know',
	},
	giftCard: {
		title: 'Wall Art Gift Cards | Free Shipping | StoryBoards',
		description:
			'Our wall art gift cards are a perfect way to say thank you, I love you and anything else to those you love, colleagues you work with and anyone else, anytime.',
		h1Tag: 'Wall Art Gift Cards for All Occasions',
	},
};

export default seoSettings;

import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import history from '../../../app/history';

import { facebookPixel } from '../../../api/facebook-pixel';
import { a_PortalClicked, a_askForHelp, a_menuTapped } from '../../../api/analytics';
import {
  openGorgiasChat,
  useGorgiasChat,
} from '../../../utils/features/gorgiasChat';
import { isProduction, search } from '../../../utils/envHandler';
import { toggleModal, updateApp } from '../../../redux/app/actions';
import { updateCheckout } from '../../../redux/checkout/actions';
import { headerMenuImages, giftImages } from '../../../lib/images';

import './Header.scss';
import { isMobile } from 'react-device-detect';
import { SBLogo } from '../../../assets/images/SBLogo';

const {
  headerLogoWhiteNew,
  FaqMenuBlue,
  HeartBlue,
  HelpMenuBlue,
  CouponWhite,
} = headerMenuImages;
const { WhiteArrowLeft } = giftImages;

const productionLink = `https://storyboards.io${search ? search : ''}`;
const stagingLink = `https://staging.storyboards.io${search ? search : ''}`;

const productionFaqLink = `https://storyboards.io/faq${search ? search : ''}`;
const stagingFaqLink = `https://staging.storyboards.io/faq${search ? search : ''
  }`;

const CLASS = 'sb-Header';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const dispatch = useDispatch();
  const { app, coupon } = useSelector((state) => state);

  const history = useHistory();
  const menuRef = useRef();

  useEffect(() => {
    const isClickOutside = (e) => {
      if (!menuRef.current?.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', isClickOutside);

    return () => document.removeEventListener('mousedown', isClickOutside);
  }, []);

  useGorgiasChat();

  const closeModals = () => {
    dispatch(
      updateApp({
        modals: { addCoupon: false, faq: false, gift: false, help: false },
      }),
    );
    dispatch(
      updateCheckout({
        isCheckoutOpen: false,
        cardPaymentOpen: false,
      }),
    );
  };

  const toggleMenu = () => {
    facebookPixel.trackCustom('Menu Tapped', {});
    a_menuTapped();

    setShowMenu((prevstate) => !prevstate);
  };

  const onCouponClick = () => {
    closeModals();
    dispatch(toggleModal({ modal: 'addCoupon', toggle: true }));
    setShowMenu(false);
  };

  const onVipCouponClick = () => {
    closeModals();
    dispatch(toggleModal({ modal: 'addVIPCoupon', toggle: true }));
    setShowMenu(false);
  };

  const onAskForHelpClick = () => {
    a_askForHelp();
    closeModals();
    openGorgiasChat();
    setShowMenu(false);
  };

  const onGiftClick = () => {
    closeModals();
    dispatch(toggleModal({ modal: 'gift' }));
    setShowMenu(false);

    if (app.modals.gift && isGiftPage()) {
      history.goBack();
      return;
    }

    history.push('/gift');
  };

  const onBack = () => {
    setShowMenu(false);
    history.push('/');
  };

  const isGiftPage = () => {
    const { pathname } = history.location;
    const isGift = pathname.startsWith('/gift');
    return isGift;
  };

  const renderMenu = () => {
    if (!showMenu) return null;
    return (
      <div
        className={`${CLASS}-menu-dropdown`}
        style={{
          padding: '8px',
          display: 'flex',
          fontSize: '14px',
          background: '#fff',
          borderRadius: '12px',
          flexDirection: 'column',
          boxShadow: '1px 1px 12px 0 rgba(80, 80, 80, 0.25)',
        }}
      >
        <a href={isProduction ? productionFaqLink : stagingFaqLink}>
          <div>
            {/* <FaqMenuBlue /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-help-hexagon"
              width="44"
              height="44"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
              <path d="M12 16v.01" />
              <path d="M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483" />
            </svg>
            <span>FAQ</span>
          </div>
        </a>
        <div onClick={onGiftClick}>
          {/* <HeartBlue /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-gift-card"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z" />
            <path d="M7 16l3 -3l3 3" />
            <path d="M8 13c-.789 0 -2 -.672 -2 -1.5s.711 -1.5 1.5 -1.5c1.128 -.02 2.077 1.17 2.5 3c.423 -1.83 1.372 -3.02 2.5 -3c.789 0 1.5 .672 1.5 1.5s-1.211 1.5 -2 1.5h-4z" />
          </svg>
          <span>Buy Gift Cards</span>
        </div>
        <div onClick={onCouponClick}>
          {/* <CouponWhite /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-ticket"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M15 5l0 2" />
            <path d="M15 11l0 2" />
            <path d="M15 17l0 2" />
            <path d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2" />
          </svg>
          <span>Apply Coupon Code</span>
        </div>
        <div onClick={onVipCouponClick}>
          {/* <HeartBlue /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-vip"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 5h18" />
            <path d="M3 19h18" />
            <path d="M4 9l2 6h1l2 -6" />
            <path d="M12 9v6" />
            <path d="M16 15v-6h2a2 2 0 1 1 0 4h-2" />
          </svg>
          <span>Apply VIP Credit</span>
        </div>
        <div onClick={onAskForHelpClick}>
          {/* <HelpMenuBlue /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-info-hexagon"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
            <path d="M12 9h.01" />
            <path d="M11 12h1v4h1" />
          </svg>
          <span>Contact Support</span>
        </div>
        <div onClick={() => {
          setShowMenu(false);
          a_PortalClicked();
          history.push('/portal');
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-home-2" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
            <path d="M10 12h4v4h-4z" />
          </svg>
          <span>VIP Portal</span>
        </div>
      </div>
    );
  };

  const isGift = isGiftPage();

  if (!app.showHeader) return null;
  else
    return (
      <div
        className={CLASS}
        style={{
          zIndex: 10,
          height: '50px',
          paddingLeft: isGift ? 0 : '16px',
          boxShadow: '0px 2px 4px rgba(135, 135, 135, 0.25)',
        }}
      >
        <div className={`${CLASS}__left`}>
          {isGift && (
            <div className={`${CLASS}-back-arrow`} onClick={onBack}>
              <WhiteArrowLeft />
            </div>
          )}
        </div>

        <div
          className={classNames(``, {
            [`${CLASS}-logo--arrow`]: isGift,
          })}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <a style={{ display: "flex" }} href={isProduction ? productionLink : stagingLink}>
            <SBLogo />
          </a>
        </div>

        <div ref={menuRef} style={{ marginLeft: 'auto' }}>
          <div
            className={classNames(`${CLASS}-menu-button`, {
              [`${CLASS}-menu-button--active`]: showMenu,
            })}
            onClick={toggleMenu}
          >
            <span />
            <span />
            <span />
          </div>
          {renderMenu()}
        </div>
      </div>
    );
};

export default Header;

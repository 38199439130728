const toggleChatPopup = (shouldShow, origin) => {
	// console.log('toggle chat popup:', shouldShow ? 'ON' : 'OFF', origin);

	const chatPopup = document.querySelector('#chat-widget-container');
	if (!chatPopup) return;

	if (shouldShow) {
		chatPopup.style.display = 'block';
	} else {
		chatPopup.style.display = 'none';
	}
};

export default toggleChatPopup;

import axios from 'axios';

export const request = (opts) => {
  if (!opts.url) {
    throw new Error('url is required');
  }

  opts.url = '/api' + opts.url;

  // opts.baseURL = opts.baseURL || process.env.REACT_APP_SERVER_URL;
  opts.method = opts.method || 'get';
  const httpClient = axios.create();
  if (opts.params && opts.params.timeout) {
    httpClient.defaults.timeout = opts.params.timeout;
  }

  return httpClient(opts)
    .then((res1) => {
      return res1.data;
    })
    .catch((res) => {
      let err = null;
      let response = res.response;
      if (response && response.data && response.data.message) {
        err = response.data;
      } else if (response) {
        err = new Error(response.statusText);
        err.status = response.status;
      } else {
        err = new Error(res.message || 'HTTP Error');
        err.status = 0;
      }
      throw err;
    });
};

export const get = (url, params) => request({ url, ...params });

export const post = (url, params, data) =>
  request({ method: 'post', url, ...params, data });

export const put = (url, params, data) =>
  request({ method: 'put', url, ...params, data });
